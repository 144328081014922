import React, { forwardRef, useEffect, useState } from "react";
import { getInsuranceRelations_Ajax } from "../../helpers/requests";
import Select from "react-select";
import { Controller } from "react-hook-form";
import { isEmptyArray } from "../../helpers/utils";

const InsuranceRelationOptions = (props, ref) => {
  const { insuranceRelation, control, validRelations = [] } = props;

  const [relations, setRelations] = useState([]);

  useEffect(() => {
    getRelationOptions();
  }, []);

  function setRelationOptions(data) {
    let relationOptions = [];
    relationOptions.push({ value: "", label: "Select Relation" });
    data.forEach((option) => {
      relationOptions.push({
        value: option.code,
        label: option.relation
      });
    });
    setRelations(relationOptions);
  }

  function getRelationOptions() {
    getInsuranceRelations_Ajax(
      function (response) {
        if (response && response.success && response.data) {
          let validRelationsOptions = [];

          if (!isEmptyArray(validRelations)) {
            validRelations.forEach((vr) => {
              let option = response.data.find(
                (r) => r.relation.toLowerCase() == vr.toLowerCase()
              );
              validRelationsOptions.push(option);
            });
          }

          if (!isEmptyArray(validRelationsOptions)) {
            setRelationOptions(validRelationsOptions);
          } else {
            setRelationOptions(response.data);
          }
        }
      },
      function (err) {}
    );
  }

  return (
    <Controller
      control={control}
      name={insuranceRelation}
      defaultValue={insuranceRelation}
      render={({ field }) => (
        <Select
          ref={ref}
          classNamePrefix="react-select"
          placeholder="Select Relation"
          id="relation"
          options={relations}
          onChange={(relation) => field.onChange(relation.value)}
          value={relations.find((option) => option.value === field.value)}
          menuPlacement="auto"
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: "#3fbbeb",
              primary: "#3fbbeb"
            }
          })}
        />
      )}
      rules={{
        required: "This field is required."
      }}
    />
  );
};

export default forwardRef(InsuranceRelationOptions);
