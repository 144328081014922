import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import InputMask from "react-input-mask";

import {
  DateTimeFormats,
  GenderOptions,
  SignUpFormFields,
  ValidationTypes
} from "../../constants";
import {
  getPatientProfileSettings_Ajax,
  savePatientProfileSettings_Ajax,
  getCountries_Ajax,
  getStates_Ajax,
  getCityOrZips_Ajax,
  getTimezones_Ajax
} from "../../helpers/requests";
import {
  formatDateTime,
  getNameInitials,
  handleApiErrors,
  isImageFile,
  isPhoneNumberValid,
  showAlertDialouge,
  validateFileMaxSize,
  isPatientDateOfBirthValid,
  onImageError,
  handleRemoveProfilePhoto
} from "../../helpers/utils";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import { setIsFamilyMembersListChanged } from "../../redux/actions/userData";
import InsuranceFormUpdate from "../InsuranceInformation/InsuranceFormUpdate";
import DateInputMask from "../DateInput/DateInputMask";

const MyDependentsEditMember = (props) => {
  const { dependentMember } = props;

  const dependentId = dependentMember.patid;

  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };
  const shouldShowInsuranceInformation = useSelector(
    (state) => state?.appSettings?.settingsData?.shouldShowInsuranceInformation
  );

  return (
    <>
      <ul className="nav nav-tabs justify-content-center">
        <li className="nav-item col-6">
          <button
            className={`tab-button ${activeTab === 1 ? "tab-active" : ""}`}
            onClick={() => handleTabClick(1)}
          >
            Profile
          </button>
        </li>
        {shouldShowInsuranceInformation && (
          <li className="nav-item col-6">
            <button
              className={`tab-button ${activeTab === 2 ? "tab-active" : ""}`}
              onClick={() => handleTabClick(2)}
            >
              Insurance Info
            </button>
          </li>
        )}
      </ul>
      {activeTab === 1 && <UpdateProfile dependentMember={dependentMember} />}
      {activeTab === 2 && <UpdateInsurance patientId={dependentId} />}
    </>
  );
};

function UpdateProfile(props) {
  const { dependentMember } = props;
  const fileInputRef = useRef(null);

  const dependentId = dependentMember.patid;

  let SignUpFormFieldsDynamic = {};

  if (dependentMember) {
    SignUpFormFieldsDynamic.FirstName =
      SignUpFormFields.FirstName + dependentId.toString();
    SignUpFormFieldsDynamic.LastName =
      SignUpFormFields.LastName + dependentId.toString();
    SignUpFormFieldsDynamic.Gender =
      SignUpFormFields.Gender + dependentId.toString();
    SignUpFormFieldsDynamic.DateOfBirth =
      SignUpFormFields.DateOfBirth + dependentId.toString();
    SignUpFormFieldsDynamic.Email =
      SignUpFormFields.Email + dependentId.toString();
    SignUpFormFieldsDynamic.Phone =
      SignUpFormFields.Phone + dependentId.toString();
    SignUpFormFieldsDynamic.Address1 =
      SignUpFormFields.Address1 + dependentId.toString();
    SignUpFormFieldsDynamic.Address2 =
      SignUpFormFields.Address2 + dependentId.toString();
    SignUpFormFieldsDynamic.Country =
      SignUpFormFields.Country + dependentId.toString();
    SignUpFormFieldsDynamic.State =
      SignUpFormFields.State + dependentId.toString();
    SignUpFormFieldsDynamic.City =
      SignUpFormFields.City + dependentId.toString();
    SignUpFormFieldsDynamic.Zip = SignUpFormFields.Zip + dependentId.toString();
    SignUpFormFieldsDynamic.Timezone =
      SignUpFormFields.Timezone + dependentId.toString();
    SignUpFormFieldsDynamic.ProfileImage =
      "profileImage" + dependentId.toString();
  }

  const [countires, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [zips, setZips] = useState([]);
  const [timeZones, setTimeZones] = useState([]);

  const [shouldUpdateLocations, setShouldUpdateLocations] = useState(false);
  const [isPatientPhoneNumberValid, setIsPatientPhoneNumberValid] =
    useState(false);
  const [profilePic, setProfilePic] = useState(null);
  const [existingPatientInfo, setExistingPatientInfo] = useState(null);
  const [photoUrl, setPhotoUrl] = useState(null);

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors }
  } = useForm({
    mode: "onChange"
  });

  const firstNameWatch = watch(SignUpFormFieldsDynamic.FirstName);
  const lastNameWatch = watch(SignUpFormFieldsDynamic.LastName);
  const dateOfBirthWatch = watch(SignUpFormFieldsDynamic.DateOfBirth);
  const countryWatch = watch(SignUpFormFieldsDynamic.Country);
  const stateWatch = watch(SignUpFormFieldsDynamic.State);
  const cityWatch = watch(SignUpFormFieldsDynamic.City);
  const phoneWatch = watch(SignUpFormFieldsDynamic.Phone);
  const genderWatch = watch(SignUpFormFieldsDynamic.Gender);
  const timezonesWatch = watch(SignUpFormFieldsDynamic.Timezone);
  const zipWatch = watch(SignUpFormFieldsDynamic.Zip);
  const relationWatch = watch("relation" + dependentId.toString());

  useEffect(() => {
    getLocationOptionsAndPopulateDate();
  }, []);

  useEffect(() => {
    let countryCode = getValues(SignUpFormFieldsDynamic.Country);

    if (countryCode && shouldUpdateLocations) {
      getStates(countryCode);
      getTimeZones(countryCode);
    }
  }, [countryWatch]);

  useEffect(() => {
    let countryCode = getValues(SignUpFormFieldsDynamic.Country);
    let stateCode = getValues(SignUpFormFieldsDynamic.State);
    let city = getValues(SignUpFormFieldsDynamic.City);

    if (countryCode && stateCode && shouldUpdateLocations) {
      getZips(countryCode, stateCode, city);
    }
  }, [cityWatch]);

  useEffect(() => {
    let phone = getValues(SignUpFormFieldsDynamic.Phone);

    if (phone && isPhoneNumberValid(phone)) {
      setIsPatientPhoneNumberValid(true);
    } else {
      setIsPatientPhoneNumberValid(false);
    }
  }, [phoneWatch]);

  useEffect(() => {
    let countryCode = getValues(SignUpFormFieldsDynamic.Country);
    let stateCode = getValues(SignUpFormFieldsDynamic.State);

    if (countryCode && stateCode && shouldUpdateLocations) {
      getCities(countryCode, stateCode);
    }
  }, [stateWatch]);

  function getLocationOptionsAndPopulateDate() {
    let patientId = dependentMember.patid;

    dispatch(showLoadingSpinner());
    setShouldUpdateLocations(false);
    getPatientProfileSettings_Ajax(
      patientId,
      function (response) {
        if (response && response.success && response.data) {
          dispatch(hideLoadingSpinner());
          let patientInfo = response.data;
          setExistingPatientInfo(patientInfo);
          getCountries().then((success) => {
            if (success) {
              getStates(patientInfo.country).then((success) => {
                if (success) {
                  getCities(patientInfo.country, patientInfo.state).then(
                    (success) => {
                      if (success) {
                        getZips(
                          patientInfo.country,
                          patientInfo.state,
                          patientInfo.city
                        ).then((success) => {
                          if (success) {
                            getTimeZones(patientInfo.country).then(
                              (success) => {
                                if (success) {
                                  populatePatientProfile(
                                    patientInfo,
                                    response.data.photoId
                                  );
                                }
                              }
                            );
                          }
                        });
                      }
                    }
                  );
                }
              });
            }
          });
        }
      },
      function (err) {
        dispatch(hideLoadingSpinner());
        handleApiErrors(err);
      }
    );
  }

  function getCountries() {
    return new Promise(function (resolve) {
      getCountries_Ajax(
        function (response) {
          if (response && response.success && response.data) {
            setValue(SignUpFormFieldsDynamic.Country, "");
            setValue(SignUpFormFieldsDynamic.State, "");
            setValue(SignUpFormFieldsDynamic.City, "");
            setValue(SignUpFormFieldsDynamic.Zip, "");
            setCountries(response.data);
          }
          resolve(true);
        },
        function (err) {
          handleApiErrors(err);
          resolve(false);
        }
      );
    });
  }

  function getStates(countryCode) {
    return new Promise(function (resolve) {
      if (countryCode) {
        getStates_Ajax(
          countryCode,
          function (response) {
            if (response && response.success && response.data) {
              setValue(SignUpFormFieldsDynamic.State, "");
              setValue(SignUpFormFieldsDynamic.City, "");
              setValue(SignUpFormFieldsDynamic.Zip, "");
              setStates(response.data);
            }
            resolve(true);
          },
          function (err) {
            handleApiErrors(err);
            resolve(false);
          }
        );
      } else {
        resolve(true);
      }
    });
  }

  function getCities(countryCode, stateCode) {
    return new Promise(function (resolve) {
      if (countryCode && stateCode) {
        getCityOrZips_Ajax(
          countryCode,
          stateCode,
          undefined,
          undefined,
          undefined,
          function (response) {
            if (response && response.success && response.data) {
              setValue(SignUpFormFieldsDynamic.City, "");
              setValue(SignUpFormFieldsDynamic.Zip, "");
              setCities(response.data);
            }
            resolve(true);
          },
          function (err) {
            handleApiErrors(err);
            resolve(false);
          }
        );
      } else {
        resolve(true);
      }
    });
  }

  function getZips(countryCode, stateCode, city) {
    return new Promise(function (resolve) {
      if (countryCode && stateCode && city) {
        getCityOrZips_Ajax(
          countryCode,
          stateCode,
          city,
          undefined,
          undefined,
          function (response) {
            if (response && response.success && response.data) {
              setValue(SignUpFormFieldsDynamic.Zip, "");
              setZips(response.data);
            }
            resolve(true);
          },
          function (err) {
            handleApiErrors(err);
            resolve(false);
          }
        );
      } else {
        resolve(true);
      }
    });
  }

  function getTimeZones(countryCode) {
    return new Promise(function (resolve) {
      if (countryCode) {
        getTimezones_Ajax(
          countryCode,
          function (response) {
            if (
              response &&
              response.success &&
              response.data &&
              Array.isArray(response.data)
            ) {
              setValue(SignUpFormFieldsDynamic.Timezone, "");
              setTimeZones(response.data);
            }
            resolve(true);
          },
          function (err) {
            handleApiErrors(err);
            resolve(false);
          }
        );
      } else {
        resolve(true);
      }
    });
  }

  function isSaveBtnVisible() {
    return (
      firstNameWatch &&
      lastNameWatch &&
      genderWatch &&
      dateOfBirthWatch &&
      isPatientPhoneNumberValid &&
      countryWatch &&
      stateWatch &&
      cityWatch &&
      relationWatch &&
      timezonesWatch &&
      zipWatch
    );
  }

  function validateImageMaxSize() {
    if (profilePic) {
      let arr = [profilePic.file];
      let fileSizeErrMsg = validateFileMaxSize(arr);

      if (fileSizeErrMsg) {
        showAlertDialouge("Error", fileSizeErrMsg);

        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  function updateSelectedPhoto(event) {
    if (event && event.target && event.target.files) {
      let file = event.target.files[0];
      let fileName = file.name.toLowerCase();

      if (isImageFile(fileName)) {
        let tempPath = URL.createObjectURL(file);

        let picObj = {
          url: tempPath,
          type: fileName.split(".").pop(),
          file: file
        };

        setProfilePic(picObj);
      } else {
        showAlertDialouge("Error", "File type not supported");
      }
    }
  }

  function populatePatientProfile(patientInfo, photoId) {
    if (patientInfo) {
      setValue(SignUpFormFieldsDynamic.FirstName, patientInfo.firstName);
      setValue(SignUpFormFieldsDynamic.LastName, patientInfo.lastName);
      setValue(SignUpFormFieldsDynamic.Gender, patientInfo.gender);
      setValue(
        SignUpFormFieldsDynamic.DateOfBirth,
        formatDateTime(patientInfo.dateOfBirth, DateTimeFormats.MM_DD_YYYY)
      );

      if (patientInfo.user?.email)
        setValue(SignUpFormFieldsDynamic.Email, patientInfo.user?.email);

      if (patientInfo.emergencyContactPhone !== null)
        setValue(
          SignUpFormFieldsDynamic.Phone,
          patientInfo.emergencyContactPhone
        );

      setValue(SignUpFormFieldsDynamic.Address1, patientInfo.address1);
      setValue(SignUpFormFieldsDynamic.Address2, patientInfo.address2);
      setValue(SignUpFormFieldsDynamic.Country, patientInfo.country);
      setValue(SignUpFormFieldsDynamic.State, patientInfo.state);
      setValue(SignUpFormFieldsDynamic.City, patientInfo.city);
      setValue(SignUpFormFieldsDynamic.Zip, patientInfo.zip);
      setValue(SignUpFormFieldsDynamic.Timezone, patientInfo.timezone);
      setValue("relation" + dependentId, patientInfo.relationToRespParty);

      setPhotoUrl(photoId);

      setShouldUpdateLocations(true);
    }
  }

  function onSubmit(signUpFormData) {
    if (validateImageMaxSize() && existingPatientInfo && signUpFormData) {
      let dependentData = { ...existingPatientInfo };

      dependentData.firstName = signUpFormData["firstName" + dependentId];
      dependentData.middleName = signUpFormData["middleName" + dependentId];
      dependentData.lastName = signUpFormData["lastName" + dependentId];
      dependentData.gender = signUpFormData["gender" + dependentId];
      dependentData.dateOfBirth = signUpFormData["dateOfBirth" + dependentId];
      dependentData.emergencyContactPhone =
        signUpFormData["phone" + dependentId];
      dependentData.address1 = signUpFormData["address1" + dependentId];
      dependentData.address2 = signUpFormData["address2" + dependentId];
      dependentData.country = signUpFormData["country" + dependentId];
      dependentData.state = signUpFormData["state" + dependentId];
      dependentData.city = signUpFormData["city" + dependentId];
      dependentData.zip = signUpFormData["zip" + dependentId];
      dependentData.timezone = signUpFormData["timezone" + dependentId];
      dependentData.relationToRespParty =
        signUpFormData["relation" + dependentId];
      dependentData.respParty.relationToPatient =
        signUpFormData["relation" + dependentId];

      let dobValidityCheck = isPatientDateOfBirthValid(
        signUpFormData["dateOfBirth" + dependentId]
      );

      if (dobValidityCheck.valid) {
        let formData = new FormData();

        if (profilePic && profilePic.file) {
          formData.append("profilePhoto", profilePic.file);
        } else {
          formData.append("profilePhoto", null);
          dependentData.photoId = null;
        }

        formData.append("patientVM", JSON.stringify(dependentData));

        dispatch(showLoadingSpinner());
        savePatientProfileSettings_Ajax(
          formData,
          function (response) {
            dispatch(hideLoadingSpinner());
            if (response && response.success && response.data) {
              dispatch(setIsFamilyMembersListChanged(true));
              if (response.message) {
                showAlertDialouge("Message", response.message);
              }
              setPhotoUrl(response.data.photoId);
            } else if (response && !response.success && response.message) {
              showAlertDialouge("Error", response.message);
            }
          },
          function (err) {
            dispatch(hideLoadingSpinner());
            handleApiErrors(err);
          }
        );
      } else {
        let errorMessage = dobValidityCheck.errorMessage
          ? dobValidityCheck.errorMessage
          : "Please enter a valid date of birth.";

        showAlertDialouge("Error", errorMessage);
      }
    }
  }

  function shouldShowRemovePhotoBtn() {
    return (photoUrl && photoUrl != null) || profilePic != null;
  }

  const handleProfilePhotoRemoval = handleRemoveProfilePhoto(() => {
    setProfilePic(null);
    setPhotoUrl(null);
  });

  return (
    <>
      {/* Member Edit Form */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset>
          <div className="row align-items-center">
            <div className="profile-setting-upload mt-4 mb-3 col-md-12 col-xl-6">
              <div className="d-flex profile_img">
                <div className="circle me-2 me-md-4 d-flex justify-content-center align-items-center">
                  {profilePic && profilePic.url ? (
                    <img
                      className="profile-pic"
                      src={profilePic.url}
                      onError={onImageError}
                    />
                  ) : photoUrl ? (
                    <img
                      className="profile-pic"
                      src={photoUrl}
                      onError={onImageError}
                    />
                  ) : (
                    <div className="bg-color d-4 d-flex justify-content-center align-items-center">
                      <div className="profile-initial">
                        {getNameInitials(dependentMember.fullName)}
                      </div>
                    </div>
                  )}
                </div>
                <div className="upload-btn">
                  {shouldShowRemovePhotoBtn() ? (
                    <div className="input-group mb-3">
                      <label
                        className="input-group-text d-table btn btn-danger"
                        onClick={handleProfilePhotoRemoval}
                      >
                        <i className="bi bi-trash-fill me-1"></i>Remove Photo
                      </label>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="input-group mb-1">
                    <label
                      className="input-group-text d-table btn btn-primary"
                      htmlFor={"inputGroupFile" + dependentId}
                      tabIndex={0}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                          fileInputRef.current?.click();
                        }
                      }}
                    >
                      <i className="bi bi-upload me-1"></i>Upload
                    </label>
                    <input
                      type="file"
                      className="form-control d-none"
                      id={"inputGroupFile" + dependentId}
                      ref={fileInputRef}
                      onChange={updateSelectedPhoto}
                    />
                  </div>
                  <div className="text-muted text-start">
                    (File should be Smaller than 1MB)
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group mb-3 col-md-6 col-xl-6">
              <label className="form-label">
                First Name<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="First Name"
                className="form-control"
                placeholder="First Name"
                {...register(SignUpFormFieldsDynamic.FirstName, {
                  required: true
                })}
              />
            </div>
            <div className="form-group mb-3 col-md-6 col-xl-6">
              <label className="form-label">
                Last Name<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="Last Name"
                className="form-control"
                placeholder="Last Name"
                {...register(SignUpFormFieldsDynamic.LastName, {
                  required: true
                })}
              />
            </div>
            <div className="form-group mb-3 col-md-6 col-xl-6">
              <label className="form-label">
                Emergency Contact Phone Number (XXX)-XXX-XXXX
                <span className="text-danger">*</span>
              </label>
              <InputMask
                mask="(999)-999-9999"
                id={SignUpFormFieldsDynamic.Phone}
                type="text"
                name="phone number"
                className="form-control"
                placeholder="(XXX)-XXX-XXXX"
                {...register(SignUpFormFieldsDynamic.Phone, {
                  required: true,
                  min: 14
                })}
              />
            </div>
            <div className="form-group mb-3 col-md-6">
              <label className="form-label">
                Select Relation<span className="text-danger">*</span>
              </label>
              <select
                className="form-select form-control"
                name="relation"
                id={"relation" + dependentId.toString()}
                {...register("relation" + dependentId, { required: true })}
              >
                <option value="" defaultValue>
                  Select Relation
                </option>
                <option value="Son">Son</option>
                <option value="Daughter">Daughter</option>
                <option value="Spouse">Spouse</option>
                <option value="Father">Father</option>
                <option value="Mother">Mother</option>
                <option value="Friend">Friend</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div className="form-group mb-3 col-md-6 col-xl-6 d-flex flex-wrap">
              <label className="form-label w-100">
                Gender<span className="text-danger">*</span>
              </label>
              <div className="form-check mb-2 me-4 ps-0">
                <input
                  className="form-check-input mx-1 position-static float-none"
                  type="radio"
                  name="painLevel"
                  value={GenderOptions.Male}
                  checked={genderWatch == GenderOptions.Male}
                  {...register(SignUpFormFieldsDynamic.Gender, {
                    required: true
                  })}
                />
                <label className="form-check-label fs-4 d-inline">Male</label>
              </div>
              <div className="form-check mb-2 me-4 ps-0">
                <input
                  className="form-check-input mx-1 position-static float-none"
                  type="radio"
                  name="painLevel"
                  value={GenderOptions.Female}
                  checked={genderWatch == GenderOptions.Female}
                  {...register(SignUpFormFieldsDynamic.Gender, {
                    required: true
                  })}
                />
                <label className="form-check-label fs-4 d-inline">Female</label>
              </div>
              <div className="form-check mb-2 me-4 ps-0">
                <input
                  className="form-check-input mx-1 position-static float-none"
                  type="radio"
                  name="painLevel"
                  value={GenderOptions.Other}
                  checked={
                    genderWatch == GenderOptions.Unknown ||
                    genderWatch == GenderOptions.Other
                  }
                  {...register(SignUpFormFieldsDynamic.Gender, {
                    required: true
                  })}
                />
                <label className="form-check-label fs-4 d-inline">Other</label>
              </div>
            </div>
            <div className="form-group mb-3 col-md-6 col-xl-6">
              <label className="form-label">
                Date of Birth<span className="text-danger">*</span>
              </label>
              <DateInputMask
                register={register}
                fieldName={SignUpFormFieldsDynamic.DateOfBirth}
                errors={errors}
                validationType={ValidationTypes.DateOfBirth}
              />
            </div>

            <div className="form-group mb-3 col-md-6 col-xl-6">
              <label className="form-label">Primary Address</label>
              <textarea
                type="text"
                name="Primary Address"
                className="form-control"
                placeholder="Primary Address"
                {...register(SignUpFormFieldsDynamic.Address1, {
                  required: false
                })}
              ></textarea>
            </div>
            <div className="form-group mb-3 col-md-6 col-xl-6">
              <label className="form-label">Secondary Address</label>
              <textarea
                type="text"
                name="Secondary Address"
                className="form-control"
                placeholder="Secondary Address"
                {...register(SignUpFormFieldsDynamic.Address2, {
                  required: false
                })}
              ></textarea>
            </div>

            <div className="form-group mb-3 col-md-6 col-xl-6">
              <label className="form-label">
                Country<span className="text-danger">*</span>
              </label>
              <select
                className="form-select form-control"
                disabled={true}
                id={"country" + dependentId.toString()}
                name="country"
                {...register(SignUpFormFieldsDynamic.Country, {
                  required: true
                })}
              >
                <option key={0} value="">
                  Select Country
                </option>
                {countires.map((country, index) => {
                  return (
                    <option key={index + 1} value={country?.value}>
                      {country?.countryName}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-group mb-3 col-md-6 col-xl-6">
              <label className="form-label">
                State<span className="text-danger">*</span>
              </label>
              <select
                className="form-select form-control"
                name="state"
                id={"state" + dependentId.toString()}
                {...register(SignUpFormFieldsDynamic.State, { required: true })}
              >
                <option value="" defaultValue>
                  State
                </option>
                {states.map((state, index) => {
                  return (
                    <option key={index + 1} value={state?.value}>
                      {state.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-group mb-3 col-md-6 col-xl-6">
              <label className="form-label">
                City<span className="text-danger">*</span>
              </label>
              <select
                className="form-select form-control"
                name="city"
                id={"city" + dependentId.toString()}
                {...register(SignUpFormFieldsDynamic.City, { required: true })}
              >
                <option key={0} value="">
                  Select City
                </option>
                {cities.map((city, index) => {
                  return (
                    <option key={index + 1} value={city}>
                      {city}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-group mb-3 col-md-6 col-xl-6">
              <label className="form-label">
                Zip Code<span className="text-danger">*</span>
              </label>
              <select
                className="form-select form-control"
                {...register(SignUpFormFieldsDynamic.Zip, { required: true })}
              >
                <option key={0} value="">
                  Select Zip
                </option>
                {zips.map((zip, index) => {
                  return (
                    <option key={index + 1} value={zip}>
                      {zip}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-group mb-3 col-md-6 col-xl-6">
              <label className="form-label">
                Timezone<span className="text-danger">*</span>
              </label>
              <select
                className="form-select form-control"
                {...register(SignUpFormFieldsDynamic.Timezone, {
                  required: true
                })}
              >
                <option key={0} value="">
                  Select Timezone
                </option>
                {timeZones.map((timezone, index) => {
                  return (
                    <option key={index + 1} value={timezone?.offset}>
                      {timezone?.timezoneName}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-group text-center mt-2 mt-md-4 col-sm-12">
              <button
                disabled={!isSaveBtnVisible()}
                type="submit"
                className="tab-focusable-background btn btn-secondary btn-rounded btn-lg px-5"
              >
                Save
              </button>
            </div>
          </div>
        </fieldset>
      </form>
    </>
  );
}

function UpdateInsurance(props) {
  const { patientId } = props;

  return (
    <>
      <InsuranceFormUpdate
        patientId={patientId}
        showApplyToDependentsOption={false}
      />
    </>
  );
}
export default MyDependentsEditMember;
