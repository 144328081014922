import React, { useState, useEffect } from "react";
import { isEmptyArray, onImageError } from "../../helpers/utils";
import { getDentalConditionStatusTextColorClass } from "../../helpers/smartScanUtils";

const DentalConditionDetails = (props) => {
  const { dentalConditionData, title, updateIsLoadingCompletedStatus } = props;
  const [imagesLoaded, setImagesLoaded] = useState(0);
  const totalImages = dentalConditionData?.annotations?.length;

  function handleImageLoad() {
    setImagesLoaded(imagesLoaded + 1);
  }

  function checkAllImagesLoaded() {
    if (imagesLoaded == totalImages) {
      updateIsLoadingCompletedStatus(title, true);
    }
  }

  useEffect(() => {
    checkAllImagesLoaded();
  }, [imagesLoaded]);

  return dentalConditionData ? (
    <tr className="section">
      <td>
        <table width="100%">
          <tbody>
            <tr>
              <td
                style={{
                  borderBottom: "1px solid #eee",
                  padding: ".5rem 0rem",
                  fontSize: 15
                }}
              >
                <strong>{title}</strong>
              </td>
              <td
                style={{
                  borderBottom: "1px solid #eee",
                  padding: ".5rem 0rem",
                  color: "#39b54a",
                  fontSize: 16,
                  textAlign: "end"
                }}
              >
                <strong
                  className={getDentalConditionStatusTextColorClass(
                    dentalConditionData.status
                  )}
                >
                  <span>{dentalConditionData.status}</span>
                </strong>
              </td>
            </tr>
            <tr>
              <td
                colSpan={2}
                style={{
                  width: "50%",
                  paddingTop: 15,
                  verticalAlign: "top",
                  paddingRight: 10
                }}
              >
                <p>
                  <strong>{dentalConditionData.description}</strong>
                </p>
                <p>{dentalConditionData.scoreInterpretation}</p>
              </td>
            </tr>
            <tr>
              <td
                colSpan={2}
                style={{
                  verticalAlign: "top",
                  paddingBottom: 20
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "nowrap"
                  }}
                >
                  {!isEmptyArray(dentalConditionData?.annotations) ? (
                    dentalConditionData?.annotations.map((annotation) => {
                      return (
                        <div key={annotation.photoId} style={{ padding: 5 }}>
                          <img
                            src={annotation.photoId}
                            alt={"Image of the " + title}
                            onError={onImageError}
                            onLoad={handleImageLoad}
                            style={{
                              width: "100%",
                              height: 100,
                              objectFit: "contain"
                            }}
                          />
                        </div>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  ) : (
    <></>
  );
};

export default DentalConditionDetails;
