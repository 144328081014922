import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import baguetteBox from "baguettebox.js";
import { useDispatch, useSelector } from "react-redux";
import {
  getSecondOpinion_Ajax,
  saveSecondOpinionRecord_Ajax
} from "../../helpers/requests";

import ReviewPicOne from "./../../assets/images/review-photo1.png";
import ReviewPicTwo from "./../../assets/images/review-photo2.png";
import IconPDF from "./../../assets/images/icon-pdf.svg";
import IconDoc from "./../../assets/images/icon-doc.svg";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import {
  convertKBFileSizeToMB,
  formatDateTime,
  getSupportedDocExtensions,
  getSupportedImageExtensions,
  handleApiErrors,
  isEmptyArray,
  isPDFfile,
  onImageError,
  showAlertDialouge,
  showConfirmDialouge,
  supportedDocExtensions,
  supportedImageExtentions,
  validateFileMaxSize
} from "../../helpers/utils";
import {
  DateTimeFormats,
  MaxAllowdFileSizes,
  SecondOpinionStatus
} from "../../constants";
import {
  isImageFile,
  isValidDocFileForPatientUpload
} from "../../helpers/utils";
import { setFileUrlToOpen } from "../../redux/actions/fileViewer";
import GenericFileIcon from "../../assets/images/word_processor_file_icon.png";
import AdditionalInfoNeededPage from "./AdditionalInfoNeededPage";

const UploadTypes = {
  Image: 1,
  Note: 2,
  Other: 3
};

const CaseReviewPage = (props) => {
  const { secondOpinionId } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [secondOpinion, setSecondOpinion] = useState(null);
  const [
    isSecondOpinionFetchApiInProgress,
    setIsSecondOpinionFetchApiInProgress
  ] = useState(true);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  useEffect(() => {
    baguetteBox.run(".gallery");
  }, []);

  useEffect(() => {
    if (secondOpinionId) {
      getSecondOpinion();
    }
  }, [secondOpinionId]);

  function getSecondOpinion() {
    if (secondOpinionId) {
      var params = {
        includeUploadedRecords: true
      };

      dispatch(showLoadingSpinner());
      setIsSecondOpinionFetchApiInProgress(true);
      getSecondOpinion_Ajax(
        secondOpinionId,
        params,
        function (response) {
          dispatch(hideLoadingSpinner());
          setIsSecondOpinionFetchApiInProgress(false);
          if (
            response &&
            response.success &&
            response.data &&
            response.data.secondOpinion
          ) {
            setSecondOpinion(response.data.secondOpinion);
          } else if (response && !response.success && response.message) {
            setSecondOpinion(null);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          setIsSecondOpinionFetchApiInProgress(false);
          handleApiErrors(err);
        }
      );
    }
  }

  function renderUploadedRecords() {
    if (secondOpinion && !isEmptyArray(secondOpinion.uploadedRecords)) {
      return (
        <>
          {secondOpinion.uploadedRecords.map((photo, index) => {
            if (isImageFile(photo.photoId)) {
              return (
                <a
                  href={photo.photoId}
                  data-bs-toggle="modal"
                  data-bs-target="#fileViewerImage"
                  className="lightbox"
                  data-caption="Image caption"
                  onClick={() => openFile(photo.photoId)}
                >
                  <img
                    className="second-opinion-uploads"
                    src={photo.photoId}
                    alt=""
                    onError={onImageError}
                  />
                </a>
              );
            } else if (isValidDocFileForPatientUpload(photo.photoId)) {
              return (
                <a
                  href={photo.photoId}
                  data-bs-toggle="modal"
                  data-bs-target="#fileViewerDoc"
                  className="img-file"
                  onClick={() => openFile(photo.photoId)}
                >
                  <img
                    src={isPDFfile(photo.photoId) ? IconPDF : GenericFileIcon}
                    className="second-opinion-uploads"
                    alt=""
                    onError={onImageError}
                  />
                </a>
              );
            } else {
              return <></>;
            }
          })}
        </>
      );
    } else {
      return <></>;
    }
  }

  function renderMessage(message) {
    if (message) {
      return (
        <div className="text-center mt-5 mb-5">
          <h2>{message}</h2>
        </div>
      );
    } else {
      return <></>;
    }
  }

  function openFile(url) {
    if (url) {
      dispatch(setFileUrlToOpen(url));
    }
  }

  function uploadMoreBtnClicked() {
    if (!isEmptyArray(uploadedFiles) && secondOpinionId && secondOpinion) {
      var apiKeyViewModelVM = {
        secondOpinionId: secondOpinionId,
        medicalRecordId: secondOpinion.medicalRecordId
      };

      var formData = new FormData();
      formData.append("apiKeyViewModel", JSON.stringify(apiKeyViewModelVM));

      if (!isEmptyArray(uploadedFiles)) {
        uploadedFiles.map((upload) => {
          if (upload && upload.file) {
            formData.append("photos", upload.file);
          }
        });
      }

      dispatch(showLoadingSpinner());
      saveSecondOpinionRecord_Ajax(
        formData,
        function (response) {
          if (response && response.success) {
            setUploadedFiles([]);
            getSecondOpinion();
            dispatch(hideLoadingSpinner());
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  function updateSelectedPhoto(event, uploadType) {
    if (event && event.target && event.target.files) {
      var arr = [];
      var imageMaxSizeInBytes = MaxAllowdFileSizes.IMAGE_MAX_SIZE_IN_MB_5;
      var FileExceededAllowedSize = [];

      if (
        uploadedFiles &&
        Array.isArray(uploadedFiles) &&
        uploadedFiles.length > 0
      ) {
        arr = [...uploadedFiles];
      }

      for (var i = 0; i < event.target.files.length; i++) {
        var file = event.target.files[i];
        var fileName = file.name.toLowerCase();

        if (isImageFile(fileName) || isValidDocFileForPatientUpload(fileName)) {
          var fileSizeErrMsg;

          if (isImageFile(fileName)) {
            fileSizeErrMsg = validateFileMaxSize([file], imageMaxSizeInBytes);
          } else if (isValidDocFileForPatientUpload(fileName)) {
            fileSizeErrMsg = validateFileMaxSize([file]);
          }

          if (!fileSizeErrMsg) {
            var tempPath = URL.createObjectURL(file);
            arr.push({
              url: tempPath,
              type: fileName.split(".").pop(),
              file: file,
              uploadType: uploadType
            });
          } else {
            FileExceededAllowedSize.push(fileName);
          }
        } else {
          showAlertDialouge("Error", "File type not supported");
        }
      }

      if (arr && arr.length > 0) {
        setUploadedFiles(arr);
      }

      if (FileExceededAllowedSize && FileExceededAllowedSize.length > 0) {
        showAlertDialouge(
          "Error",
          "The following file(s) have exceeded maximum allowed size: " +
            FileExceededAllowedSize.join(", ") +
            ". Please upload images less than " +
            MaxAllowdFileSizes.IMAGE_MAX_SIZE_IN_MB_5 +
            " MB and documents less than " +
            MaxAllowdFileSizes.FILE_MAX_SIZE_IN_MB +
            " MB"
        );
      }
    }
  }

  function deleteUploadedBtnClicked(fileIndex) {
    showConfirmDialouge(
      "Are you sure?",
      "Are you sure you want to Delete this uploaded record?",
      function () {
        deleteFileWithIndex(fileIndex);
      }
    );
  }

  function deleteFileWithIndex(fileIndex) {
    if (fileIndex < 0) return;

    var arr = [];

    if (
      uploadedFiles &&
      Array.isArray(uploadedFiles) &&
      uploadedFiles.length > 0
    ) {
      arr = uploadedFiles.filter((file, index) => {
        return index != fileIndex;
      });
    }

    if (arr) {
      setUploadedFiles(arr);
    }
  }

  function renderUploadedFiles(uploadType) {
    if (uploadedFiles && !isEmptyArray(uploadedFiles)) {
      var filterdListByType = uploadedFiles.filter((upload) => {
        return upload && upload.uploadType == uploadType;
      });

      if (!isEmptyArray(filterdListByType)) {
        return (
          <div className="form-group mt-4 mb-3 col-md-12 d-none111">
            <ul className="uploaded-file-wrapper p-0 m-0">
              {uploadedFiles.map((upload, index) => {
                if (upload.uploadType == uploadType) {
                  return (
                    <li className="d-flex flex-wrap mb-3">
                      <div className="left-wrapper">
                        {isImageFile(upload?.file?.name) ? (
                          <img src={upload?.url} onError={onImageError} />
                        ) : isValidDocFileForPatientUpload(
                            upload?.file?.name
                          ) ? (
                          isPDFfile(upload?.file?.name) ? (
                            <i className="bi bi-file-earmark-pdf-fill fs-1"></i>
                          ) : (
                            <i className="bi bi-file-earmark-text-fill fs-1"></i>
                          )
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="right-wrapper right-wrapper position-relative">
                        <div className="form-group-title fs-4 pe-4 pe-md-5 mb-1 lh-1 text-break">
                          {upload?.file?.name}
                        </div>
                        <span className="file-size fs-5 text-muted">
                          {Number(
                            convertKBFileSizeToMB(upload?.file?.size)
                          ).toFixed(2) + " MB"}
                        </span>
                        <a
                          href="javascript:;"
                          className="close text-danger px-1 position-absolute top-0 end-0"
                        >
                          <i
                            className="bi bi-x-lg"
                            onClick={() => deleteUploadedBtnClicked(index)}
                          ></i>
                        </a>
                      </div>
                    </li>
                  );
                } else {
                  return <></>;
                }
              })}
            </ul>
          </div>
        );
      } else {
        return <></>;
      }
    } else {
      return <></>;
    }
  }

  return (
    <>
      <div className="body-content">
        <section className="review-photo-section py-4 py-md-5">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="gth-main-header mb-3">
                  <div className="heading-wrapper back-to-wrapper position-relative">
                    <h1 className="title">Records Submitted</h1>
                  </div>
                </div>
                <div className="bg-white border-radius-xlg px-4 px-md-5 py-4 mb-4">
                  {isSecondOpinionFetchApiInProgress ? (
                    renderMessage("Loading medical records")
                  ) : !secondOpinion ? (
                    renderMessage("Second opinion data not found")
                  ) : (
                    <>
                      <div className="form-group mb-333 col-md-12">
                        <div className="d-flex flex-wrap justify-content-between">
                          <label className="form-label mb-1 me-3 w-auto">
                            Second Opinion
                          </label>
                          <span className="fs-5 text-violet text-decoration-none mb-1">
                            Submitted
                          </span>
                        </div>
                        <div className="text-muted mb-2 mb-md-3">
                          {formatDateTime(
                            secondOpinion?.createdOn,
                            DateTimeFormats.MMM_DD_YYYY
                          )}
                        </div>
                        {renderUploadedRecords()}
                      </div>

                      <h3 class="my-4 pt-2 text-secondary">
                        Upload More Records
                      </h3>
                      {secondOpinion?.status ==
                        SecondOpinionStatus.AdditionalInfoNeeded && (
                        <AdditionalInfoNeededPage
                          secondOpinionId={secondOpinionId}
                        />
                      )}
                      <div className="form-group mb-3 col-md-12">
                        <div className="upload-file-wrapper">
                          <input
                            type="file"
                            multiple
                            tabIndex={0}
                            className="gth-upload"
                            name=""
                            onChange={(event) =>
                              updateSelectedPhoto(event, UploadTypes.Image)
                            }
                          />
                          <p className="second-opinion-record btn btn-outline-secondary btn-rounded btn-lg w-100">
                            <i className="bi bi-cloud-upload"></i> &nbsp; Upload
                            X-Rays and Images
                          </p>
                        </div>
                      </div>
                      {renderUploadedFiles(UploadTypes.Image)}
                      <div className="form-group mb-3 col-md-12">
                        <div className="upload-file-wrapper">
                          <input
                            type="file"
                            tabIndex={0}
                            multiple
                            className="gth-upload"
                            name=""
                            onChange={(event) =>
                              updateSelectedPhoto(event, UploadTypes.Note)
                            }
                          />
                          <p className="second-opinion-record btn btn-outline-secondary btn-rounded btn-lg w-100">
                            <i className="bi bi-cloud-upload"></i> &nbsp; Upload
                            Proposed Treatment
                          </p>
                        </div>
                      </div>
                      {renderUploadedFiles(UploadTypes.Note)}
                      <div className="form-group mb-3 col-md-12">
                        <div className="upload-file-wrapper">
                          <input
                            type="file"
                            multiple
                            tabIndex={0}
                            className="gth-upload"
                            name=""
                            onChange={(event) =>
                              updateSelectedPhoto(event, UploadTypes.Other)
                            }
                          />
                          <p className="second-opinion-record btn btn-outline-secondary btn-rounded btn-lg w-100">
                            <i className="bi bi-cloud-upload"></i> &nbsp; Upload
                            Additional Files
                          </p>
                        </div>
                      </div>
                      {renderUploadedFiles(UploadTypes.Other)}
                      <br />
                      <br />
                      <div className="tab-focusable-background form-group text-center mt-4 col-md-12">
                        <button
                          className="btn btn-secondary btn-rounded btn-lg px-3 px-md-5 w-100 w-md-auto"
                          onClick={uploadMoreBtnClicked}
                        >
                          Upload More Records
                        </button>
                      </div>
                      <br />
                      <br />
                      <div className="d-flex flex-wrap justify-content-between">
                        <label className="form-label mb-1 me-3 w-auto">
                          Supported image extensions:{" "}
                          {getSupportedImageExtensions()}
                        </label>
                      </div>
                      <div className="d-flex flex-wrap justify-content-between">
                        <label className="form-label mb-1 me-3 w-auto">
                          Maximum allowed image size:{" "}
                          {MaxAllowdFileSizes.IMAGE_MAX_SIZE_IN_MB_5} MB
                        </label>
                      </div>
                      <div className="d-flex flex-wrap justify-content-between">
                        <label className="form-label mb-1 me-3 w-auto">
                          Supported document extensions:{" "}
                          {getSupportedDocExtensions()}
                        </label>
                      </div>
                      <div className="d-flex flex-wrap justify-content-between">
                        <label className="form-label mb-1 me-3 w-auto">
                          Maximum allowed document size:{" "}
                          {MaxAllowdFileSizes.FILE_MAX_SIZE_IN_MB} MB
                        </label>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* <script src="https://cdnjs.com/libraries/baguettebox.js"></script> */}
    </>
  );
};
export default CaseReviewPage;
