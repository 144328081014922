import React, { useRef } from "react";
import { useForm } from "react-hook-form";

const SubmitVerificationCodePage = (props) => {
  const {
    numDigits = 6,
    onSubmit = () => {},
    phoneNumber = "",
    onResend = () => {}
  } = props;
  const { handleSubmit, setValue, watch } = useForm();
  const inputRef = useRef(null);

  const handleInputChange = (e) => {
    const value = e.target.value.replace(/\D/g, "").slice(0, numDigits);
    setValue("otp", value);
  };

  const otpValue = watch("otp", "");
  const isAllFilled = otpValue?.length === numDigits;

  function onResendBtnClicked() {
    onResend();
  }

  function onSubmitBtnClicked(data) {
    const code = data.otp;
    if (code) {
      onSubmit(code);
    }
  }

  const handlePaste = (e) => {
    const pasteData = e.clipboardData
      .getData("Text")
      .replace(/\D/g, "")
      .slice(0, numDigits);
    if (pasteData.length === numDigits) {
      setValue("otp", pasteData);
      e.preventDefault();
    }
  };

  function onKeyPressed(event) {
    if (event?.key == "Enter") {
      onResendBtnClicked();
    }
  }

  return (
    <form
      className="bg-white border-radius-xlg px-4 px-md-5 py-4 otp-form"
      onSubmit={handleSubmit(onSubmitBtnClicked)}
    >
      <h2 className="text-left text-black fw-bold mb-2">
        Enter verification code
      </h2>
      <p className="text-muted">Enter the code we sent to {phoneNumber}</p>
      <div className="form-group text-center">
        <input
          ref={inputRef}
          name="otp"
          type="text"
          value={otpValue}
          onChange={handleInputChange}
          onPaste={handlePaste}
          maxLength={numDigits}
          className="otp-input text-center"
          autoComplete="one-time-code"
          inputMode="numeric"
          placeholder="Enter code"
        />
      </div>
      <label
        className="form-check-label text-center fs-12 text-muted mt-2 d-inline-block w-100"
        htmlFor="saveBillingInfo1"
      >
        Didn't receive a code?{" "}
        <span
          tabIndex={0}
          onClick={onResendBtnClicked}
          onKeyPress={(event) => onKeyPressed(event)}
          role="presentation"
        >
          <a className="text-primary">Resend</a>
        </span>
      </label>
      <div className="tab-focusable-background form-group text-center mt-2 mt-md-4 col-sm-12">
        <button
          type="submit"
          className="btn btn-secondary btn-rounded btn-lg px-5"
          disabled={!isAllFilled}
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default SubmitVerificationCodePage;
