import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { getPromoCodeDiscountDetails_Ajax } from "../../helpers/requests";
import { handleApiErrors } from "../../helpers/utils";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";

const SeeDentistPromo = (props) => {
  const {
    initialCharge,
    type,
    validPromoCode,
    setValidPromoCode,
    campaignId,
    secondOpinionSettingsId,
    shouldShowPromoCodeSection
  } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPatient = useSelector((state) => {
    if (state?.userData?.currentPatient != null) {
      return state?.userData?.currentPatient;
    } else if (location.state?.userData?.currentPatient != null) {
      return location.state?.userData?.currentPatient;
    }
    return null;
  });
  const [promocodeErrorMessage, setPromocodeErrorMessage] = useState(null);
  const [promoCode, setPromoCode] = useState(null);
  const [discountedAmount, setDiscountedAmount] = useState(0);

  const shouldDisplayCampaingChargeInfo = useSelector(
    (state) => state?.appSettings?.settingsData?.displayCampaignCharge
  );

  useEffect(
    function () {
      if (initialCharge > 0) {
        getCampaignsCharges();
      }
    },
    [initialCharge]
  );

  function getCampaignsCharges() {
    setDiscountedAmount(initialCharge);
  }

  function setPromoCodeEmpty() {
    setPromoCode("");
    setValidPromoCode(null);
    setDiscountedAmount(initialCharge);
  }

  function isApplyButtonEnabled() {
    return initialCharge > 0 && promoCode != null && promoCode != "";
  }

  function getPromoCodeDiscountDetails() {
    if ((promoCode, currentPatient)) {
      var params = {
        userId: currentPatient.userId,
        campaignId: campaignId,
        secondOpinionSettingsId: secondOpinionSettingsId
      };

      dispatch(showLoadingSpinner());
      getPromoCodeDiscountDetails_Ajax(
        promoCode,
        initialCharge,
        params,
        function (response) {
          dispatch(hideLoadingSpinner());

          if (response && response.success && response.data) {
            setValidPromoCode(promoCode);
            setDiscountedAmount(response.data.amountAfterDiscountApply);
            setPromocodeErrorMessage(null);
          } else if (response && !response.success && response.message) {
            setPromocodeErrorMessage(response.message);
            setValidPromoCode(null);
            setDiscountedAmount(initialCharge);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  return (
    <>
      {shouldShowPromoCodeSection() ? (
        <div className="col-md-12 col-lg-6 add-coupon-wrapper">
          <div className="mx-auto col-lg-10 col-xl-8 inner-wrapper">
            <legend className="fs-2 mb-3 mb-md-4">Your Order </legend>
            <div className="inner-coupon-sec py-4 mb-3 mb-md-4 fs-3 d-flex align-items-center border-top border-bottom">
              <div className="d-flex flex-wrap flex-grow-1 align-items-center">
                <div className="ms-3">{type}</div>
              </div>
              <div className="text-primary">
                {shouldDisplayCampaingChargeInfo && initialCharge > 0 ? (
                  discountedAmount != initialCharge ? (
                    <span className="text-primary">
                      <s className="text-danger"> ${initialCharge}</s> $
                      {discountedAmount}
                    </span>
                  ) : (
                    <span className="text-primary"> ${discountedAmount}</span>
                  )
                ) : (
                  <></>
                )}
              </div>
            </div>
            <label className="text-primary form-label">Add Coupon Code</label>
            <div className="tab-focusable-background d-flex form-group">
              <div
                id="promo-code"
                className="flex-grow-1 me-3 position-relative"
              >
                <input
                  type="text"
                  className="form-control pe-5"
                  aria-labelledby="promo-code"
                  value={promoCode}
                  onChange={(e) => setPromoCode(e.target.value)}
                />
                <a
                  className="code-dismiss me-2 position-absolute  top-50 end-0 translate-middle-y"
                  role="button"
                  aria-label="Close"
                >
                  <i
                    className="bi bi-x-circle-fill text-gold fs-2"
                    onClick={setPromoCodeEmpty}
                  ></i>
                </a>
              </div>
              <button
                className="btn btn-primary align-content-center"
                type="submit"
                onClick={getPromoCodeDiscountDetails}
                disabled={!isApplyButtonEnabled()}
              >
                Apply
              </button>
            </div>
            <div className="text-gold mt-2">
              {promocodeErrorMessage != null ? promocodeErrorMessage : <></>}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default SeeDentistPromo;
