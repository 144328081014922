import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import SecondOpinionIcon from "./../../assets/images/icon-second-opinion.svg";
import { queryStringToJSON } from "../../helpers/utils";

const InstructionPage = () => {
  const [includeZState, setIncludeZState] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    var params = queryStringToJSON();
    var includeZState = false;
    if (params.includezstate) {
      if (params.includezstate == "true") {
        includeZState = true;
      }
    }
    setIncludeZState(includeZState);
  }, []);

  function onNextBtnClicked() {
    navigate(
      includeZState
        ? "/questionnaire-intake-form?includeZState=true"
        : "/questionnaire-intake-form"
    );
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <div className="gth-main-header mb-3">
            <div className="heading-wrapper back-to-wrapper position-relative">
              <h1 className="title">Get a Second Opinion</h1>
            </div>
          </div>
          <div className="bg-white border-radius-xlg px-4 px-md-5 py-4">
            <div className="fixed-button-bottom text-center">
              <div className="border border-transparent">
                <figure className="mt-2 mt-md-4 mb-4 mb-md-5">
                  <img
                    style={{ width: "180px" }}
                    src={SecondOpinionIcon}
                    alt=""
                  />
                </figure>
                <h2 className="mb-4">How It Works</h2>
                <ul className="list-unstyled instructions-list text-start d-table mb-0 mx-auto">
                  <li className="mb-3 w-100 w-md-auto position-relative">
                    <i className="bi bi-chevron-right fs-5 position-absolute start-0"></i>{" "}
                    Follow our guide to request your records or upload your own
                  </li>
                  <li className="mb-3 w-100 w-md-auto position-relative">
                    <i className="bi bi-chevron-right fs-5 position-absolute start-0"></i>{" "}
                    A licensed dentist will review your case
                  </li>
                  <li className="mb-3 w-100 w-md-auto position-relative">
                    <i className="bi bi-chevron-right fs-5 position-absolute start-0"></i>{" "}
                    Receive your second opinion report
                  </li>
                </ul>
                <div className="text-primary my-3 my-md-4">
                  Your second opinion report will give supporting documentation
                  regarding your specific case.
                </div>

                <button
                  className="tab-focusable-background btn btn-secondary btn-rounded btn-lg px-4 px-md-5 mt-3 mt-md-0 w-100 w-md-auto"
                  onClick={onNextBtnClicked}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default InstructionPage;
