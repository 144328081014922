import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { useNavigate } from "react-router-dom";
import {
  isEmailAddressValid,
  isPhoneNumberValid,
  showAlertDialouge
} from "../../helpers/utils";
import { saveSecondOpinionRecordRequestProgress } from "../../indexedDbHelper/secondOpinionRecordRequestProgressIndexedDbHelper";

const QuestionnaireRecordRequestForm = ({
  stateChanger,
  patientId,
  pageStates,
  formData
}) => {
  const navigate = useNavigate();

  const [inputs, setInputs] = useState({
    dentistName: "",
    email: "",
    phone: ""
  });

  const { dentistName, email, phone } = inputs;

  useEffect(() => {
    if (formData) {
      setInputs(formData);
    }
  }, [formData]);

  function handleChange(e) {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  }

  function isInputValid() {
    var errorMessage = "";

    if (!dentistName) {
      errorMessage = "Please enter dentist name.";
    } else if (!email || !isEmailAddressValid(email)) {
      errorMessage = "Please enter a valid email address.";
    } else if (phone && !isPhoneNumberValid(phone)) {
      errorMessage = "Please enter a valid phone number.";
    }

    if (errorMessage) {
      showAlertDialouge("Error", errorMessage);
    } else {
      return true;
    }
  }

  function nextBtnClicked() {
    if (isInputValid()) {
      saveSecondOpinionRecordRequestProgress(
        {
          ...pageStates,
          recordRequestProviderData: inputs,
          patientId: patientId
        },
        patientId
      );

      navigate("/consent-form", {
        state: {
          recordRequestProviderData: inputs,
          patientId: patientId
        }
      });
    }
  }

  function isNextBtnEnabled() {
    return dentistName && email && isEmailAddressValid(email);
  }

  return (
    <>
      <fieldset className="d-none111">
        <div className="min-vh">
          <legend className="fs-1 mb-3 mb-md-4">Records Request</legend>
          <div className="row">
            <div className="fs-3 mt-1 mb-3 mb-md-4">
              Enter your dentist's information and we'll request your records on
              your behalf
            </div>
            <div className="form-group mb-3 col-md-12">
              <label for="name" className="form-label">
                Dentist's Name or Practice Name
                <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                id="name"
                name="dentistName"
                value={dentistName}
                className="form-control"
                placeholder="Dentist's Name"
                onChange={handleChange}
              />
            </div>
            <div className="form-group mb-3 col-md-6">
              <label for="email" className="form-label">
                Dentist's Email Address <span className="text-danger">*</span>
              </label>
              <input
                type="email"
                name="email"
                id="email"
                value={email}
                className="form-control"
                placeholder="Dentist's Email Address"
                onChange={handleChange}
              />
            </div>
            <div className="form-group mb-3 col-md-6">
              <label for="phone" className="form-label">
                Dentist's Phone Number
              </label>
              <InputMask
                mask="(999)-999-9999"
                name="phone"
                id="phone"
                value={phone}
                className="form-control"
                placeholder="(XXX)-XXX-XXXX"
                onChange={handleChange}
              />
            </div>
            <div className="tab-focusable-background form-group text-center mt-3 mt-md-5 col-sm-12 button-wrapper">
              <button
                type="button"
                className="btn btn-outline btn-rounded btn-lg px-5 mx-md-2 mt-3 mt-md-0 w-100 w-md-auto"
                onClick={() => stateChanger(false)}
              >
                Cancel
              </button>
              {/* <!-- <span className="fs-5 text-muted d-table mt-1 mx-auto">(Fake consent form)</span> --> */}
              <button
                disabled={!isNextBtnEnabled()}
                onClick={nextBtnClicked}
                className="btn btn-secondary btn-rounded btn-lg px-4 px-md-5 mx-md-2 mt-3 mt-md-0 w-100 w-md-auto"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </fieldset>
    </>
  );
};

export default QuestionnaireRecordRequestForm;
