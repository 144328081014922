import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  arrayBufferToBlob,
  getSmartScanImageObjectNameForState,
  getSmartScanStepNo
} from "../../helpers/utils";
import { getSmartScanImageBasedOnCaptureOption } from "../../helpers/smartScanUtils";
import { getIsSmartScanSelfieCaptureProgressCachingEnabled } from "../../ApplicationSettings";
import { useRef } from "react";

const SmartScanSelfieConfirm = (props) => {
  const { nextPagePath, retakePagePath, state, category } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const [selfieSrc, setSelfieSrc] = useState();
  const poseImageRef = useRef();

  const [isExampleImageExpanded, setIsExampleImageExpanded] = useState(false);

  const expandExampleImage = () => {
    setIsExampleImageExpanded((value) => !value);
  };

  const poseImage = getSmartScanImageBasedOnCaptureOption(
    state?.captureOption,
    category
  );

  useEffect(() => {
    if (state) {
      setSmartScanCategoryPhotoURL(category);
    }
  }, [state]);

  function getNewState() {
    let newState;
    if (getIsSmartScanSelfieCaptureProgressCachingEnabled()) {
      newState = {
        patientId: state.patientId,
        isAuthenticated: state.isAuthenticated
      };
      if (state.isRetake) {
        newState.isRetake = state.isRetake;
      }
    } else {
      newState = { ...state };
    }

    return newState;
  }

  function confirmBtnClicked() {
    let newState = getNewState();

    if (newState?.isRetake) {
      navigate(
        newState.isAuthenticated
          ? "/smart-scan-pose"
          : "/smart-scan-pose-no-auth",
        {
          state: newState
        }
      );
    } else if (nextPagePath) {
      navigate(nextPagePath, {
        state: newState
      });
    }
  }

  function retakeBtnClicked() {
    let newState = getNewState();
    if (retakePagePath) {
      navigate(retakePagePath, {
        state: newState
      });
    }
  }

  function getProgress() {
    let stepNo = getSmartScanStepNo(category);
    let totalSteps = 5;

    let width = (stepNo / totalSteps) * 100;

    return (
      <div className="progress mb-3">
        <div
          className="progress-bar fs-6 pb-1"
          role="progressbar"
          style={{ width: width + "%" }}
          aria-valuenow="16.66"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          {stepNo + " out of " + totalSteps}
        </div>
      </div>
    );
  }

  function setSmartScanCategoryPhotoURL(category) {
    if (
      category &&
      state[getSmartScanImageObjectNameForState(category)]?.file
    ) {
      let imageFile;
      if (getIsSmartScanSelfieCaptureProgressCachingEnabled()) {
        imageFile = arrayBufferToBlob(
          state[getSmartScanImageObjectNameForState(category)]?.file,
          state[getSmartScanImageObjectNameForState(category)]?.name,
          state[getSmartScanImageObjectNameForState(category)]?.mimeType
        );
      } else {
        imageFile = state[getSmartScanImageObjectNameForState(category)].file;
      }

      let objURL = URL.createObjectURL(imageFile);
      setSelfieSrc(objURL);
    }
  }

  function onPoseImageKeyPressed(event) {
    if (event?.key == "Enter") {
      poseImageRef.current.click();
    }
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            {getProgress()}
            <div className="bg-white border-radius-xlg position-relative">
              {selfieSrc ? (
                <div className="bg-white border-radius-xlg position-relative">
                  <figure className="mb-0">
                    <img
                      className="w-100 border-radius-xlg"
                      src={selfieSrc}
                      alt="smart scan captured photo"
                    />

                    <div
                      className={`smartscan-ref-img p-3 p-md-4 position-absolute border-radius-xlg ${
                        isExampleImageExpanded ? "expand" : ""
                      }`}
                      onClick={expandExampleImage}
                    >
                      <div className="w-100 h-100 position-relative">
                        <img
                          className="border-radius-xlg"
                          src={poseImage}
                          alt="smart scan pose photo"
                          tabIndex={0}
                          ref={poseImageRef}
                          onKeyPress={onPoseImageKeyPressed}
                        />
                        {isExampleImageExpanded ? (
                          <i className="bi bi-x position-absolute zoomout-img top-0 end-0 p-2 fs-1 fw-bold text-danger"></i>
                        ) : (
                          <i className="bi bi-arrows-angle-expand expand-img position-absolute bottom-0 end-0 p-2 p-md-3 fs-3 fw-bold text-white"></i>
                        )}
                      </div>
                    </div>
                  </figure>
                </div>
              ) : (
                <></>
              )}
              <div className="tab-focusable-background text-center px-3 px-md-5 py-4 w-100 position-absolute start-0 bottom-0">
                <h2 className="text-white mb-4 mb-md-5">
                  Does your photo match the example?
                </h2>
                <button
                  onClick={retakeBtnClicked}
                  tabIndex={0}
                  className="btn btn-lg bg-white btn-rounded text-primary text-decoration-none px-4 px-md-5 mx-1 mx-md-2"
                >
                  Retake
                </button>
                <button
                  onClick={confirmBtnClicked}
                  tabIndex={0}
                  className="btn btn-lg btn-primary btn-rounded text-white text-decoration-none px-4 px-md-5 mx-1 mx-md-2"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SmartScanSelfieConfirm;
