import React, { useState, useEffect } from "react";
import { savePatientProfileSettings_Ajax } from "../../helpers/requests";
import {
  handleApiErrors,
  isEmptyArray,
  isPatientDateOfBirthValid,
  showAlertDialouge,
  getDefaultSelectedPatientId
} from "../../helpers/utils";
import { Roles, AddDependentSuccessMessage } from "../../constants";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import {
  setIsFamilyMembersListChanged,
  saveCurrentPatient
} from "../../redux/actions/userData";
import { getPatientInfo } from "../../helpers/authManager";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import AddDependentForm from "../AddDependentForm/AddDependentForm";
import { useForm } from "react-hook-form";
import ReactTooltip from "react-tooltip";
import {
  clearSmartScanSelfieCaptureProgress,
  saveSmartScanSelfieCaptureProgress
} from "../../indexedDbHelper/smartScanProgressIndexedDbHelper";
import { getPatientOrigin } from "../../ApplicationSettings";

const SmartScanSelectPatientPage = () => {
  const formRef = useForm({ mode: "onChange" });
  const { state } = useLocation();

  const [patientId, setPatientId] = useState(0);
  const [isAddFamilyMemberFormVisible, setIsAddFamilyMemberFormVisible] =
    useState(false);
  const officeId = useSelector(
    (state) => state?.appSettings?.settingsData?.officeId
  );
  const patientAndFamilyMemberList = useSelector(
    (state) => state.userData.patientAndFamilyMembersList
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  function handlePatientChange(e) {
    const patientId = e.target.value;
    clearSmartScanSelfieCaptureProgress(patientId);
    setPatientId(patientId);
    setExistingFamilyMemberAsCurrentPatient(patientId);
  }

  function isNextBtnEnabled() {
    return patientId > 0;
  }

  function toogleAddFamilyMemberFormVisibility() {
    setIsAddFamilyMemberFormVisible(!isAddFamilyMemberFormVisible);
  }

  async function navigateToDisclaimerPage() {
    if (patientId > 0) {
      await saveSmartScanSelfieCaptureProgress(
        {
          patientId: patientId,
          isAuthenticated: true
        },
        patientId
      );

      navigate("/smart-scan-disclaimer", {
        state: {
          patientId: patientId
        }
      });
    }
  }

  function prepareNewDependentData(formData) {
    if (formData) {
      let patientInfo = getPatientInfo();

      return {
        firstName: formData.firstName,
        lastName: formData.lastName,
        gender: formData.gender,
        dateOfBirth: formData.dateOfBirth,
        respPartyUserId: patientInfo.user.id,
        isRespPartyIncluded: true,
        pgId: patientInfo.pgid,
        address1: patientInfo.address1,
        address2: patientInfo.address2,
        country: patientInfo.country,
        city: patientInfo.city,
        timezone: patientInfo.timezone,
        state: patientInfo.state,
        zip: patientInfo.zip,
        emergencyContactPhone: patientInfo.emergencyContactPhone,
        activeOfficeId: officeId,
        origin: getPatientOrigin(),
        newUser: {
          pgId: patientInfo.pgid,
          firstName: formData.firstName,
          lastName: formData.lastName,
          dateOfBirth: formData.dateOfBirth,
          roleId: Roles.Patient,
          gender: formData.gender,
          country: patientInfo.country,
          city: patientInfo.city,
          timezone: patientInfo.timezone,
          state: patientInfo.state,
          zip: patientInfo.zip
        },
        respParty: {
          pgId: patientInfo.pgid,
          email: patientInfo.user.userId,
          phone: patientInfo.user.phone,
          firstName: patientInfo.firstName,
          lastName: patientInfo.lastName,
          relationToPatient: formData.relation
        }
      };
    }
  }

  function addDependent(depdendentformData) {
    if (depdendentformData) {
      dispatch(showLoadingSpinner());
      savePatientProfileSettings_Ajax(
        depdendentformData,
        function (response) {
          dispatch(hideLoadingSpinner());

          if (response && response.success && response.data) {
            formRef.reset();
            setIsAddFamilyMemberFormVisible(false);
            setPatientId(response.data.patid);
            showAlertDialouge("Message", AddDependentSuccessMessage);
            setCurrentPatient(response.data);
            dispatch(setIsFamilyMembersListChanged(true));
          } else if (response && response.success && response.message) {
            showAlertDialouge("Error", response.message);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  function validateAddDependentFormData(formData) {
    let isValid = true;
    let errorMessages = [];

    if (formData) {
      if (formData.dateOfBirth) {
        let dobValidityCheck = isPatientDateOfBirthValid(formData.dateOfBirth);

        if (!dobValidityCheck.valid) {
          isValid = false;
          errorMessages.push(dobValidityCheck.errorMessage);
        }
      } else if (!formData.dateOfBirth) {
        isValid = false;
        errorMessages.push("Please enter a valid date of birth.");
      }
    } else {
      isValid = false;
    }

    if (isValid) {
      return { isValid };
    }

    return { isValid, errorMessages };
  }

  function addDependentBtnClicked(formData) {
    if (isAddFamilyMemberFormVisible && formData) {
      let formDataValidationObj = validateAddDependentFormData(formData);

      if (formDataValidationObj.isValid) {
        let dependentData = prepareNewDependentData(formData);

        if (dependentData) {
          let depdendentformData = new FormData();
          depdendentformData.append("patientVM", JSON.stringify(dependentData));
          addDependent(depdendentformData);
        }
      } else if (
        !formDataValidationObj.isValid &&
        formDataValidationObj.errorMessages
      ) {
        showAlertDialouge(
          "Error",
          formDataValidationObj.errorMessages.join(" ")
        );
        return;
      }
    }
  }

  function setCurrentPatient(patientInfo) {
    if (patientInfo) {
      dispatch(saveCurrentPatient(patientInfo));
    }
  }

  function setExistingFamilyMemberAsCurrentPatient(patientId) {
    if (patientId) {
      let patientInfo = patientAndFamilyMemberList.find(
        (member) => member.patid == patientId
      );
      setCurrentPatient(patientInfo);
    }
  }

  useEffect(() => {
    let patId = 0;
    if (state?.patientId) {
      patId = state?.patientId;
    } else {
      patId = getDefaultSelectedPatientId(patientAndFamilyMemberList);
    }

    setPatientId(patId);
  }, [patientAndFamilyMemberList, state]);

  function goToPreviousPage() {
    navigate("/smart-scan-works", {
      state: {
        ...state
      }
    });
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="gth-main-header mb-3">
              <div className="heading-wrapper back-to-wrapper position-relative">
                <h1 className="title">Smart Scan</h1>
              </div>
            </div>
            <div className="bg-white border-radius-xlg px-4 px-md-5 py-4">
              <div>
                <div className="border border-transparent">
                  <div className="form-group mt-4 mb-3 col-md-8 col-xl-6">
                    <div className="form-group-title fs-3 text-violet mb-2 mb-md-3 lh-1">
                      Who is this smart scan for?{" "}
                      <span className="text-danger">*</span>
                    </div>
                    <div className="form-check mb-2 ps-0">
                      <select
                        className="form-select form-control"
                        name="dependentsList"
                        id=""
                        value={patientId}
                        disabled={isAddFamilyMemberFormVisible}
                        onChange={handlePatientChange}
                        aria-label="patient name"
                      >
                        <option value="">Select Patient</option>
                        {!isEmptyArray(patientAndFamilyMemberList) &&
                          patientAndFamilyMemberList.map((patient, index) => (
                            <option
                              key={patient.fullName + patient.patid}
                              value={patient.patid}
                            >
                              {patient.fullName}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div
                      className="mt-2"
                      onClick={toogleAddFamilyMemberFormVisibility}
                    >
                      <a
                        href="javascript:;"
                        tabIndex={0}
                        className="text-muted"
                      >
                        <em className="bi bi-plus"> </em>
                        Add Dependent
                        <em
                          className="bi bi-info-circle-fill fs-3 text-primary ms-2"
                          data-place="top"
                          data-for="dashboardToolTip"
                          data-type="light"
                          data-tip="A dependent is defined as children under the age of 18, elderly parents, or disabled adult children."
                        ></em>
                        <ReactTooltip
                          id="dashboardToolTip"
                          className="tooltip"
                        />
                      </a>
                    </div>

                    <div
                      id="show-hide-div"
                      style={{
                        display: isAddFamilyMemberFormVisible ? "flex" : "none"
                      }}
                    >
                      <AddDependentForm
                        formRef={formRef}
                        onSubmit={addDependentBtnClicked}
                      />
                    </div>
                  </div>
                </div>
                <button
                  className="tab-focusable-background btn btn-secondary btn-rounded btn-lg px-4 px-md-5 mt-4 mt-md-0 w-100 w-md-auto start-0 btn-bottom-0"
                  id="billing-info-tab"
                  type="button"
                  onClick={navigateToDisclaimerPage}
                  disabled={!isNextBtnEnabled()}
                  tabIndex={0}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SmartScanSelectPatientPage;
