import { getApiUrl, getBaseUrl, getPgId } from "../ApplicationSettings";
import { postJsonData, getJson, postFormData, getBlobJson } from "./httpReq";
import { queryStringToJSON } from "./utils";
import $ from "jquery";

export const login_Ajax = (jsonData, success_callback, error_callback) => {
  var url = getApiUrl() + "/api/account/token";
  postJsonData(url, "POST", jsonData, success_callback, error_callback);
};

export const addDentistDirectReferral_Ajax = (
  jsonData,
  success_callback,
  error_callback
) => {
  const url = getApiUrl() + "/api/dentistdirectreferrals";
  postJsonData(url, "POST", jsonData, success_callback, error_callback);
};

export const getCountries_Ajax = (success_callback, error_callback) => {
  var url = getApiUrl() + "/api/countries";
  getJson(url, success_callback, error_callback);
};

export const getStates_Ajax = (
  countryCode,
  success_callback,
  error_callback
) => {
  var params = queryStringToJSON();
  var includeZState = false;
  if (params.includezstate) {
    if (params.includezstate == "true") {
      includeZState = true;
    }
  }

  var url =
    getApiUrl() +
    "/api/countries/" +
    countryCode +
    "/states?includeZState=" +
    includeZState;

  getJson(url, success_callback, error_callback);
};

export const getCityOrZips_Ajax = (
  countryCode,
  state,
  city,
  zipCode,
  currentPgId,
  success_callback,
  error_callback
) => {
  var params = {
    state: state || "",
    city: city || "",
    zipCode: zipCode || "",
    pgId: currentPgId
  };

  var url =
    getApiUrl() +
    "/api/countries/" +
    countryCode +
    "/city_zip?" +
    $.param(params);
  getJson(url, success_callback, error_callback);
};

export const registerUser_Ajax = (
  jsonData,
  homeOfficeId,
  success_callback,
  error_callback
) => {
  var url =
    getApiUrl() +
    "/api/register/?returnUrl=" +
    getBaseUrl() +
    "&isDentalPatient=true&homeOfficeId=" +
    homeOfficeId;
  postJsonData(url, "POST", jsonData, success_callback, error_callback);
};

export const forgotPassword_Ajax = (
  jsonData,
  success_callback,
  error_callback
) => {
  var url =
    getApiUrl() + "/api/account/forgotpassword/?returnUrl=" + getBaseUrl();
  postJsonData(url, "POST", jsonData, success_callback, error_callback);
};

export const getMedicalRecords_Ajax = (
  patId,
  itemPerPage,
  currentPageNum,
  success_callback,
  error_callback
) => {
  var url = "";

  var data = {
    patId: patId || 0,
    itemPerPage: itemPerPage || 0,
    currentPageNum: currentPageNum || 0
  };

  url = getApiUrl() + "/api/patient/records";
  url += "?" + $.param(data);

  getJson(url, success_callback, error_callback);
};

export const saveMedicalRecord_ajax = (
  formData,
  success_callback,
  error_callback
) => {
  var url = getApiUrl() + "/api/patient/record";
  postFormData(url, "PUT", formData, success_callback, error_callback);
};

export const verifyEmail_Ajax = (
  jsonData,
  success_callback,
  error_callback
) => {
  var url = getApiUrl() + "/api/verifyemail/";
  postJsonData(url, "POST", jsonData, success_callback, error_callback);
};

export const deleteMedicalRecord_Ajax = (
  recordId,
  success_callback,
  error_callback
) => {
  var url = getApiUrl() + "/api/patient/record/" + recordId;
  postJsonData(url, "DELETE", {}, success_callback, error_callback);
};

export const getProviderData_Ajax = (
  providerId,
  success_callback,
  error_callback
) => {
  var url = getApiUrl() + "/api/providers/" + providerId;
  getJson(url, success_callback, error_callback);
};

export const getPatientNotes_Ajax = (
  patientId,
  params,
  success_callback,
  error_callback
) => {
  var url =
    getApiUrl() +
    "/api/provider/progress_notes/v2/" +
    patientId +
    "?" +
    $.param(params);
  getJson(url, success_callback, error_callback);
};

export const getProgressNoteAttachments_Ajax = (
  noteId,
  noteType,
  success_callback,
  error_callback
) => {
  var url =
    getApiUrl() +
    "/api/provider/progress_notes/attachments/" +
    noteId +
    "/" +
    noteType;
  getJson(url, success_callback, error_callback);
};

export const getNotePrintDetails_Ajax = (
  noteId,
  noteType,
  success_callback,
  error_callback
) => {
  var url =
    getApiUrl() +
    "/api/provider/progress_notes/printdetails/" +
    noteId +
    "/" +
    noteType;
  getJson(url, success_callback, error_callback);
};

export const getPatientsIncompleteReferralSuggestion_Ajax = (
  patientId,
  success_callback,
  error_callback
) => {
  var url = getApiUrl() + "/api/referral/suggestion/" + patientId;
  getJson(url, success_callback, error_callback);
};

export const getPatientReferrals_Ajax = (
  params,
  success_callback,
  error_callback
) => {
  var url = getApiUrl() + "/api/patient/ref_info?" + $.param(params);
  getJson(url, success_callback, error_callback);
};

export const getRecommendedProviders_Ajax = (
  zip,
  params,
  success_callback,
  error_callback
) => {
  var url =
    getApiUrl() +
    "/api/referral/recommended/providers/" +
    zip.trim() +
    "?" +
    $.param(params);
  getJson(url, success_callback, error_callback);
};

export const referToDoctor_Ajax = (
  jsonData,
  success_callback,
  error_callback
) => {
  var url = getApiUrl() + "/api/provider/refer";
  postJsonData(url, "PUT", jsonData, success_callback, error_callback);
};

export const redirectToManageBilling_Ajax = (
  patientId,
  email,
  jsonData,
  success_callback,
  error_callback
) => {
  var url =
    getApiUrl() + "/api/payment/" + patientId + "/createCustomer/" + email;
  postJsonData(url, "PUT", jsonData, success_callback, error_callback);
};

export const getPaymentInfo_Ajax = (
  patId,
  params,
  success_callback,
  error_callback
) => {
  var url =
    getApiUrl() +
    "/api/payment/lastsavedpaymentmethod/" +
    patId +
    "?" +
    $.param(params);
  getJson(url, success_callback, error_callback);
};

export const getTimezones_Ajax = (
  countryCode,
  success_callback,
  error_callback
) => {
  var url = getApiUrl() + "/api/countries/" + countryCode + "/timezones";
  getJson(url, success_callback, error_callback);
};

export const getPatientProfileSettings_Ajax = (
  patId,
  success_callback,
  error_callback
) => {
  var url = getApiUrl() + "/api/patient/" + patId;
  getJson(url, success_callback, error_callback);
};

export const savePatientProfileSettings_Ajax = (
  formData,
  success_callback,
  error_callback
) => {
  var url = getApiUrl() + "/api/patient";
  postFormData(url, "PUT", formData, success_callback, error_callback);
};

export const GetPatientInsuranceInfo_Ajax = (
  patientId,
  params,
  success_callback,
  error_callback
) => {
  var url = getApiUrl() + "/api/patient/insurance_info/" + patientId;
  if (params) {
    url += "?" + $.param(params);
  }

  getJson(url, success_callback, error_callback);
};

export const getOnlineCsrs_Ajax = (success_callback, error_callback) => {
  var url = getApiUrl() + "/api/staff/onlinecsrs/";

  getJson(url, success_callback, error_callback);
};

export const getChatRoom_Ajax = (
  currentUserId,
  chatParticipants,
  success_callback,
  error_callback
) => {
  var url =
    getApiUrl() +
    "/api/chat/chatroom/create/" +
    currentUserId +
    "/" +
    chatParticipants.join(",");

  getJson(url, success_callback, error_callback);
};

export const getChatRoomMessages_Ajax = (
  chatRoomId,
  params,
  success_callback,
  error_callback
) => {
  var url = getApiUrl() + "/api/chat/chatroom/messages/" + chatRoomId;

  if (params) {
    url += "?" + $.param(params);
  }

  getJson(url, success_callback, error_callback);
};

export const getChatMessageAttachments_Ajax = (
  messageId,
  success_callback,
  error_callback
) => {
  var url =
    getApiUrl() + "/api/chat/chatroom/messages/attachments/" + messageId;
  getJson(url, success_callback, error_callback);
};

export const saveMessage_Ajax = (
  chatRoomId,
  currentUserId,
  shouldSendEmailNotification,
  formData,
  success_callback,
  error_callback
) => {
  var url =
    getApiUrl() +
    "/api/chat/chatroom/messages/" +
    chatRoomId +
    "/" +
    currentUserId +
    "/" +
    shouldSendEmailNotification;
  postFormData(url, "PUT", formData, success_callback, error_callback);
};

export const changePassword_Ajax = (
  jsonData,
  success_callback,
  error_callback
) => {
  var url = getApiUrl() + "/api/account/changePassword/";
  postJsonData(url, "POST", jsonData, success_callback, error_callback);
};

export const getPatientEducations_Ajax = (
  patientId,
  success_callback,
  error_callback
) => {
  var url = getApiUrl() + "/api/patients/" + patientId + "/educations";
  getJson(url, success_callback, error_callback);
};

export const getPrescriptions_Ajax = (
  params,
  success_callback,
  error_callback
) => {
  var url = getApiUrl() + "/api/provider/prescriptions/?" + $.param(params);
  getJson(url, success_callback, error_callback);
};

export const getFamilyMembers_Ajax = (
  onlyFamilyMembersOptions,
  success_callback,
  error_callback
) => {
  var url = getApiUrl() + "/api/patients/family/";

  if (onlyFamilyMembersOptions) {
    url = url + "?onlyFamilyMembersOptions=" + onlyFamilyMembersOptions;
  }

  getJson(url, success_callback, error_callback);
};

export const getBasicCampaignsInfo_Ajax = (
  params,
  success_callback,
  error_callback
) => {
  var url = getApiUrl() + "/api/campaigns/basic_info";

  if (params) {
    url = url + "?" + $.param(params);
  }

  getJson(url, success_callback, error_callback);
};

export const getAssociatedOnDemandCampaigns_Ajax = (
  success_callback,
  error_callback
) => {
  var url = getApiUrl() + "/api/ondemandconsultation/patcampaigns";

  getJson(url, success_callback, error_callback);
};

export const getCampaign_Ajax = (params, success_callback, error_callback) => {
  var url = getApiUrl() + "/api/campaigns/PatientPortal";

  if (params) {
    url = url + "?" + $.param(params);
  }

  getJson(url, success_callback, error_callback);
};

export const getPatientActiveOfficeCampaign_Ajax = (
  success_callback,
  error_callback
) => {
  var url = getApiUrl() + "/api/campaigns/patienthomeoffice/active";

  getJson(url, success_callback, error_callback);
};

export const getCampaignSlots_Ajax = (
  campaignId,
  officeId,
  packageId,
  params,
  success_callback,
  error_callback
) => {
  var url =
    getApiUrl() +
    "/api/campaign/slots/" +
    campaignId +
    "/" +
    officeId +
    "/" +
    packageId;

  if (params) {
    url = url + "?" + $.param(params);
  }

  getJson(url, success_callback, error_callback);
};

export const getCampaignProviders_Ajax = (
  campaignId,
  officeId,
  packageId,
  success_callback,
  error_callback
) => {
  var url =
    getApiUrl() +
    "/api/campaign/providers/" +
    campaignId +
    "/" +
    officeId +
    "/" +
    packageId;

  getJson(url, success_callback, error_callback);
};

export const getUpcomingAppointment_Ajax = (
  patientId,
  campaignId,
  params,
  success_callback,
  error_callback
) => {
  var url =
    getApiUrl() +
    "/api/campaign/appointment/upcoming/" +
    patientId +
    "/" +
    campaignId;

  if (params) {
    url = url + "?" + $.param(params);
  }

  getJson(url, success_callback, error_callback);
};

export const seeDentistNow_Ajax = (
  campaignId,
  params,
  jsonData,
  success_callback,
  error_callback
) => {
  var url = getApiUrl() + "/api/ondemandconsultation/" + campaignId;
  if (params) {
    url = url + "?" + $.param(params);
  }
  postJsonData(url, "PUT", jsonData, success_callback, error_callback);
};

export const saveMedicalHistory_Ajax = (
  medicalFormId,
  params,
  jsonData,
  success_callback,
  error_callback
) => {
  var url = getApiUrl() + "/api/medical_form/" + medicalFormId;

  if (params) {
    url = url + "?" + $.param(params);
  }

  postJsonData(url, "PUT", jsonData, success_callback, error_callback);
};

export const getOnDemandConsultations_Ajax = (
  params,
  success_callback,
  error_callback
) => {
  var url = getApiUrl() + "/api/ondemandconsultation?" + $.param(params);
  getJson(url, success_callback, error_callback);
};

export const getAppointments_Ajax = (
  patientId,
  campaignId,
  params,
  success_callback,
  error_callback
) => {
  var url =
    getApiUrl() +
    "/api/campaign/appointment/" +
    patientId +
    "/" +
    campaignId +
    "?" +
    $.param(params);
  getJson(url, success_callback, error_callback);
};

export const getNotifications_Ajax = (
  unseenOnly,
  success_callback,
  error_callback
) => {
  var url =
    getApiUrl() +
    "/api/notifications?unseenOnly=" +
    unseenOnly +
    "&sendAll=true";
  getJson(url, success_callback, error_callback);
};

export const processNotification_Ajax = (
  notificationId,
  success_callback,
  error_callback
) => {
  var url = getApiUrl() + "/api/notification/seen/" + notificationId;
  getJson(url, success_callback, error_callback);
};

export const addAppointment_Ajax = (
  campaignId,
  timezoneOffset,
  jsonData,
  success_callback,
  error_callback
) => {
  var url =
    getApiUrl() +
    "/api/campaign/appointment/" +
    campaignId +
    "?localTimezoneOffset=" +
    timezoneOffset;
  postJsonData(url, "PUT", jsonData, success_callback, error_callback);
};

export const getAssignedForms_Ajax = (
  patientId,
  params,
  success_callback,
  error_callback
) => {
  var url = getApiUrl() + "/api/patient/medical_histories/" + patientId;

  if (params) {
    url = url + "?" + $.param(params);
  }

  getJson(url, success_callback, error_callback);
};

export const getMedicalFormPatientWithHistory_Ajax = (
  medicalFormPatientId,
  historyId,
  success_callback,
  error_callback
) => {
  var url =
    getApiUrl() +
    "/api/patient/medical_form_patient/" +
    medicalFormPatientId +
    "/history/" +
    historyId;

  getJson(url, success_callback, error_callback);
};

export const getAppSettingsData = (success_callback, error_callback) => {
  try {
    let unixTimestamp = new Date().getTime();

    fetch("./appSettings.json?unixtimestamp=" + unixTimestamp)
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then(success_callback)
      .catch(error_callback);
  } catch (ex) {}
};

export const postBookingProcedures_Ajax = (postBookingProceduresJsonData) => {
  var url = getApiUrl() + "/api/ondemandconsultation/post-booking-procedures";

  postJsonData(
    url,
    "PUT",
    postBookingProceduresJsonData,
    () => {},
    () => {}
  );
};

export const updateOnDemandConsultation_Ajax = (
  jsonData,
  success_callback,
  error_callback
) => {
  var url = getApiUrl() + "/api/ondemandconsultation/update";

  postJsonData(url, "PUT", jsonData, success_callback, error_callback);
};

export const savePatientMedicalForm_Ajax = (
  formId,
  jsonData,
  success_callback,
  error_callback
) => {
  var url = getApiUrl() + "/api/patient/medical_form_patient/" + formId;
  postJsonData(url, "PUT", jsonData, success_callback, error_callback);
};

export const searchPharmacies_Ajax = (
  params,
  success_callback,
  error_callback
) => {
  var url = getApiUrl() + "/api/admin/pharmacies";

  if (params) {
    url = url + "?" + $.param(params);
  }

  getJson(url, success_callback, error_callback);
};

export const getLedgers_Ajax = (
  patientId,
  datesForLedger,
  success_callback,
  error_callback
) => {
  var url =
    getApiUrl() +
    "/api/provider/ledger/" +
    patientId +
    "?dates=" +
    datesForLedger[0] +
    "&dates=" +
    datesForLedger[1];

  getJson(url, success_callback, error_callback);
};

export const getMedicalFormPatient_Ajax = (
  patientFormId,
  params,
  success_callback,
  error_callback
) => {
  var url = getApiUrl() + "/api/medical_form/" + patientFormId;

  if (params) {
    url = url + "?" + $.param(params);
  }

  getJson(url, success_callback, error_callback);
};

export const getPatientStatusSummary_Ajax = (
  patientId,
  success_callback,
  error_callback
) => {
  var url = getApiUrl() + "/api/patient/statussummary/" + patientId;
  getJson(url, success_callback, error_callback);
};

export const updateReferInfoSeenStatus_Ajax = (
  jsonData,
  success_callback,
  error_callback
) => {
  var url = getApiUrl() + "/api/patient/refer_info/update_seen_status/";
  postJsonData(url, "PUT", jsonData, success_callback, error_callback);
};

export const updatePrescriptionSeenStatus_Ajax = (
  unseenPrescriptionIds,
  params,
  success_callback,
  error_callback
) => {
  var url =
    getApiUrl() +
    "/api/provider/prescription/update_seen_status/" +
    unseenPrescriptionIds +
    "?" +
    $.param(params);
  postJsonData(url, "PUT", {}, success_callback, error_callback);
};

export const updatePatientNotesSeenStatus_Ajax = (
  params,
  success_callback,
  error_callback
) => {
  var url = getApiUrl() + "/api/patient/notes/update_seen_status/";
  postJsonData(url, "PUT", params, success_callback, error_callback);
};

export const updateMedicalRecordsSeenStatus_Ajax = (
  params,
  success_callback,
  error_callback
) => {
  var url = getApiUrl() + "/api/patient/medicalrecords/update_seen_status/";
  postJsonData(url, "PUT", params, success_callback, error_callback);
};

export const updateEducationsSeenStatus_Ajax = (
  params,
  success_callback,
  error_callback
) => {
  var url = getApiUrl() + "/api/patient/educations/update_seen_status/";
  postJsonData(url, "PUT", params, success_callback, error_callback);
};

export const updateSmartScanSeenStatus_ajax = (
  smartScanIds,
  status,
  success_callback,
  error_callback
) => {
  let url = getApiUrl() + "/api/smartscan/" + smartScanIds + "/seen/" + status;
  postJsonData(url, "PUT", {}, success_callback, error_callback);
};

export const getSecondOpinionForm_Ajax = (
  type,
  params,
  success_callback,
  error_callback
) => {
  var url = getApiUrl() + "/api/secondopinion/form/" + type;

  if (params) {
    url = url + "?" + $.param(params);
  }

  getJson(url, success_callback, error_callback);
};

export const addSecondOpinionRequest_Ajax = (
  formData,
  params,
  success_callback,
  error_callback
) => {
  var url = getApiUrl() + "/api/secondopinion";
  if (params) {
    url = url + "?" + $.param(params);
  }
  postFormData(url, "PUT", formData, success_callback, error_callback);
};

export const saveSecondOpinionRecord_Ajax = (
  formData,
  success_callback,
  error_callback
) => {
  var url = getApiUrl() + "/api/secondopinion/record";
  postFormData(url, "PUT", formData, success_callback, error_callback);
};

export const getSecondOpinionSetting_Ajax = (
  clientName,
  success_callback,
  error_callback
) => {
  var url = getApiUrl() + "/api/secondopinion/setting/" + clientName;
  getJson(url, success_callback, error_callback);
};

export const getSecondOpinion_Ajax = (
  secondOpinionId,
  params,
  success_callback,
  error_callback
) => {
  var url = getApiUrl() + "/api/secondopinion/" + secondOpinionId;

  if (params) {
    url = url + "?" + $.param(params);
  }

  getJson(url, success_callback, error_callback);
};

export const getSecondOpinions_Ajax = (
  params,
  success_callback,
  error_callback
) => {
  var url = getApiUrl() + "/api/secondopinions";

  if (params) {
    url = url + "?" + $.param(params);

    getJson(url, success_callback, error_callback);
  }
};

export const updateSecondOpinionRecordRequest_Ajax = (
  secondOpinionId,
  formData,
  success_callback,
  error_callback
) => {
  var url =
    getApiUrl() + "/api/secondopinion/recordrequest/update/" + secondOpinionId;
  postFormData(url, "PUT", formData, success_callback, error_callback);
};

export const sendRecordRequestNotification_Ajax = (
  secondOpinionId,
  success_callback,
  error_callback
) => {
  var url =
    getApiUrl() +
    "/api/secondopinion/recordrequest/notification/" +
    secondOpinionId;
  postJsonData(url, "PUT", {}, success_callback, error_callback);
};

export const getProviderSchedules_Ajax = (
  officeId,
  params,
  success_callback,
  error_callback
) => {
  var url =
    getApiUrl() + "/api/offices/" + officeId + "/providers?" + $.param(params);
  getJson(url, success_callback, error_callback);
};

export const secondOpinionConsultationRequest_Ajax = (
  campaignId,
  jsonData,
  success_callback,
  error_callback
) => {
  var url = getApiUrl() + "/api/secondopinion/consultation/" + campaignId;
  postJsonData(url, "PUT", jsonData, success_callback, error_callback);
};

export const getSecondOpinionCaseReport_Ajax = (
  secondOpinionId,
  success_callback,
  error_callback
) => {
  var url =
    getApiUrl() + "/api/secondopinion/" + secondOpinionId + "/casereport/";
  getJson(url, success_callback, error_callback);
};

export const getSecondOpinionData_Ajax = (
  secondOpinionId,
  success_callback,
  error_callback
) => {
  var url = getApiUrl() + "/api/secondopinion/" + secondOpinionId;
  getJson(url, success_callback, error_callback);
};

export const getCaseReportEducationDetails_Ajax = (
  educationid,
  success_callback,
  error_callback
) => {
  var url =
    getApiUrl() + "/api/secondopinion/casereport/edudcation/" + educationid;
  getJson(url, success_callback, error_callback);
};

export const getAnnotatedRecords_Ajax = (
  caseReportId,
  success_callback,
  error_callback
) => {
  var url =
    getApiUrl() +
    "/api/secondopinion/casereport/" +
    caseReportId +
    "/annotatedrecords/";
  getJson(url, success_callback, error_callback);
};

export const getCaseReportVideoEducationDetails_Ajax = (
  videoEducationId,
  success_callback,
  error_callback
) => {
  var url =
    getApiUrl() +
    "/api/video_educations/patient_video_education_details/" +
    videoEducationId;
  getJson(url, success_callback, error_callback);
};

export const getPromoCodeDiscountDetails_Ajax = (
  promoCode,
  totalAmount,
  params,
  success_callback,
  error_callback
) => {
  var url =
    getApiUrl() + "/api/promocode/" + promoCode + "/discount/" + totalAmount;

  if (params) {
    url = url + "?" + $.param(params);
  }

  getJson(url, success_callback, error_callback);
};

export const getAppointmentSlots_Ajax = (
  officeId,
  providerId,
  params,
  success_callback,
  error_callback
) => {
  var url =
    getApiUrl() +
    "/api/appointments/" +
    officeId +
    "/" +
    providerId +
    "?" +
    $.param(params);

  getJson(url, success_callback, error_callback);
};

export const saveAppointment_Ajax = (
  jsonData,
  success_callback,
  error_callback
) => {
  var url = getApiUrl() + "/api/appointments";

  postJsonData(url, "PUT", jsonData, success_callback, error_callback);
};

export const getChatParticipantInfo_Ajax = (
  chatParticipantId,
  success_callback,
  error_callback
) => {
  var url = getApiUrl() + "/api/chat/chatparticipant/" + chatParticipantId;

  getJson(url, success_callback, error_callback);
};

export const getPatientPreferredPharmacy_Ajax = (
  patientId,
  success_callback,
  error_callback
) => {
  var url = getApiUrl() + "/api/patient/preferredpharmacy/" + patientId;

  getJson(url, success_callback, error_callback);
};

export const resetPassword_Ajax = (
  jsonData,
  success_callback,
  error_callback
) => {
  var url = getApiUrl() + "/api/account/resetpassword";

  postJsonData(url, "PUT", jsonData, success_callback, error_callback);
};

export const validateToken_Ajax = (
  jsonData,
  success_callback,
  error_callback
) => {
  var url = getApiUrl() + "/api/account/validatetoken";

  postJsonData(url, "PUT", jsonData, success_callback, error_callback);
};

export const markAllNotificationsAsSeen_Ajax = (
  success_callback,
  error_callback
) => {
  var url = getApiUrl() + "/api/notification/seen_all";

  postJsonData(url, "PUT", {}, success_callback, error_callback);
};

export const checkIfUserExists_Ajax = (
  jsonData,
  success_callback,
  error_callback
) => {
  var url = getApiUrl() + "/api/account/checkifuserexists";

  postJsonData(url, "PUT", jsonData, success_callback, error_callback);
};

export const auditConference_Ajax = (
  jsonData,
  success_callback,
  error_callback
) => {
  let url = getApiUrl() + "/api/audit/conference";
  postJsonData(url, "PUT", jsonData, success_callback, error_callback);
};
export const getQRCode_ajax = (
  qrCodeData,
  success_callback,
  error_callback
) => {
  let url = getApiUrl() + "/api/qrcode/createqrcode/?qrCodeData=" + qrCodeData;
  getJson(url, success_callback, error_callback);
};

export const loginPatientWithToken_Ajax = (
  apiKey,
  success_callback,
  error_callback
) => {
  let url = getApiUrl() + "/api/patient/account/autologinWithToken/" + apiKey;
  postJsonData(url, "POST", {}, success_callback, error_callback);
};

export const updateUserSession_Ajax = (
  apiKey,
  success_callback,
  error_callback
) => {
  let url = getApiUrl() + "/api/update_logged_in_status";
  postJsonData(url, "PUT", {}, success_callback, error_callback);
};

export const getSmartScanSetting_Ajax = (
  clientId,
  success_callback,
  error_callback
) => {
  let url = getApiUrl() + "/api/smartscan/setting/" + clientId;
  getJson(url, success_callback, error_callback);
};

export const saveSmartScanPhotos_Ajax = (
  formData,
  success_callback,
  error_callback
) => {
  let url = getApiUrl() + "/api/smartscan";
  postFormData(url, "PUT", formData, success_callback, error_callback);
};

export const getSmartScans_Ajax = (
  params,
  success_callback,
  error_callback
) => {
  let url = getApiUrl() + "/api/smartscans?" + $.param(params);
  getJson(url, success_callback, error_callback);
};

export const getSmartScan_Ajax = (
  smartScanId,
  success_callback,
  error_callback
) => {
  let url = getApiUrl() + "/api/smartscan/" + smartScanId;
  getJson(url, success_callback, error_callback);
};

export const smartScanPostBookingProcedures_Ajax = (
  postBookingProceduresJsonData,
  success_callback,
  error_callback
) => {
  let url = getApiUrl() + "/api/smartscan/post-creation-procedures";
  postJsonData(
    url,
    "PUT",
    postBookingProceduresJsonData,
    success_callback,
    error_callback
  );
};

export const getSmartScanCaseReport_Ajax = (
  smartScanId,
  params,
  success_callback,
  error_callback
) => {
  let url =
    getApiUrl() +
    "/api/smartscan/" +
    smartScanId +
    "/casereport?" +
    $.param(params);
  getJson(url, success_callback, error_callback);
};

export const getWhitenessScoreReport_Ajax = (
  smartScanId,
  params,
  success_callback,
  error_callback
) => {
  let url =
    getApiUrl() +
    "/api/smartscan/" +
    smartScanId +
    "/whitenessreport?" +
    $.param(params);
  getJson(url, success_callback, error_callback);
};

export const getSmartScanWellnessScores_Ajax = (
  patientId,
  params,
  success_callback,
  error_callback
) => {
  let url =
    getApiUrl() +
    "/api/smartscan/smartscanscores/" +
    patientId +
    "?" +
    $.param(params);
  getJson(url, success_callback, error_callback);
};

export const getSmartScanWhitenessScores_Ajax = (
  patientId,
  params,
  success_callback,
  error_callback
) => {
  let url =
    getApiUrl() +
    "/api/smartscan/whitenessscores/" +
    patientId +
    "?" +
    $.param(params);
  getJson(url, success_callback, error_callback);
};

export const getSmartScanWellnessScoreSummery_Ajax = (
  patientId,
  params,
  success_callback,
  error_callback
) => {
  let url =
    getApiUrl() +
    "/api/smartscan/wellness-score/" +
    patientId +
    "?" +
    $.param(params);
  getJson(url, success_callback, error_callback);
};

export const getSmartScanReportForExternalClient_Ajax = (
  params,
  success_callback,
  error_callback
) => {
  let url =
    getApiUrl() + "/api/smartscans/smartscanreport/" + "?" + $.param(params);
  getJson(url, success_callback, error_callback, false);
};

export const getOnsitePatientDetails_Ajax = (
  denticonPatId,
  employerId,
  success_callback,
  error_callback
) => {
  let url =
    getApiUrl() +
    "/api/denticon/patient/" +
    denticonPatId +
    "?employerId=" +
    employerId +
    "&tdxPgId=" +
    getPgId();
  getJson(url, success_callback, error_callback);
};

export const getExternalPatientDetailsFromEncryptedData_Ajax = (
  version,
  clientId,
  params,
  success_callback,
  error_callback
) => {
  let url =
    getApiUrl() +
    "/api/" +
    version +
    "/denticon/patient/" +
    clientId +
    "?" +
    $.param(params);
  getJson(url, success_callback, error_callback);
};

export const registerOnsitePatient_Ajax = (
  denticonPatId,
  jsonData,
  success_callback,
  error_callback
) => {
  let url = getApiUrl() + "/api/denticon/patient/" + denticonPatId;
  postJsonData(url, "POST", jsonData, success_callback, error_callback);
};

export const getInsuranceCarriers_Ajax = (
  params,
  success_callback,
  error_callback
) => {
  let url =
    getApiUrl() +
    "/api/patient/public/carriers/" +
    getPgId() +
    "?" +
    $.param(params);
  getJson(url, success_callback, error_callback);
};

export const getInsuranceRelations_Ajax = (
  success_callback,
  error_callback
) => {
  let url = getApiUrl() + "/api/insured_patient_relations";
  getJson(url, success_callback, error_callback);
};

export const saveInsurance_Ajax = (
  patientId,
  jsonData,
  success_callback,
  error_callback
) => {
  let url = getApiUrl() + "/api/patient/insurance_info/" + patientId;
  postJsonData(url, "PUT", jsonData, success_callback, error_callback);
};

export const getInsuranceProductTypes_Ajax = (
  pgId,
  params,
  success_callback,
  error_callback
) => {
  let url = getApiUrl() + "/api/producttypes/" + pgId + "?" + $.param(params);
  getJson(url, success_callback, error_callback);
};

export const getPatientRegistrationSettings_Ajax = (
  officeId,
  success_callback,
  error_callback
) => {
  let url = getApiUrl() + "/api/patientregistrationsetting/" + officeId;
  getJson(url, success_callback, error_callback);
};

export const getAutoLoginData_Ajax = (
  version,
  clientId,
  params,
  success_callback,
  error_callback
) => {
  let url =
    getApiUrl() +
    "/api/" +
    version +
    "/account/autoLogin/" +
    clientId +
    "?" +
    $.param(params);
  getJson(url, success_callback, error_callback);
};

export const validateEmail_Ajax = (
  jsonData,
  success_callback,
  error_callback
) => {
  let url = getApiUrl() + "/api/account/validateemail";
  postJsonData(url, "PUT", jsonData, success_callback, error_callback);
};

export const shareWellnessScoreReort_Ajax = (
  jsonData,
  success_callback,
  error_callback
) => {
  let url = getApiUrl() + "/api/smartscan/share-case-report";
  postJsonData(url, "PUT", jsonData, success_callback, error_callback);
};

export const getVisitSummary_Ajax = (
  patientId,
  consultationId,
  success_callback,
  error_callback
) => {
  let url =
    getApiUrl() +
    "/api/patient/" +
    patientId +
    "/visitsummary/" +
    consultationId;

  getJson(url, success_callback, error_callback);
};

export const getEligibility_ajax = (
  patientId,
  success_callback,
  error_callback
) => {
  let url = getApiUrl() + "/api/dentalClaims/eligibility?patId=" + patientId;
  getJson(url, success_callback, error_callback);
};

export const getAIChatRoom_Ajax = (
  userId,
  success_callback,
  error_callback
) => {
  let url = getApiUrl() + `/api/aichat/chatroom/${userId}`;
  postJsonData(url, "PUT", {}, success_callback, error_callback);
};

export const getAIChatMessages_Ajax = (
  aiChatRoomId,
  params,
  success_callback,
  error_callback
) => {
  let url =
    getApiUrl() +
    `/api/aichat/chatroom/${aiChatRoomId}/messages?` +
    $.param(params);
  getJson(url, success_callback, error_callback);
};

export const saveAIChatMessages_Ajax = (
  chatRoomId,
  jsonData,
  success_callback,
  error_callback
) => {
  let url = getApiUrl() + `/api/aichat/messages/${chatRoomId}`;
  postJsonData(url, "PUT", jsonData, success_callback, error_callback);
};

export const getSecondOpinionAdditionalInfo_Ajax = (
  secondOpinionId,
  success_callback,
  error_callback
) => {
  let url =
    getApiUrl() +
    "/api/secondopinion/requested-additional-info/" +
    secondOpinionId;

  getJson(url, success_callback, error_callback);
};

export const getCreatorInfo_Ajax = (
  creatorUserId,
  success_callback,
  error_callback
) => {
  let url = getApiUrl() + "/api/patient/createdUserInfo/" + creatorUserId;

  getJson(url, success_callback, error_callback);
};

export const getImageBasedOnShade_Ajax = (
  smartScanId,
  shade,
  success_callback,
  error_callback
) => {
  let url =
    getApiUrl() + "/api/smartscan/" + smartScanId + "/toothimage/" + shade;

  getBlobJson(url, success_callback, error_callback);
};

export const getActiveVoipCalls_Ajax = (
  params,
  success_callback,
  error_callback
) => {
  let url = getApiUrl() + "/api/voipcalls?" + $.param(params);
  getJson(url, success_callback, error_callback);
};

export const cancelVoipCall_Ajax = (
  voipCallId,
  success_callback,
  error_callback
) => {
  let url = getApiUrl() + `/api/voipcalls/cancel/${voipCallId}`;
  postJsonData(url, "PUT", {}, success_callback, error_callback);
};

export const getEmployerName_Ajax = (
  clientid,
  success_callback,
  error_callback
) => {
  let url = getApiUrl() + "/api/employername/" + clientid;
  getJson(url, success_callback, error_callback);
};

export const checkIfPhoneNumberAlreadyExists_Ajax = (
  jsonData,
  success_callback,
  error_callback
) => {
  let url = getApiUrl() + "/api/account/check-if-phone-number-exists";

  postJsonData(url, "PUT", jsonData, success_callback, error_callback);
};

export const sendVerificationCode_Ajax = (
  formData,
  success_callback,
  error_callback
) => {
  let url = getApiUrl() + "/api/verificationcode";
  postFormData(url, "POST", formData, success_callback, error_callback);
};

export const submitVerificationCode_Ajax = (
  formData,
  success_callback,
  error_callback
) => {
  let url = getApiUrl() + "/api/verificationcode/submit";
  postFormData(url, "POST", formData, success_callback, error_callback);
};

export const getPatientsDirectDentistReferral_Ajax = (
  patId,
  success_callback,
  error_callback
) => {
  var url = getApiUrl() + "/api/dentistdirectreferrals/provider_info/" + patId;
  getJson(url, success_callback, error_callback);
};
