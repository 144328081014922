import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import DeleteModal from "./DeleteModal";
import {
  convertKBFileSizeToMB,
  isEmptyArray,
  isImageFile,
  isPDFfile,
  isValidDocFileForPatientUpload,
  showAlertDialouge,
  showConfirmDialouge,
  validateFileMaxSize,
  queryStringToJSON,
  onImageError,
  supportedImageExtentions,
  supportedDocExtensions
} from "../../helpers/utils";
import { MaxAllowdFileSizes } from "../../constants";
import { saveSecondOpinionRecordRequestProgress } from "../../indexedDbHelper/secondOpinionRecordRequestProgressIndexedDbHelper";

const UploadTypes = {
  Image: 1,
  Note: 2,
  Other: 3
};

const QuestionnaireUploadRecords = (props) => {
  const {
    closeUploadRecordHandler,
    isProviderUploadRecord,
    patientId,
    pageStates,
    uploadFiles
  } = props;

  const navigate = useNavigate();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [includeZState, setIncludeZState] = useState(false);

  useEffect(() => {
    let params = queryStringToJSON();
    let includeZState = false;
    if (params.includezstate) {
      if (params.includezstate == "true") {
        includeZState = true;
      }
    }
    setIncludeZState(includeZState);
  }, []);

  useEffect(() => {
    if (uploadFiles) {
      setUploadedFiles(uploadFiles);
    }
  }, [uploadFiles]);

  function updateSelectedPhoto(event, uploadType) {
    if (event && event.target && event.target.files) {
      let arr = [];
      let imageMaxSizeInBytes = MaxAllowdFileSizes.IMAGE_MAX_SIZE_IN_MB_5;
      let FileExceededAllowedSize = [];

      if (
        uploadedFiles &&
        Array.isArray(uploadedFiles) &&
        uploadedFiles.length > 0
      ) {
        arr = [...uploadedFiles];
      }

      for (let i = 0; i < event.target.files.length; i++) {
        let file = event.target.files[i];
        let fileName = file.name.toLowerCase();

        if (isImageFile(fileName) || isValidDocFileForPatientUpload(fileName)) {
          let fileSizeErrMsg;

          if (isImageFile(fileName)) {
            fileSizeErrMsg = validateFileMaxSize([file], imageMaxSizeInBytes);
          } else if (isValidDocFileForPatientUpload(fileName)) {
            fileSizeErrMsg = validateFileMaxSize([file]);
          }

          if (!fileSizeErrMsg) {
            var tempPath = URL.createObjectURL(file);
            arr.push({
              url: tempPath,
              type: fileName.split(".").pop(),
              file: file,
              uploadType: uploadType
            });
          } else {
            FileExceededAllowedSize.push(fileName);
          }
        } else {
          showAlertDialouge("Error", "File type not supported");
        }
      }

      if (arr && arr.length > 0) {
        setUploadedFiles(arr);
      }

      if (FileExceededAllowedSize && FileExceededAllowedSize.length > 0) {
        showAlertDialouge(
          "Error",
          "The following file(s) have exceeded maximum allowed size: " +
            FileExceededAllowedSize.join(", ") +
            ". Please upload images less than " +
            MaxAllowdFileSizes.IMAGE_MAX_SIZE_IN_MB_5 +
            " MB and documents less than " +
            MaxAllowdFileSizes.FILE_MAX_SIZE_IN_MB +
            " MB"
        );
      }
    }
  }

  function deleteUploadedBtnClicked(fileIndex) {
    showConfirmDialouge(
      "Are you sure?",
      "Are you sure you want to Delete this uploaded record?",
      function () {
        deleteFileWithIndex(fileIndex);
      }
    );
  }

  function deleteFileWithIndex(fileIndex) {
    if (fileIndex < 0) return;

    let arr = [];

    if (
      uploadedFiles &&
      Array.isArray(uploadedFiles) &&
      uploadedFiles.length > 0
    ) {
      arr = uploadedFiles.filter((file, index) => {
        return index != fileIndex;
      });
    }

    if (arr) {
      setUploadedFiles(arr);
    }
  }

  function isRequiredFilesUploaded() {
    if (uploadedFiles && !isEmptyArray(uploadedFiles)) {
      let filterdListByImageType = uploadedFiles.filter((upload) => {
        return upload && upload.uploadType == UploadTypes.Image;
      });

      let filterdListByNoteType = uploadedFiles.filter((upload) => {
        return upload && upload.uploadType == UploadTypes.Note;
      });

      return !(
        isEmptyArray(filterdListByNoteType) ||
        isEmptyArray(filterdListByImageType)
      );
    }
    return false;
  }

  function nextBtnClicked() {
    if (isRequiredFilesUploaded()) {
      saveSecondOpinionRecordRequestProgress(
        {
          ...pageStates,
          uploadedFiles,
          patientId: patientId
        },
        patientId
      );

      navigate("/questionnaire-review-submit", {
        state: {
          uploadedFiles: uploadedFiles,
          patientId: patientId
        }
      });
    }
  }

  function submitBtnClicked() {
    if (isRequiredFilesUploaded()) {
      props.onSubmit(uploadedFiles);
    }
  }

  function renderUploadedFiles(uploadType) {
    if (uploadedFiles && !isEmptyArray(uploadedFiles)) {
      let filterdListByType = uploadedFiles.filter((upload) => {
        return upload && upload.uploadType == uploadType;
      });

      if (!isEmptyArray(filterdListByType)) {
        return (
          <div className="form-group mt-4 mb-3 col-md-12 d-none111">
            <ul className="uploaded-file-wrapper p-0 m-0">
              {uploadedFiles.map((upload, index) => {
                if (upload.uploadType == uploadType) {
                  return (
                    <li className="d-flex flex-wrap mb-3">
                      <div className="left-wrapper">
                        {isImageFile(upload?.file?.name) ? (
                          <img
                            src={upload?.url}
                            onError={onImageError}
                            alt="second opinion upload"
                          />
                        ) : isValidDocFileForPatientUpload(
                            upload?.file?.name
                          ) ? (
                          isPDFfile(upload?.file?.name) ? (
                            <em className="bi bi-file-earmark-pdf-fill fs-1"></em>
                          ) : (
                            <em className="bi bi-file-earmark-text-fill fs-1"></em>
                          )
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="right-wrapper right-wrapper position-relative">
                        <div className="form-group-title fs-4 pe-4 pe-md-5 mb-1 lh-1 text-break">
                          {upload?.file?.name}
                        </div>
                        <span className="file-size fs-5 text-muted">
                          {Number(
                            convertKBFileSizeToMB(upload?.file?.size)
                          ).toFixed(2) + " MB"}
                        </span>
                        <button
                          type="button"
                          tabIndex={0}
                          aria-label="Close"
                          onClick={() => deleteUploadedBtnClicked(index)}
                          className="bg-transparent border-0 close text-danger px-1 position-absolute top-0 end-0"
                        >
                          <em className="bi bi-x-lg"></em>
                        </button>
                      </div>
                    </li>
                  );
                } else {
                  return <></>;
                }
              })}
            </ul>
          </div>
        );
      } else {
        return <></>;
      }
    } else {
      return <></>;
    }
  }

  function getAllowedFileTypes() {
    let allTypes = [...supportedImageExtentions, ...supportedDocExtensions];
    return allTypes.map((type) => type.toUpperCase()).join(", ");
  }

  return (
    <>
      <fieldset id="upload-records">
        <form className="min-vh">
          {!isProviderUploadRecord ? (
            <div className="fs-3">
              <p>
                To receive a second opinion, please upload any supporting
                documents and images that were gathered during your initial
                treatment recommendation.
              </p>
            </div>
          ) : (
            <></>
          )}
          <br />
          <br />
          <div className="form-group mb-4 pt-2 col-md-12">
            <div className="form-group-title fs-3 mb-2 mb-md-3 lh-1">
              1. Upload Proposed Treatment <i>(required)</i>
              <i
                className="bi bi-info-circle-fill fs-3 text-primary ms-2"
                data-place="top"
                data-type="light"
                data-for="UploadProposedTreatmentTooltip"
                data-tip={
                  "Your dental treatment plan is the most important document we need to provide you with a Second Opinion. Treatment Plans are typically printed out or emailed to you and contain exactly what dental services your dentist recommends. If you do not have your treatment plan, go back to the previous screen and we can request your records for you (select 'Request your records'). <br/>Allowed file extensions: " +
                  getAllowedFileTypes()
                }
              ></i>
              <ReactTooltip
                id="UploadProposedTreatmentTooltip"
                className="tooltip"
                html={true}
              />
            </div>
            <div className="form-group mb-3 col-md-12">
              <div className="upload-file-wrapper">
                <input
                  type="file"
                  className="gth-upload"
                  id="validate-Img"
                  multiple
                  name=""
                  onChange={(event) =>
                    updateSelectedPhoto(event, UploadTypes.Note)
                  }
                />
                <div className="second-opinion-upload btn-rounded align-items-center justify-content-center">
                  <span className="text-center p-3">
                    Drop proposed treatment files here or{" "}
                    <a className="text-primary">browse</a>
                  </span>
                </div>
              </div>
            </div>
          </div>
          {renderUploadedFiles(UploadTypes.Note)}
          <div className="form-group mb-4 pt-2 col-md-12">
            <div className="form-group-title fs-3 mb-2 mb-md-3 lh-1">
              2. Upload X-Rays and Images <em>(required)</em>
              <em
                className="bi bi-info-circle-fill fs-3 text-primary ms-2"
                data-place="top"
                data-for="xrayToolTip"
                data-type="light"
                data-tip={
                  "X-rays gathered by your dental providers are important for your Second Opinion. If your treatment plan already included xrays, re upload it here. If you do not have your x-rays, go back to the previous screen and we can request your records for you (select 'Request your records'). <br/>Allowed file extensions: " +
                  getAllowedFileTypes()
                }
              ></em>
              <ReactTooltip id="xrayToolTip" className="tooltip" html={true} />
            </div>
            <div className="form-group mb-3 col-md-12">
              <div className="upload-file-wrapper">
                <input
                  type="file"
                  className="gth-upload"
                  id="validate-Img-2"
                  multiple
                  name=""
                  onChange={(event) =>
                    updateSelectedPhoto(event, UploadTypes.Image)
                  }
                />
                <div className="second-opinion-upload gth-browose-file btn-rounded d-flex align-items-center justify-content-center">
                  <span className="text-center p-3">
                    Drop x-rays and images files here or{" "}
                    <a className="text-primary">browse</a>
                  </span>
                </div>
              </div>
            </div>
          </div>
          {renderUploadedFiles(UploadTypes.Image)}
          <div className="form-group mb-4 pt-2 col-md-12">
            <div className="form-group-title fs-3 mb-2 mb-md-3 lh-1">
              3. Upload Additional Files <em>(optional)</em>
              <em
                className="bi bi-info-circle-fill fs-3 text-primary ms-2"
                data-place="top"
                data-for="additionalToolTip"
                data-type="light"
                data-tip={
                  "If you have other images, photos, or documents aside from your treatment plan and x-rays, please upload them here. <br/>Allowed file extensions: " +
                  getAllowedFileTypes()
                }
              ></em>
              <ReactTooltip
                id="additionalToolTip"
                className="tooltip"
                html={true}
              />
            </div>
            <div className="form-group mb-3 col-md-12">
              <div className="upload-file-wrapper">
                <input
                  type="file"
                  className="gth-upload"
                  multiple
                  name=""
                  onChange={(event) =>
                    updateSelectedPhoto(event, UploadTypes.Other)
                  }
                />
                <div className="second-opinion-upload gth-browose-file btn-rounded d-flex align-items-center justify-content-center">
                  <span className="text-center p-3">
                    Drop additional files here or{" "}
                    <a className="text-primary">browse</a>
                  </span>
                </div>
              </div>
            </div>
          </div>
          {renderUploadedFiles(UploadTypes.Other)}
          <br />
          <br />
          <div className="form-group text-center mt-3 mt-md-5 col-sm-12 button-wrapper">
            {isProviderUploadRecord ? (
              <>
                <button
                  type="button"
                  className="tab-focusable-background btn btn-secondary btn-rounded btn-lg px-3 px-md-5 mt-3 mx-md-2 w-100 w-md-auto"
                  disabled={!isRequiredFilesUploaded()}
                  style={{ minWidth: "220px" }}
                  onClick={submitBtnClicked}
                >
                  Submit
                </button>
              </>
            ) : (
              <div className="tab-focusable-background d-flex flex-column flex-md-row justify-content-center align-items-center">
                <button
                  type="button"
                  className="btn btn-outline btn-rounded btn-lg px-3 px-md-5 mt-3 mx-2 w-100 w-md-auto"
                  onClick={() => {
                    debugger;
                    closeUploadRecordHandler(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  onClick={nextBtnClicked}
                  disabled={!isRequiredFilesUploaded()}
                  className="btn btn-secondary btn-rounded btn-lg px-3 px-md-5 mt-3 mx-md-2 w-100 w-md-auto"
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </form>
      </fieldset>
      <DeleteModal />
    </>
  );
};

export default QuestionnaireUploadRecords;
