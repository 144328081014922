import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useGetSecondOpinionRecordRequestProgress from "../CustomHooks/useGetSecondOpinionRecordRequestProgress";

const QuestionnaireTabs = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const secondOpinionState = useGetSecondOpinionRecordRequestProgress();
  const secondOpinionSteps = {
    step1: 1,
    step2: 2,
    step3: 3,
    step4: 4,
    step5: 5
  };

  const secondOpinionCharge = useSelector((state) => {
    if (state?.userData?.secondOpinionCharge) {
      return state?.userData?.secondOpinionCharge;
    } else if (secondOpinionState?.secondOpinionCharge) {
      return secondOpinionState?.secondOpinionCharge;
    }
  });

  const shouldCaptureInsurance = useSelector(
    (state) =>
      state?.appSettings?.settingsData
        ?.shouldCaptureInsuranceInSecondOpinionFlow
  );

  function requestRecordBubbleClicked() {
    let currentPath = location.pathname;
    if (currentPath == "/questionnaire-review-submit") {
      navigate("/record-request", {
        state: {
          patientId: location?.state?.patientId
        }
      });
    }
  }

  function intakeFormBubbleClicked() {
    let currentPath = location.pathname;
    if (
      currentPath == "/questionnaire-review-submit" ||
      currentPath == "/questionnaire-insurance-info" ||
      currentPath == "/record-request"
    ) {
      navigate("/questionnaire-intake-form", {
        state: {
          patientId: location?.state?.patientId
        }
      });
    }
  }

  function insuranceInfoBubbleClicked() {
    let currentPath = location.pathname;
    if (
      currentPath == "/questionnaire-review-submit" ||
      currentPath == "/record-request"
    ) {
      navigate("/questionnaire-insurance-info", {
        state: {
          patientId: location?.state?.patientId
        }
      });
    }
  }

  function shouldShowBillingInformationTab() {
    return secondOpinionCharge > 0;
  }

  return (
    <>
      <ul
        className="nav d-flex flex-wrap justify-content-between mb-5 position-relative"
        id="step-tab"
      >
        <li className="nav-item">
          <a
            onClick={intakeFormBubbleClicked}
            className={
              "fs-3 p-0 d-flex flex-wrap justify-content-center align-items-center align-content-center position-relative rounded-pill complete active"
            }
          >
            <span className="num lh-1">{secondOpinionSteps.step1}</span>
            <span className="value fs-6 text-muted p-0 mt-2 position-absolute top-100 start-50 translate-middle-x">
              Visit <br />
              Details
            </span>
          </a>
        </li>
        {shouldCaptureInsurance ? (
          <li className="nav-item">
            <a
              onClick={insuranceInfoBubbleClicked}
              className={
                "fs-3 p-0 d-flex flex-wrap justify-content-center align-items-center align-content-center position-relative rounded-pill " +
                (location.pathname === "/questionnaire-insurance-info" ||
                location.pathname === "/record-request" ||
                location.pathname === "/questionnaire-review-submit"
                  ? "complete    active"
                  : " ")
              }
            >
              <span className="num lh-1">{secondOpinionSteps.step2}</span>
              <span className="value fs-6 text-muted p-0 mt-2 position-absolute top-100 start-50 translate-middle-x">
                Insurance <br />
                Info
              </span>
            </a>
          </li>
        ) : (
          <></>
        )}

        <li className="nav-item">
          <a
            onClick={requestRecordBubbleClicked}
            className={
              "fs-3 p-0 d-flex flex-wrap justify-content-center align-items-center align-content-center position-relative rounded-pill " +
              (location.pathname === "/record-request" ||
              location.pathname === "/questionnaire-review-submit"
                ? "complete    active"
                : " ")
            }
          >
            <span className="num lh-1">
              {shouldCaptureInsurance
                ? secondOpinionSteps.step3
                : secondOpinionSteps.step2}
            </span>
            <span className="value fs-6 text-muted p-0 mt-2 position-absolute top-100 start-50 translate-middle-x">
              Record <br />
              Request
            </span>
          </a>
        </li>
        <li className="nav-item">
          <a
            className={
              "complete111 fs-3 p-0 d-flex flex-wrap justify-content-center align-items-center align-content-center position-relative rounded-pill " +
              (location.pathname === "/questionnaire-review-submit" ||
              location.pathname === "/questionnaire-billing-info"
                ? "complete    active"
                : " ")
            }
          >
            <span className="num lh-1">
              {shouldCaptureInsurance
                ? secondOpinionSteps.step4
                : secondOpinionSteps.step3}
            </span>
            <span className="value fs-6 text-muted p-0 mt-2 position-absolute top-100 start-50 translate-middle-x">
              Review and <br />
              Submit
            </span>
          </a>
        </li>
        {shouldShowBillingInformationTab() ? (
          <li className="nav-item">
            <a
              className={
                "complete111 fs-3 p-0 d-flex flex-wrap justify-content-center align-items-center align-content-center position-relative rounded-pill " +
                (location.pathname === "/questionnaire-billing-info"
                  ? "complete    active"
                  : " ")
              }
            >
              <span className="num lh-1">
                {shouldCaptureInsurance
                  ? secondOpinionSteps.step5
                  : secondOpinionSteps.step4}
              </span>
              <span className="value fs-6 text-muted p-0 mt-2 position-absolute top-100 start-50 translate-middle-x">
                Billing <br />
                Information
              </span>
            </a>
          </li>
        ) : (
          <></>
        )}
      </ul>
    </>
  );
};

export default QuestionnaireTabs;
