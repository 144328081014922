import React from "react";
import WellnessReportForExternalClient from "../../components/WellnessReportForExternalClient/WellnessReportForExternalClient.js";
import { useParams } from "react-router-dom";

const SmartscanReport = () => {
  const { scrambledData } = useParams();
  return (
    <>
      <WellnessReportForExternalClient scrambledData={scrambledData} />
    </>
  );
};

export default SmartscanReport;
