import React, { useEffect, useState, useRef } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import { useAlert } from "react-alert";
import moment from "moment";

import iconSeeDentist from "./../../assets/images/icon-see-dentist.svg";
import iconAppointment from "./../../assets/images/icon-appointment.svg";
import upcomingIcon from "./../../assets/images/icon-upcoming.svg";
import profilePic from "./../../assets/images/img-profile-pic.png";
import calendarIcon from "./../../assets/images/icon-calendar.svg";
import outlookIcon from "./../../assets/images/icon-outlook.svg";
import calendar2Icon from "./../../assets/images/icon-calendar2.svg";
import SecondOpinionIcon from "./../../assets/images/icon-second-opinion.svg";
import IconSmartScan from "./../../assets/images/icon-smart-scan.png";

import {
  getOnDemandConsultations_Ajax,
  updateOnDemandConsultation_Ajax,
  getBasicCampaignsInfo_Ajax,
  getSecondOpinionSetting_Ajax,
  getSecondOpinions_Ajax,
  getSmartScanSetting_Ajax,
  getSmartScans_Ajax,
  updateSmartScanSeenStatus_ajax
} from "../../helpers/requests";
import {
  handleApiErrors,
  formatDateTime,
  openUrlInNewTab,
  createVideoCallUrl,
  queryStringToJSON,
  getTimezoneAbbreviation,
  formatDateTimeWithZone,
  showAlertDialouge,
  isEmptyArray,
  checkBrowserVideoCallEligibility,
  formatCurrency,
  checkForPaymentCompletionStatus
} from "../../helpers/utils";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import {
  sethasOnDemandPromoCode,
  sethasSchedulerPromoCode,
  sethasSecondOpinionPromoCode,
  setOnDemandCampaignCharge,
  setSchedulerCampaignCharge,
  setSecondOpinionCharge,
  setSmartScanCharge
} from "../../redux/actions/userData";

import Pagination from "../../redux/customHooks/pagination";
import {
  ConsultationStatus,
  Pagination as PaginationConstants,
  SortOrders,
  PATIENT_PORTAL_AUTO_LOGIN_API_KEY,
  IntervalTimes,
  DateTimeFormats,
  SecondOpinionStatus,
  CONSULTATION_PROVIDER_PENDING,
  SmartScanGeneralStatus,
  SmartScanGraphDataPropertyIndices,
  IS_SEEN
} from "../../constants";
import {
  getAutoLoginUrl,
  getSelectedPatientId,
  getTokenData
} from "../../helpers/authManager";
import SmartScanGraph from "../SmartScanGraph/SmartScanGraph";
import { excludeSmartScanStatuses } from "../../helpers/smartScanUtils.js";
import UnsupportedBrowserModal from "../UnsupportedBrowserModal/UnsupportedBrowserModal.js";
import ChatCard from "../Chat/ChatCard.js";
import { getBaseUrl } from "../../ApplicationSettings.js";
import RequestPhoneCall from "./RequestPhoneCall.js";

const DashboardCards = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useAlert();
  const appointmentsRef = useRef([]);

  const [searchParams, setSearchParams] = useSearchParams();
  const [browserEligibility, setBrowserEligibility] = useState(null);
  const [autoLoginUrl, setAutoLoginUrl] = useState("");

  const currentPatient = useSelector(
    (state) => state?.userData?.currentPatient
  );
  const onDemandCampaignId = useSelector(
    (state) => state?.appSettings?.settingsData?.onDemandCampaignId
  );
  const schedulerCampaignId = useSelector(
    (state) => state?.appSettings?.settingsData?.schedulerCampaignId
  );
  const clientName = useSelector(
    (state) => state?.appSettings?.settingsData?.clientName
  );
  const patientOrigin = useSelector(
    (state) => state?.appSettings?.settingsData?.patientOrigin
  );
  const pgId = useSelector((state) => state?.appSettings?.settingsData?.pgId);
  const onDemandCampaignCharge = useSelector(
    (state) => state?.userData.onDemandCampaignCharge
  );
  const schedulerCampaignCharge = useSelector(
    (state) => state?.userData.schedulerCampaignCharge
  );
  const secondOpinionCharge = useSelector(
    (state) => state?.userData.secondOpinionCharge
  );

  const [upcomingAppointments, setUpcomingAppointments] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const [secondOpinionCurrentPage, setSecondOpinionCurrentPage] = useState(0);
  const [secondOpinionTotalCount, setSecondOpinionTotalCount] = useState(0);
  const [secondOpinions, setSecondOpinions] = useState([]);
  const secondOpinionButtonEnabled = useSelector(
    (state) =>
      state?.appSettings?.settingsData?.secondOpinionRequestButtonEnabled
  );
  const isSmartScanFeatureAvailable = useSelector(
    (state) => state?.appSettings?.settingsData?.isSmartScanFeatureAvailable
  );
  const isOnDemandCallFeatureEnabled = useSelector(
    (state) => state?.appSettings?.settingsData?.isOnDemandCallFeatureEnabled
  );

  const isScheduledOnDemandCallFeatureEnabled = useSelector(
    (state) =>
      state?.appSettings?.settingsData?.isScheduledOnDemandCallFeatureEnabled
  );

  const shouldDisplayCampaingChargeInfo = useSelector(
    (state) => state?.appSettings?.settingsData?.displayCampaignCharge
  );

  const shouldDisplaySecondOpinionChargeInfo = useSelector(
    (state) => state?.appSettings?.settingsData?.displaySecondOpinionCharge
  );

  const shouldDisplaySmartScanChargeInfo = useSelector(
    (state) => state?.appSettings?.settingsData?.displaySmartScanCharge
  );

  const isCICIChatEnabled = useSelector(
    (state) => state?.appSettings?.settingsData?.isCICIChatEnabled
  );

  const [smartScansCurrentPage, setSmartScanCurrentPage] = useState(0);
  const [smartScanTotalCount, setSmartScanTotalCount] = useState(0);
  const [smartScans, setSmartScans] = useState([]);
  const userData = useSelector((state) => state.userData);
  const [userHasSmartScan, setUserHasSmartScan] = useState(false);

  const [smartScanFilterOptions, setSmartScanFilterOptions] = useState({
    currentPatient: currentPatient,
    smartScansCurrentPage: smartScansCurrentPage,
    pgId: pgId,
    excludeSmartScanStatuses: excludeSmartScanStatuses
  });
  const shouldFetchSmartScans = useRef(false);

  const [secondOpinionFilterOptions, setSecondOpinionFilterOptions] = useState({
    secondOpinionCurrentPage: secondOpinionCurrentPage,
    currentPatient: currentPatient,
    pgId: pgId
  });
  const shouldFetchSecondOpinions = useRef(false);

  const [
    upcomingAppointmentsFilterOptions,
    setUpcomingAppointmentsFilterOptions
  ] = useState({
    currentPage: currentPage,
    currentPatient: currentPatient,
    pgId: pgId
  });
  const shouldFetchUpcomingAppointments = useRef(false);

  const previousURL = () => {
    navigate(1);
  };

  useEffect(() => {
    checkForAppointmentBookingStatus();
    checkForCallbackRequestStatus();
    displayAlertBasedOnQueryParamOfUrl();
    removeApptQueryParams();
    checkForPaymentQueryParams();
    removeQueryParamsFromUrl();
    checkIfJoinedButtonEnabled();
    setBrowserEligibility(checkBrowserVideoCallEligibility());
  }, []);

  useEffect(
    function () {
      if (onDemandCampaignId || schedulerCampaignId) {
        getCampaignsCharges();
      }
    },
    [onDemandCampaignId, schedulerCampaignId]
  );

  useEffect(() => {
    if (currentPatient) {
      initSmartScansFetchingFilterOptions();
      initSecondOpinionsFetchingFilterOptions();
      initUpcomingAppointmentsFetchingFilterOptions();
    }
  }, [currentPatient]);

  useEffect(() => {
    if (shouldFetchSmartScans.current) {
      if (smartScansCurrentPage > 0) {
        let newOptions = {
          ...smartScanFilterOptions,
          smartScansCurrentPage: smartScansCurrentPage
        };
        setSmartScanFilterOptions(newOptions);
      }
    } else {
      shouldFetchSmartScans.current = true;
    }
  }, [smartScansCurrentPage]);

  useEffect(() => {
    if (shouldFetchSecondOpinions.current) {
      if (secondOpinionCurrentPage > 0) {
        let newOptions = {
          ...secondOpinionFilterOptions,
          secondOpinionCurrentPage: secondOpinionCurrentPage
        };
        setSecondOpinionFilterOptions(newOptions);
      }
    } else {
      shouldFetchSecondOpinions.current = true;
    }
  }, [secondOpinionCurrentPage]);

  useEffect(() => {
    if (shouldFetchUpcomingAppointments.current) {
      if (currentPage > 0) {
        let newOptions = {
          ...upcomingAppointmentsFilterOptions,
          currentPage: currentPage
        };
        setUpcomingAppointmentsFilterOptions(newOptions);
      }
    } else {
      shouldFetchUpcomingAppointments.current = true;
    }
  }, [currentPage]);

  useEffect(() => {
    getSecondOpinionCharge();
  }, [clientName]);

  useEffect(() => {
    if (
      secondOpinionFilterOptions.secondOpinionCurrentPage &&
      secondOpinionFilterOptions.pgId &&
      secondOpinionFilterOptions.currentPatient
    ) {
      getSecondOpinions();

      const interval = setInterval(() => {
        getSecondOpinions();
      }, IntervalTimes.Minute_MS_30);

      return () => {
        clearInterval(interval);
      };
    }
  }, [secondOpinionFilterOptions]);

  useEffect(() => {
    if (patientOrigin > 0) {
      getSmartScanSetting();
    }
  }, [patientOrigin]);

  useEffect(() => {
    if (
      smartScanFilterOptions.smartScansCurrentPage > 0 &&
      smartScanFilterOptions.pgId > 0 &&
      smartScanFilterOptions.currentPatient
    ) {
      getSmartScans();

      const interval = setInterval(() => {
        getSmartScans();
      }, IntervalTimes.Minute_MS_30);

      return () => clearInterval(interval);
    }
  }, [smartScanFilterOptions]);

  useEffect(() => {
    if (
      upcomingAppointmentsFilterOptions.currentPage > 0 &&
      upcomingAppointmentsFilterOptions.pgId > 0 &&
      upcomingAppointmentsFilterOptions.currentPatient
    ) {
      getUpcomingAppointments();

      const interval = setInterval(() => {
        getUpcomingAppointments();
      }, IntervalTimes.Minute_MS_30);

      return () => {
        clearInterval(interval);
      };
    }
  }, [upcomingAppointmentsFilterOptions]);

  function initSmartScansFetchingFilterOptions() {
    let newSmartScanFilterOptions = {
      ...smartScanFilterOptions,
      currentPatient: currentPatient,
      smartScansCurrentPage: PaginationConstants.FirstPageNo
    };
    setSmartScanCurrentPage(PaginationConstants.FirstPageNo);
    setSmartScanFilterOptions(newSmartScanFilterOptions);
    shouldFetchSmartScans.current = false;
  }

  function initSecondOpinionsFetchingFilterOptions() {
    let newSecondOpinionFilterOptions = {
      ...secondOpinionFilterOptions,
      currentPatient: currentPatient,
      secondOpinionCurrentPage: PaginationConstants.FirstPageNo
    };
    setSecondOpinionCurrentPage(PaginationConstants.FirstPageNo);
    setSecondOpinionFilterOptions(newSecondOpinionFilterOptions);
    shouldFetchSecondOpinions.current = false;
  }

  function initUpcomingAppointmentsFetchingFilterOptions() {
    let newUpcomingAppointmentsFilterOptions = {
      ...upcomingAppointmentsFilterOptions,
      currentPatient: currentPatient,
      currentPage: PaginationConstants.FirstPageNo
    };
    setCurrentPage(PaginationConstants.FirstPageNo);
    setUpcomingAppointmentsFilterOptions(newUpcomingAppointmentsFilterOptions);
    shouldFetchUpcomingAppointments.current = false;
  }

  function resetSmartScans() {
    setSmartScans([]);
    setSmartScanTotalCount(0);
  }

  function getSmartScans() {
    if (
      smartScanFilterOptions.pgId > 0 &&
      smartScanFilterOptions.currentPatient &&
      smartScanFilterOptions.smartScansCurrentPage > 0
    ) {
      let params = {
        itemPerPage: PaginationConstants.SmartScansItemPerPage,
        currentPageNum: smartScanFilterOptions.smartScansCurrentPage,
        pgId: smartScanFilterOptions.pgId,
        patientId: smartScanFilterOptions.currentPatient.patid,
        isSeenByPatient: false,
        excludeStatuses:
          smartScanFilterOptions.excludeSmartScanStatuses?.join(",")
      };

      dispatch(showLoadingSpinner());
      getSmartScans_Ajax(
        params,
        function (response) {
          resetSmartScans();
          dispatch(hideLoadingSpinner());
          if (
            response &&
            response.success &&
            response.data &&
            response.data.smartScans &&
            Array.isArray(response.data.smartScans)
          ) {
            setSmartScans(response.data.smartScans);
            setSmartScanTotalCount(response.data.totalCount);
          } else if (response && !response.success) {
            if (response.message && response.total_count == -1) {
              showAlertDialouge("Error", response.message);
            }
          }
        },
        function (err) {
          resetSmartScans();
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  function UpdateSmartScanSeenStatus(smartScanIds) {
    if (smartScanIds) {
      let status = IS_SEEN;

      dispatch(showLoadingSpinner());
      updateSmartScanSeenStatus_ajax(
        JSON.stringify(smartScanIds),
        status,
        function (response) {
          dispatch(hideLoadingSpinner());
          if (response && !response.success && response.message) {
            showAlertDialouge("Error", response.message);
          }
        },
        function (err) {
          handleApiErrors(err);
          dispatch(hideLoadingSpinner());
        }
      );
    }
  }

  function getSecondOpinions() {
    if (
      secondOpinionFilterOptions.secondOpinionCurrentPage &&
      secondOpinionFilterOptions.pgId &&
      secondOpinionFilterOptions.currentPatient
    ) {
      var params = {
        itemPerPage: PaginationConstants.SecondOpinionsItemPerPage,
        excludeStatus: SecondOpinionStatus.Pending,
        currentPageNum: secondOpinionFilterOptions.secondOpinionCurrentPage,
        pgId: secondOpinionFilterOptions.pgId,
        patientId: secondOpinionFilterOptions.currentPatient.patid
      };

      dispatch(showLoadingSpinner());
      getSecondOpinions_Ajax(
        params,
        function (response) {
          dispatch(hideLoadingSpinner());
          if (
            response &&
            response.success &&
            response.data &&
            !isEmptyArray(response.data.secondOpinions)
          ) {
            setSecondOpinions(response.data.secondOpinions);
            setSecondOpinionTotalCount(response.data.totalCount);
          } else {
            setSecondOpinions([]);
            setSecondOpinionTotalCount(0);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  function getSecondOpinionCharge() {
    if (clientName) {
      dispatch(showLoadingSpinner());
      getSecondOpinionSetting_Ajax(
        clientName,
        function (response) {
          dispatch(hideLoadingSpinner());

          if (
            response &&
            response.success &&
            response.data &&
            response.data.secondOpinionSetting &&
            response.data.secondOpinionSetting.charge
          ) {
            dispatch(
              setSecondOpinionCharge(response.data.secondOpinionSetting.charge)
            );
            dispatch(sethasSecondOpinionPromoCode(response.data.hasPromoCode));
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  function getSmartScanSetting() {
    if (patientOrigin > 0) {
      dispatch(showLoadingSpinner());
      getSmartScanSetting_Ajax(
        patientOrigin,
        function (response) {
          dispatch(hideLoadingSpinner());

          if (
            response &&
            response.success &&
            response.data &&
            response.data.smartScanSetting &&
            response.data.smartScanSetting.charge
          ) {
            dispatch(setSmartScanCharge(response.data.smartScanSetting.charge));
          }
        },
        function (error) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(error);
        }
      );
    }
  }

  function getCampaignsCharges() {
    if (onDemandCampaignId || schedulerCampaignId) {
      var campaignIdsList = [];

      if (onDemandCampaignId) {
        campaignIdsList.push(onDemandCampaignId);
      }

      if (schedulerCampaignId) {
        campaignIdsList.push(schedulerCampaignId);
      }

      var params = {
        campaignIds: campaignIdsList.join(",")
      };

      dispatch(showLoadingSpinner());
      getBasicCampaignsInfo_Ajax(
        params,
        function (response) {
          dispatch(hideLoadingSpinner());
          if (
            response &&
            response.success &&
            response.data &&
            response.data.campaignsBasicInfo &&
            Array.isArray(response.data.campaignsBasicInfo) &&
            response.data.campaignsBasicInfo.length > 0
          ) {
            response.data.campaignsBasicInfo.map((campaignData) => {
              if (campaignData.campaignId == onDemandCampaignId) {
                dispatch(
                  setOnDemandCampaignCharge(campaignData.campaignCharge)
                );
                dispatch(sethasOnDemandPromoCode(campaignData.hasPromoCode));
              }

              if (campaignData.campaignId == schedulerCampaignId) {
                dispatch(
                  setSchedulerCampaignCharge(campaignData.campaignCharge)
                );
                dispatch(sethasSchedulerPromoCode(campaignData.hasPromoCode));
              }
            });
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  function removeApptQueryParams() {
    const param = searchParams.get("apptBookingSuccess");

    if (param) {
      searchParams.delete("apptBookingSuccess");
      setSearchParams(searchParams);
    }
  }

  function checkForAppointmentBookingStatus() {
    var params = queryStringToJSON();
    if (params.apptbookingsuccess) {
      if (params.apptbookingsuccess == "true") {
        showAlertDialouge("Message", "Appointment Scheduled Successfully.");
      } else if (params.apptbookingsuccess == "false") {
        showAlertDialouge(
          "Error",
          "Your appointment could not be scheduled. Please try again."
        );
      }
    }
  }

  function checkForCallbackRequestStatus() {
    let params = queryStringToJSON();
    if (params?.callbackrequestsuccess == "true") {
      showAlertDialouge(
        "Request Received!",
        <>
          <p>One of our Virtual Assistants will reach out soon.</p>
          <small>
            <em>Call back time is typically less than 5 minutes</em>
          </small>
        </>
      );
    }
  }

  function checkForPaymentQueryParams() {
    let params = queryStringToJSON();
    if (params.paymentsuccess) {
      checkForPaymentCompletionStatus(params.paymentsuccess);
    }
  }

  function displayAlertBasedOnQueryParamOfUrl() {
    let params = queryStringToJSON();
    if (params.isauthorized && params.message) {
      if (params.isauthorized == "false") {
        showAlertDialouge("Error", params.message);
      }
    } else if (params.secondopinionrequestsuccess == "false") {
      showAlertDialouge("Error", "Failed to authorize payment");
    }
  }

  function removeQueryParamsFromUrl() {
    const param1 = searchParams.get("isAuthorized");
    const param2 = searchParams.get("message");
    const param3 = searchParams.get("secondOpinionRequestSuccess");
    const param4 = searchParams.get("paymentSuccess");
    const callbackRequestSuccess = searchParams.get("callbackRequestSuccess");

    if (param1) {
      searchParams.delete("isAuthorized");
    }
    if (param2) {
      searchParams.delete("message");
    }
    if (param3) {
      searchParams.delete("secondOpinionRequestSuccess");
    }

    if (param4) {
      searchParams.delete("paymentSuccess");
    }

    if (callbackRequestSuccess) {
      searchParams.delete("callbackRequestSuccess");
    }

    setSearchParams(searchParams);
  }

  function getUpcomingAppointments() {
    if (
      isScheduledOnDemandCallFeatureEnabled &&
      upcomingAppointmentsFilterOptions.currentPage &&
      upcomingAppointmentsFilterOptions.currentPatient &&
      upcomingAppointmentsFilterOptions.currentPatient.patid
    ) {
      var params = {
        itemPerPage: PaginationConstants.UpcomingAppointmentsItemPerPage,
        currentPageNum: upcomingAppointmentsFilterOptions.currentPage,
        patientId: upcomingAppointmentsFilterOptions.currentPatient.patid,
        includeScheduledCallsOnly: true,
        sortingOrder: SortOrders.Ascending,
        statuses: ConsultationStatus.Scheduled,
        sendAllRows: false,
        maxTimeInMinutesAfterScheduledTime: 30
      };

      dispatch(showLoadingSpinner());
      getOnDemandConsultations_Ajax(
        params,
        function (response) {
          dispatch(hideLoadingSpinner());

          if (
            response &&
            response.success &&
            response.data &&
            response.data.consultations
          ) {
            processAndSetUpcomingAppointments(response.data.consultations);
            setTotalCount(response.data.totalCount);
          } else {
            setUpcomingAppointments([]);
            setTotalCount(0);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  function processAndSetUpcomingAppointments(appointments) {
    if (
      appointments &&
      Array.isArray(appointments) &&
      appointments.length > 0
    ) {
      appointments.forEach((appointment) => {
        if (isJoinButtonEnabled(appointment)) {
          appointment.isJoinButtonEnabled = true;
        } else {
          appointment.isJoinButtonEnabled = false;
        }
      });
    }

    appointmentsRef.current = appointments;
    setUpcomingAppointments(appointments);
  }

  function redirectToConferenceUrl(meetingJoinUrl, windowReference) {
    var err = "";

    if (meetingJoinUrl) {
      var tokenData = getTokenData();

      if (tokenData && tokenData.token) {
        meetingJoinUrl += "&apikey=" + PATIENT_PORTAL_AUTO_LOGIN_API_KEY;
        meetingJoinUrl += "&token=" + tokenData.token;

        windowReference.location = meetingJoinUrl;
      } else {
        err = "Token data not found.";
      }
    } else {
      err = "Meeting join Url not found.";
    }

    if (err) {
      showAlertDialouge("Error", err);
    }
  }

  function joinVideoCallBtnClicked(appointment) {
    if (browserEligibility && !browserEligibility.status) {
      let selectedPatientId = getSelectedPatientId();
      let autoLoginUrl = getAutoLoginUrl({
        redirectTo: "/dashboard",
        patientId: selectedPatientId
      });
      showUnsupportedBrowserModal(autoLoginUrl);
      return;
    }

    if (appointment && appointment.id && appointment.meetingJoinUrl) {
      var data = {
        id: appointment.id,
        status: ConsultationStatus.Initiated
      };

      var windowReference = window.open("", "_self");

      dispatch(showLoadingSpinner());
      updateOnDemandConsultation_Ajax(
        JSON.stringify(data),
        function (response) {
          dispatch(hideLoadingSpinner());

          if (response && response.success) {
            setCurrentPage(PaginationConstants.FirstPageNo);
            getUpcomingAppointments();

            redirectToConferenceUrl(
              appointment.meetingJoinUrl,
              windowReference
            );
          } else if (response && !response.success && response.message) {
            showAlertDialouge("Error", response.message);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  function getAppointmentDateTime(appointment) {
    if (appointment && appointment.scheduledDateTime) {
      var dateUtc = moment.utc(appointment.scheduledDateTime).local();

      return (
        dateUtc.format("dddd") +
        " " +
        dateUtc.format("MMMM Do YYYY") +
        " at " +
        formatDateTimeWithZone(dateUtc, "h:mm:ss A")
      );
    }
  }

  function checkIfJoinedButtonEnabled() {
    const interval = setInterval(() => {
      var appointments = [...appointmentsRef.current];

      if (
        appointments &&
        Array.isArray(appointments) &&
        appointments.length > 0
      ) {
        processAndSetUpcomingAppointments(appointments);
      }
    }, IntervalTimes.Minute_MS_30);

    return () => clearInterval(interval);
  }

  function isJoinButtonEnabled(appointment) {
    if (appointment && appointment.scheduledDateTime) {
      var dateTimeUtc = moment.utc(appointment.scheduledDateTime).local();
      var localDateTime = moment.now();

      var difference = dateTimeUtc.diff(localDateTime);

      if (difference < 0) {
        return true;
      }
    }
  }

  function rescheduleBtnClicked(consultation) {
    if (consultation && consultation.id && consultation.scheduledDateTime) {
      if (consultation.providerId == CONSULTATION_PROVIDER_PENDING) {
        navigate("/reschedule-virtual-appointment", {
          state: {
            existingScheduledDateTime: consultation.scheduledDateTime,
            consultationId: consultation.id
          }
        });
      } else if (consultation.providerId > 0) {
        navigate("/second-opinion-appointment-reschedule", {
          state: {
            consultation: consultation
          }
        });
      }
    }
  }

  function cancelBtnClicked(consultation) {
    if (consultation) {
      navigate("/cancel-appointment", {
        state: {
          consultation: consultation
        }
      });
    }
  }

  function getStartDateTime(scheduledDateTime) {
    var dateObj = moment.utc(scheduledDateTime).local();
    const startDateTime = dateObj.toISOString().replace(/-|:|\.\d+/g, "");
    return startDateTime;
  }

  function getEndDateTime(scheduledDateTime) {
    var dateObj = moment.utc(scheduledDateTime).local();
    const endDateTime = dateObj
      .add(30, "minutes")
      .toISOString()
      .replace(/-|:|\.\d+/g, "");
    return endDateTime;
  }

  function addToGoogleCalendar(appointment) {
    if (appointment && appointment.scheduledDateTime) {
      const startDateTime = getStartDateTime(appointment.scheduledDateTime);
      const endDateTime = getEndDateTime(appointment.scheduledDateTime);

      var dashboardUrl = getBaseUrl() + "/dashboard";
      var eventDescription =
        "Please click here: " +
        dashboardUrl +
        " to view your upcoming appointments";

      const encodedUrl = encodeURI(
        [
          "https://www.google.com/calendar/render",
          "?action=TEMPLATE",
          "&text=dental.com appointment",
          `&dates=${startDateTime || ""}`,
          `/${endDateTime || ""}`,
          `&details=${eventDescription}`,
          `&location=${appointment.campaignLocationStateName || ""}`,
          "&sprop=&sprop=name:"
        ].join("")
      );

      openUrlInNewTab(encodedUrl);
    }
  }

  function generateIcsCalendarFile(appointment) {
    if (appointment && appointment.scheduledDateTime) {
      const startDateTime = getStartDateTime(appointment.scheduledDateTime);
      const endDateTime = getEndDateTime(appointment.scheduledDateTime);

      var dashboardUrl = getBaseUrl() + "/dashboard";
      var eventDescription =
        "Please click here: " +
        dashboardUrl +
        " to view your upcoming appointments";

      const encodedUrl = encodeURI(
        `data:text/calendar;charset=utf8,${[
          "BEGIN:VCALENDAR",
          "VERSION:2.0",
          "BEGIN:VEVENT",
          `DTSTART:${startDateTime || ""}`,
          `DTEND:${endDateTime || ""}`,
          "SUMMARY:dental.com appointment",
          `DESCRIPTION:${eventDescription}`,
          `LOCATION:${appointment.campaignLocationStateName || ""}`,
          "END:VEVENT",
          "END:VCALENDAR"
        ].join("\n")}`
      );

      openUrlInNewTab(encodedUrl);
    }
  }

  function getCompletedClassForRecordRequested(secondOpinion) {
    if (
      secondOpinion &&
      (secondOpinion.status == SecondOpinionStatus.RecordRequested ||
        secondOpinion.status == SecondOpinionStatus.RecordSubmitted ||
        secondOpinion.status == SecondOpinionStatus.ReportReady ||
        secondOpinion.status == SecondOpinionStatus.CaseAccepted)
    ) {
      return "complete";
    }
  }

  function getCompletedClassForRecordSubmitted(secondOpinion) {
    if (
      secondOpinion &&
      (secondOpinion.status == SecondOpinionStatus.RecordSubmitted ||
        secondOpinion.status == SecondOpinionStatus.ReportReady ||
        secondOpinion.status == SecondOpinionStatus.CaseAccepted)
    ) {
      return "complete";
    }
  }

  function getCompletedClassForReportReady(secondOpinion) {
    if (
      secondOpinion &&
      secondOpinion.status == SecondOpinionStatus.ReportReady
    ) {
      return "complete";
    }
  }

  function getCompletedClassForAdditionalInfoNeeded(secondOpinion) {
    if (
      secondOpinion &&
      secondOpinion.status == SecondOpinionStatus.AdditionalInfoNeeded
    ) {
      return "complete";
    }
  }

  function isSmartScanInSubmittedStatus(smartScan) {
    return (
      smartScan &&
      (smartScan.generalStatus == SmartScanGeneralStatus.Submitted ||
        smartScan.generalStatus == SmartScanGeneralStatus.InProgress ||
        smartScan.generalStatus == SmartScanGeneralStatus.ReportReady)
    );
  }

  function isSmartScanInInProgresStatus(smartScan) {
    return (
      smartScan &&
      (smartScan.generalStatus == SmartScanGeneralStatus.InProgress ||
        smartScan.generalStatus == SmartScanGeneralStatus.ReportReady)
    );
  }

  function isSmartScanInReportReadyStatus(smartScan) {
    return (
      smartScan && smartScan.generalStatus == SmartScanGeneralStatus.ReportReady
    );
  }

  function getSmartScanCharge() {
    return shouldDisplaySmartScanChargeInfo ? "FREE" : "";
  }

  function secondOpinionClicked(secondOpinion) {
    if (secondOpinion) {
      if (secondOpinion.status == SecondOpinionStatus.RecordRequested) {
        navigate("/update-record", {
          state: {
            secondOpinionId: secondOpinion.secondOpinionId
          }
        });
      } else if (
        secondOpinion.status == SecondOpinionStatus.RecordSubmitted ||
        secondOpinion.status == SecondOpinionStatus.AdditionalInfoNeeded
      ) {
        navigate("/case-review", {
          state: {
            secondOpinionId: secondOpinion.secondOpinionId
          }
        });
      } else if (secondOpinion.status == SecondOpinionStatus.ReportReady) {
        navigate("/request-completed-details/" + secondOpinion.secondOpinionId);
      }
    }
  }

  function renderRequestPhoneCall() {
    return <RequestPhoneCall />;
  }

  function renderSecondOpinions() {
    if (!isEmptyArray(secondOpinions)) {
      return (
        <>
          {secondOpinions.map((secondOpinion, index) => {
            return (
              <div
                key={index}
                className="patient-member-card second-opinion-card mb-3"
                onClick={() => secondOpinionClicked(secondOpinion)}
              >
                <Link
                  className="card text-body text-decoration-none d-flex flex-row align-items-center border-0 border-radius-xlg p-3"
                  to=""
                >
                  <div className="card-header flex-shrink-000 p-0 border-0">
                    <img src={SecondOpinionIcon} alt="Second Opinion Icon" />
                  </div>
                  <div className="card-body flex-grow-1 px-3 px-md-4 py-0 border-0">
                    <h3 className="card-title">Second opinion status</h3>
                    <div className="text-muted mb-2 mb-md-3">
                      {formatDateTime(
                        secondOpinion?.createdOn,
                        DateTimeFormats.MMM_DD_YYYY
                      )}
                    </div>
                    <ul className="second-opinion-steps list-unstyled text-muted d-flex flex-wrap mb-0">
                      <li
                        key={"Records_Requested"}
                        className={
                          "ps-4 mb-1 me-md-3 w-100 w-md-auto position-relative " +
                          getCompletedClassForRecordRequested(secondOpinion)
                        }
                      >
                        <i className="bi bi-check-circle-fill fs-3 position-absolute start-0"></i>{" "}
                        Records Requested
                      </li>
                      <li
                        key={"Records_Submitted"}
                        className={
                          "ps-4 mb-1 me-md-3 w-100 w-md-auto position-relative " +
                          getCompletedClassForRecordSubmitted(secondOpinion)
                        }
                      >
                        <i className="bi bi-check-circle-fill fs-3 position-absolute start-0"></i>{" "}
                        Records Submitted
                      </li>
                      {secondOpinion.status ==
                        SecondOpinionStatus.AdditionalInfoNeeded && (
                        <li
                          key={"Records_Submitted"}
                          className={
                            "ps-4 mb-1 me-md-3 w-100 w-md-auto position-relative " +
                            getCompletedClassForAdditionalInfoNeeded(
                              secondOpinion
                            )
                          }
                        >
                          <i className="bi bi-check-circle-fill fs-3 position-absolute start-0"></i>{" "}
                          More Information Needed
                        </li>
                      )}
                      <li
                        key={"Second_Option_Report_Ready"}
                        className={
                          "ps-4 mb-1 me-md-3 w-100 w-md-auto position-relative " +
                          getCompletedClassForReportReady(secondOpinion)
                        }
                      >
                        <i className="bi bi-check-circle-fill fs-3 position-absolute start-0"></i>{" "}
                        Second Opinion Report Ready
                      </li>
                    </ul>
                    {secondOpinion.status == SecondOpinionStatus.ReportReady ? (
                      <span className="btn btn-secondary btn-sm px-4 mt-2">
                        View Report
                      </span>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="card-footer fs-2 bg-primary p-0 border-0 rounded-circle d-flex flex-wrap justify-content-center align-content-center">
                    <i className="bi bi-chevron-right bi bi-chevron-right"></i>
                  </div>
                </Link>
              </div>
            );
          })}
          <Pagination
            className="pagination-bar"
            currentPage={secondOpinionCurrentPage}
            totalCount={secondOpinionTotalCount ? secondOpinionTotalCount : 0}
            pageSize={PaginationConstants.SecondOpinionsItemPerPage}
            onPageChange={(page) => setSecondOpinionCurrentPage(page)}
          />
          <hr className="my-4" />
        </>
      );
    } else {
      return <></>;
    }
  }

  function requestPhoneCallBtnClicked(consultation) {
    if (!consultation?.id) {
      return;
    }
    let data = {
      id: consultation.id,
      status: ConsultationStatus.Requested
    };

    dispatch(showLoadingSpinner());
    updateOnDemandConsultation_Ajax(
      JSON.stringify(data),
      function (response) {
        dispatch(hideLoadingSpinner());
        if (response?.success) {
          showAlertDialouge(
            "Success",
            "Phone call has been requested successfully"
          );

          setCurrentPage(PaginationConstants.FirstPageNo);
          getUpcomingAppointments();
        } else if (!response?.success && response?.message) {
          showAlertDialouge("Error", response.message);
        }
      },
      function (err) {
        dispatch(hideLoadingSpinner());
        handleApiErrors(err);
      }
    );
  }

  function renderScheduledAppointments() {
    if (!isEmptyArray(upcomingAppointments)) {
      return (
        <>
          {upcomingAppointments.map((appointment, index) => {
            return (
              <div className="upcoming-member-card patient-member-card mb-3">
                <div className="card text-body text-decoration-none d-flex flex-row align-items-center border-0 border-radius-xlg p-3">
                  <div className="card-header p-0 border-0">
                    <img src={upcomingIcon} alt="Upcoming Icon" />
                  </div>

                  <div className="card-body d-flex flex-wrap justify-content-between ps-3 pe-0 ps-md-4 py-0 border-0">
                    <div className="card-body-left pe-md-3 mb-0 mb-lg-0">
                      <h3 className="card-title">
                        Upcoming appointment{" "}
                        <span className="text-primary d-inline-block">
                          {getAppointmentDateTime(appointment)}
                        </span>
                      </h3>
                      <p className="card-text">{appointment?.patientName}</p>

                      <div className="d-flex flex-wrap">
                        <div className="d-none align-items-center me-md-3 me-lg-5">
                          <figure className="small-profile-pic my-0 me-2">
                            <img
                              className="rounded-circle object-cover"
                              src={profilePic}
                              alt="Profile Pic"
                            />
                          </figure>
                          <div className="right">
                            <h4 className="name mb-1 fs-4 fw-bold">
                              Scott Hawkings
                            </h4>
                            <div className="fs-6 text-muted">
                              Scott.Hawkings@gmail.com
                            </div>
                          </div>
                        </div>
                        <div className="join-video-call-wrapper d-flex flex-wrap align-items-center">
                          {appointment?.voipCallId > 0 && (
                            <button
                              href="javascript:;"
                              className="btn btn-secondary btn-sm ms-0 disabled111"
                              disabled={!appointment?.isJoinButtonEnabled}
                              onClick={() =>
                                requestPhoneCallBtnClicked(appointment)
                              }
                            >
                              Request phone call
                            </button>
                          )}
                          {!appointment?.voipCallId > 0 && (
                            <button
                              href="javascript:;"
                              className="btn btn-secondary btn-sm ms-0 disabled111"
                              disabled={!appointment?.isJoinButtonEnabled}
                              onClick={() =>
                                joinVideoCallBtnClicked(appointment)
                              }
                            >
                              Join Video Call
                            </button>
                          )}
                          {!appointment?.isJoinButtonEnabled ? (
                            <>
                              <i
                                className="bi bi-info-circle-fill fs-3 text-primary ms-2"
                                data-place="top"
                                data-for="dashboardToolTip"
                                data-type="light"
                                data-tip="This button will automatically be activated at the time of your scheduled visit."
                              ></i>
                              <ReactTooltip
                                id="dashboardToolTip"
                                className="tooltip"
                              />
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="card-body-right patient-info-footer d-flex flex-wrap111 justify-content-between flex-column">
                      <div className="appointment-buttons my-2 my-md-0 d-flex flex-wrap111">
                        {!(appointment.apptId > 0) ? (
                          <button
                            className="tab-focusable-background btn btn-primary btn-xs"
                            onClick={() => rescheduleBtnClicked(appointment)}
                          >
                            <i className="bi bi-clock text-white pe-1 d-none d-md-inline-block"></i>
                            Reschedule
                          </button>
                        ) : (
                          <></>
                        )}
                        <button
                          className="tab-focusable-background btn btn-outline-primary btn-xs ms-2 ms-md-3"
                          onClick={() => cancelBtnClicked(appointment)}
                        >
                          <i className="bi bi-x-circle pe-1 d-none d-md-inline-block"></i>
                          Cancel
                        </button>
                      </div>
                      <div className="appointment-icons d-flex align-items-center justify-content-md-end">
                        <a
                          href="javascript:;"
                          onClick={() => generateIcsCalendarFile(appointment)}
                          className="me-3"
                          aria-label="Add to calendar"
                        >
                          <img src={calendarIcon} alt="Calendar icon" />
                        </a>
                        <a
                          href="javascript:;"
                          onClick={() => generateIcsCalendarFile(appointment)}
                          className="me-3"
                          aria-label="Add to Outlook"
                        >
                          <img src={outlookIcon} alt="OutLook icon" />
                        </a>
                        <a
                          href="javascript:;"
                          onClick={() => addToGoogleCalendar(appointment)}
                          aria-label="Add to calendar2"
                        >
                          <img src={calendar2Icon} alt="Calendar2 icon" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={totalCount ? totalCount : 0}
            pageSize={PaginationConstants.UpcomingAppointmentsItemPerPage}
            onPageChange={(page) => setCurrentPage(page)}
          />
          <hr className="my-4" />
        </>
      );
    } else {
      return <></>;
    }
  }

  function onSmartScanClicked(smartScan) {
    if (
      smartScan &&
      smartScan.smartScanId > 0 &&
      (smartScan.generalStatus == SmartScanGeneralStatus.Submitted ||
        smartScan.generalStatus == SmartScanGeneralStatus.InProgress)
    ) {
      navigate("/smart-scan-submitted-records", {
        state: {
          smartScanId: smartScan.smartScanId
        }
      });
    } else if (
      smartScan &&
      smartScan.smartScanId > 0 &&
      smartScan.generalStatus == SmartScanGeneralStatus.ReportReady
    ) {
      if (!smartScan.seenbypatient) {
        UpdateSmartScanSeenStatus([smartScan.smartScanId]);
      }
      navigate("/smart-scan-wellness-score", {
        state: {
          smartScanId: smartScan.smartScanId,
          patientId: smartScan.patId,
          userData: userData
        }
      });
    }
  }

  function renderSmartScans() {
    if (!isEmptyArray(smartScans)) {
      return (
        <>
          {smartScans.map((smartScan, index) => {
            return (
              <div
                key={smartScan.smartScanId.toString()}
                onClick={() => onSmartScanClicked(smartScan)}
                className="patient-member-card second-opinion-card mb-3"
              >
                <Link
                  to=""
                  className="card text-body text-decoration-none d-flex flex-row align-items-center border-0 border-radius-xlg p-3"
                >
                  <div className="card-header flex-shrink-000 p-0 border-0">
                    <img src={IconSmartScan} alt="Smart Scan Icon" />
                  </div>
                  <div className="card-body flex-grow-1 px-3 px-md-4 py-0 border-0">
                    <h3 className="card-title">Smart Scan Status</h3>
                    <ul className="second-opinion-steps list-unstyled text-muted d-flex flex-wrap mb-0">
                      <li
                        key={"Is_Smart_Scan_Submitted_Status"}
                        className={
                          "ps-4 mb-1 me-md-3 w-100 w-md-auto position-relative " +
                          (isSmartScanInSubmittedStatus(smartScan)
                            ? "complete "
                            : "")
                        }
                      >
                        <i className="bi bi-check-circle-fill fs-3 position-absolute start-0"></i>{" "}
                        Scans Submitted
                      </li>
                      <li
                        key={"Is_Smart_Scan_In_Progress_Status"}
                        className={
                          "ps-4 mb-1 me-md-3 w-100 w-md-auto position-relative " +
                          (isSmartScanInInProgresStatus(smartScan)
                            ? "complete "
                            : "")
                        }
                      >
                        <i className="bi bi-check-circle-fill fs-3 position-absolute start-0"></i>{" "}
                        Scans In Progress
                      </li>
                      <li
                        key={"Is_Smart_Scan_In_Report_Ready_Status"}
                        className={
                          "ps-4 mb-1 me-md-3 w-100 w-md-auto position-relative " +
                          (isSmartScanInReportReadyStatus(smartScan)
                            ? "complete "
                            : "")
                        }
                      >
                        <i className="bi bi-check-circle-fill fs-3 position-absolute start-0"></i>{" "}
                        Wellness Report Ready
                      </li>
                    </ul>
                  </div>
                  <div className="card-footer fs-2 bg-primary p-0 border-0 rounded-circle d-flex flex-wrap justify-content-center align-content-center">
                    <i className="bi bi-chevron-right bi bi-chevron-right"></i>
                  </div>
                </Link>
              </div>
            );
          })}

          <Pagination
            className="pagination-bar"
            currentPage={smartScansCurrentPage}
            totalCount={smartScanTotalCount ? smartScanTotalCount : 0}
            pageSize={PaginationConstants.SmartScansItemPerPage}
            onPageChange={(pageNo) => setSmartScanCurrentPage(pageNo)}
          />
          <hr className="my-4" />
        </>
      );
    } else {
      return <></>;
    }
  }

  function onGraphPointClicked(smartScan) {
    if (
      smartScan &&
      smartScan[SmartScanGraphDataPropertyIndices.SmartScanId] > 0 &&
      smartScan[SmartScanGraphDataPropertyIndices.PatientId] > 0
    ) {
      navigate("/smart-scan-wellness-score", {
        state: {
          smartScanId: smartScan[SmartScanGraphDataPropertyIndices.SmartScanId],
          patientId: smartScan[SmartScanGraphDataPropertyIndices.PatientId],
          userData: userData
        }
      });
    }
  }

  function shouldDisplaySmartScanChart() {
    return isSmartScanFeatureAvailable || !isEmptyArray(smartScans);
  }

  function renderSmartScanChart() {
    if (shouldDisplaySmartScanChart()) {
      return (
        <SmartScanGraph
          patientId={currentPatient?.patid}
          refreshGraph={true}
          onGraphPointClicked={onGraphPointClicked}
          setUserHasSmartScan={setUserHasSmartScan}
        />
      );
    }

    return <></>;
  }

  function showUnsupportedBrowserModal(autoLoginUrl) {
    if (autoLoginUrl) {
      setAutoLoginUrl(autoLoginUrl);
      window.$("#modal-unsupported-browser").modal("show");
    }
  }

  function onSeeDentistNowBtnClicked() {
    if (!browserEligibility?.status) {
      let selectedPatientId = getSelectedPatientId();
      let autoLoginUrl = getAutoLoginUrl({
        selectedPatientId: selectedPatientId,
        redirectTo: "/see-dentist"
      });
      showUnsupportedBrowserModal(autoLoginUrl);
    } else {
      navigate("/see-dentist");
    }
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <h1 className="mb-4">{currentPatient?.fullName}</h1>
            {renderSmartScanChart()}
            {isCICIChatEnabled && userHasSmartScan ? (
              <ChatCard patientId={currentPatient?.patid} />
            ) : (
              <></>
            )}
            {renderRequestPhoneCall()}
            {renderSmartScans()}
            {renderScheduledAppointments()}
            {renderSecondOpinions()}
            {/* Section :: Smart Scan*/}
            {isSmartScanFeatureAvailable ? (
              <div className="patient-member-card mb-3">
                <Link
                  to="/smart-scan-works"
                  className="card text-body text-decoration-none d-flex flex-row align-items-center border-0 border-radius-xlg p-3"
                >
                  <div className="card-header flex-shrink-000 p-0 border-0">
                    <img src={IconSmartScan} alt="Smart Scan Icon" />
                  </div>
                  <div className="card-body flex-grow-1 px-3 px-md-4 py-0 border-0">
                    <h3 className="card-title">
                      Smart Scan
                      <span className="text-primary space-before">
                        {getSmartScanCharge()}
                      </span>
                    </h3>
                    <p className="card-text text-muted mb-1">
                      Take photos of your mouth and teeth to instantly see any
                      detected issues*
                    </p>
                    <small className="text-muted">
                      <em>
                        *For informational purposes only. Findings are not
                        reviewed by a licensed dentist and do not constitute a
                        dental diagnosis.
                      </em>
                    </small>
                  </div>
                  <div className="card-footer fs-2 bg-primary p-0 border-0 rounded-circle d-flex flex-wrap justify-content-center align-content-center">
                    <i className="bi bi-chevron-right bi bi-chevron-right"></i>
                  </div>
                </Link>
              </div>
            ) : (
              <></>
            )}
            {isOnDemandCallFeatureEnabled ? (
              <div
                className="patient-member-card mb-3"
                onClick={onSeeDentistNowBtnClicked}
              >
                <button className="w-100 text-start card text-body text-decoration-none d-flex flex-row align-items-center border-0 border-radius-xlg p-3">
                  <div className="card-header flex-shrink-000 p-0 border-0">
                    <img src={iconSeeDentist} alt="See a Dentist Now Icon" />
                  </div>
                  <div className="card-body flex-grow-1 px-2 px-md-4 py-0 border-0">
                    <h3 className="card-title">
                      See a dentist now
                      {shouldDisplayCampaingChargeInfo &&
                      onDemandCampaignCharge > 0 ? (
                        <span className="text-primary">
                          {" "}
                          ${onDemandCampaignCharge}
                        </span>
                      ) : (
                        <></>
                      )}
                    </h3>
                    <p className="card-text text-muted">
                      Get 24/7 care with a licensed dentist for conditions like
                      toothaches, broken tooth, infections, and more.
                    </p>
                  </div>
                  <div className="card-footer fs-2 bg-primary p-0 border-0 rounded-circle d-flex flex-wrap justify-content-center align-content-center">
                    <i className="bi bi-chevron-right bi bi-chevron-right"></i>
                  </div>
                </button>
              </div>
            ) : (
              <></>
            )}
            {isScheduledOnDemandCallFeatureEnabled ? (
              <div className="patient-member-card mb-3">
                <Link
                  to="/appointment-schedule"
                  className="card text-body text-decoration-none d-flex flex-row align-items-center border-0 border-radius-xlg p-3"
                >
                  <div className="card-header flex-shrink-000 p-0 border-0">
                    <img src={iconAppointment} alt="Appointment Icon" />
                  </div>
                  <div className="card-body flex-grow-1 px-2 px-md-4 py-0 border-0">
                    <h3 className="card-title">
                      Schedule virtual appointment
                      {shouldDisplayCampaingChargeInfo &&
                      schedulerCampaignCharge > 0 ? (
                        <span className="text-primary">
                          {" "}
                          ${schedulerCampaignCharge}
                        </span>
                      ) : (
                        <></>
                      )}
                    </h3>
                    <p className="card-text text-muted">
                      No rush? No problem. Select a date and time for a virtual
                      dental visit that works for your schedule.
                    </p>
                  </div>
                  <div className="card-footer fs-2 bg-primary p-0 border-0 rounded-circle d-flex flex-wrap justify-content-center align-content-center">
                    <i className="bi bi-chevron-right bi bi-chevron-right"></i>
                  </div>
                </Link>
              </div>
            ) : (
              <></>
            )}
            {/* Section 4 */}
            {secondOpinionButtonEnabled ? (
              <div className="patient-member-card mb-3">
                <Link
                  to="/instruction"
                  className="card text-body text-decoration-none d-flex flex-row align-items-center border-0 border-radius-xlg p-3"
                >
                  <div className="card-header flex-shrink-000 p-0 border-0">
                    <img src={SecondOpinionIcon} alt="Second Opinion Icon" />
                  </div>
                  <div className="card-body flex-grow-1 px-3 px-md-4 py-0 border-0">
                    <h3 className="card-title">
                      Get a second opinion{" "}
                      <span className="text-primary">
                        {shouldDisplaySecondOpinionChargeInfo &&
                          formatCurrency(secondOpinionCharge)}
                      </span>
                    </h3>
                    <p className="card-text text-muted">
                      A licensed dentist will review your records and create a
                      second opinion report.
                    </p>
                  </div>
                  <div className="card-footer fs-2 bg-primary p-0 border-0 rounded-circle d-flex flex-wrap justify-content-center align-content-center">
                    <i className="bi bi-chevron-right bi bi-chevron-right"></i>
                  </div>
                </Link>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <UnsupportedBrowserModal
        message={browserEligibility?.message}
        Url={autoLoginUrl}
      />
    </>
  );
};

export default DashboardCards;
