import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  AddDependentSuccessMessage,
  Roles,
  SecondOpinionFormTypes
} from "../../constants";
import {
  getSecondOpinionForm_Ajax,
  getStates_Ajax,
  savePatientProfileSettings_Ajax
} from "../../helpers/requests";
import {
  handleApiErrors,
  showConfirmDialouge,
  queryStringToJSON,
  initializeAdditionalChangesInFormIoFormDuringRender,
  isPatientDateOfBirthValid,
  isEmptyArray,
  getDefaultSelectedPatientId,
  showAlertDialouge
} from "../../helpers/utils";
import {
  clearSecondOpinionRecordRequestProgress,
  saveSecondOpinionRecordRequestProgress
} from "../../indexedDbHelper/secondOpinionRecordRequestProgressIndexedDbHelper";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import QuestionnaireTabs from "../QuestionnaireTabs/QuestionnaireTabs";

import { Form } from "@formio/react";
import "formiojs/dist/formio.full.min.css";
import AddDependentForm from "../AddDependentForm/AddDependentForm";
import ReactTooltip from "react-tooltip";
import {
  getPatientInfo,
  storeSelectedPatientProfile
} from "../../helpers/authManager";
import {
  saveCurrentPatient,
  setIsFamilyMembersListChanged
} from "../../redux/actions/userData";
import useGetSecondOpinionRecordRequestProgress from "../CustomHooks/useGetSecondOpinionRecordRequestProgress";
import { getPatientOrigin } from "../../ApplicationSettings";

const QuestionnaireIntakeFormPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const clientName = useSelector(
    (state) => state?.appSettings?.settingsData?.clientName
  );

  const [secondOpinionForm, setSecondOpinionForm] = useState(null);
  const [currentFormIndex, setCurrentFormIndex] = useState(0);
  const [totalForms, setTotalForms] = useState(0);
  const [patientState, setPatientState] = useState(null);
  const [displayFormData, setDisplayFormData] = useState(null);
  const [states, setStates] = useState([]);
  const [includeZState, setIncludeZState] = useState(false);
  const [submissionData, setSubmissionData] = useState();
  const [patientId, setPatientId] = useState(0);
  const [isAddFamilyMemberFormVisible, setIsAddFamilyMemberFormVisible] =
    useState(false);
  const savedProgress = useGetSecondOpinionRecordRequestProgress();

  const patientAndFamilyMemberList = useSelector(
    (state) => state.userData.patientAndFamilyMembersList
  );

  const officeId = useSelector(
    (state) => state?.appSettings?.settingsData?.officeId
  );

  const shouldCaptureInsurance = useSelector(
    (state) =>
      state?.appSettings?.settingsData
        ?.shouldCaptureInsuranceInSecondOpinionFlow
  );

  useEffect(() => {
    if (savedProgress?.patientState) {
      setPatientState(savedProgress.patientState);
    }
    if (savedProgress?.secondOpinionForm?.formData) {
      setSubmissionData(savedProgress.secondOpinionForm.formData);
    }
    if (savedProgress?.patientId) {
      setPatientId(savedProgress.patientId);
    }
  }, [savedProgress]);

  useEffect(() => {
    let params = queryStringToJSON();
    let includeZState = false;
    if (params.includezstate) {
      if (params.includezstate == "true") {
        includeZState = true;
      }
    }
    setIncludeZState(includeZState);
  }, []);

  useEffect(() => {
    getStates("US");
    getSecondOpinionForm();
  }, []);

  useEffect(() => {
    if (secondOpinionForm) {
      var formData = {};
      var formArray = JSON.parse(secondOpinionForm.formComponent);

      var formObj = {
        display: "form",
        components: formArray
      };

      var submissionData = {};
      var formOptions = {};

      if (secondOpinionForm.formData) {
        submissionData = {
          data: secondOpinionForm.formData
        };

        var formOptions = {
          readOnly: true,
          viewAsHtml: true
        };
      }

      formData.formObj = formObj;
      formData.submissionData = submissionData;
      formData.formOptions = formOptions;

      setDisplayFormData(formData);

      if (isFormSubmitted() && isPatientSelected()) {
        saveBtnClicked();
      }
    }
  }, [secondOpinionForm]);

  function getStates(countryCode) {
    if (countryCode) {
      getStates_Ajax(
        countryCode,
        function (response) {
          if (response && response.success && response.data) {
            setStates(response.data);
          }
        },
        function (err) {
          handleApiErrors(err);
        }
      );
    }
  }

  function onFormSubmit(submission) {
    setTimeout(function () {
      if (submission && submission.data) {
        onSuccessfulFormSubmission(submission.data);
      }
    }, 500);
  }

  function onSuccessfulFormSubmission(submissionData) {
    if (submissionData && secondOpinionForm) {
      var secondOpinionFormData = { ...secondOpinionForm };
      secondOpinionFormData.isSubmitted = true;
      secondOpinionFormData.formData = submissionData;

      setSecondOpinionForm(secondOpinionFormData);
    }
  }

  function isFormSubmitted() {
    return secondOpinionForm && secondOpinionForm.isSubmitted;
  }

  function isStateSelected() {
    return patientState && patientState != null;
  }

  function prepareAndSetSecondOpinionForm(form) {
    if (form) {
      let customFormTitle = "Second Opinion Intake Form";
      let formObj = {
        title: customFormTitle,
        formComponent: form.medicalForm?.components,
        medicalFormId: form.formId,
        isSubmitted: false
      };

      setSecondOpinionForm(formObj);
    }
  }

  function getSecondOpinionForm() {
    let params = {
      clientName: clientName
    };

    dispatch(showLoadingSpinner());
    getSecondOpinionForm_Ajax(
      SecondOpinionFormTypes.IntakeForm,
      params,
      function (response) {
        dispatch(hideLoadingSpinner());
        if (
          response &&
          response.success &&
          response.data &&
          response.data.secondOpinionForm
        ) {
          prepareAndSetSecondOpinionForm(response.data.secondOpinionForm);
        }
      },
      function (err) {
        dispatch(hideLoadingSpinner());
        handleApiErrors(err);
      }
    );
  }

  function saveBtnClicked() {
    let curState = {
      patientState,
      secondOpinionForm,
      patientId
    };
    saveSecondOpinionRecordRequestProgress(curState, patientId);
    if (shouldCaptureInsurance) {
      navigate(
        includeZState
          ? "/questionnaire-insurance-info?includeZState=true"
          : "/questionnaire-insurance-info",
        {
          state: curState
        }
      );
    } else {
      navigate("/record-request", {
        state: curState
      });
    }
  }

  function cancelBtnClicked() {
    navigate("/instruction");
  }

  function toogleAddFamilyMemberFormVisibility() {
    setIsAddFamilyMemberFormVisible(!isAddFamilyMemberFormVisible);
  }

  function handlePatientChange(e) {
    const patientId = e.target.value;
    setPatientId(patientId);
    setExistingFamilyMemberAsCurrentPatient(patientId);
  }

  function setExistingFamilyMemberAsCurrentPatient(patientId) {
    if (patientId) {
      let patientInfo = patientAndFamilyMemberList.find(
        (member) => member.patid == patientId
      );
      setCurrentPatient(patientInfo);
      storeSelectedPatientProfile(JSON.stringify(patientInfo));
    }
  }

  function setCurrentPatient(patientInfo) {
    if (patientInfo) {
      dispatch(saveCurrentPatient(patientInfo));
    }
  }

  function addDependentBtnClicked(formData) {
    if (isAddFamilyMemberFormVisible && formData) {
      let formDataValidationObj = validateAddDependentFormData(formData);

      if (formDataValidationObj.isValid) {
        let dependentData = prepareNewDependentData(formData);

        if (dependentData) {
          let dependentformData = new FormData();
          dependentformData.append("patientVM", JSON.stringify(dependentData));
          addDependent(dependentformData);
        }
      } else if (
        !formDataValidationObj.isValid &&
        formDataValidationObj.errorMessages
      ) {
        showAlertDialouge(
          "Error",
          formDataValidationObj.errorMessages.join(" ")
        );
        return;
      }
    }
  }

  function prepareNewDependentData(formData) {
    if (formData) {
      let patientInfo = getPatientInfo();

      return {
        firstName: formData.firstName,
        lastName: formData.lastName,
        gender: formData.gender,
        dateOfBirth: formData.dateOfBirth,
        respPartyUserId: patientInfo.user.id,
        isRespPartyIncluded: true,
        pgId: patientInfo.pgid,
        address1: patientInfo.address1,
        address2: patientInfo.address2,
        country: patientInfo.country,
        city: patientInfo.city,
        timezone: patientInfo.timezone,
        state: patientInfo.state,
        zip: patientInfo.zip,
        emergencyContactPhone: patientInfo.emergencyContactPhone,
        activeOfficeId: officeId,
        origin: getPatientOrigin(),
        newUser: {
          pgId: patientInfo.pgid,
          firstName: formData.firstName,
          lastName: formData.lastName,
          dateOfBirth: formData.dateOfBirth,
          roleId: Roles.Patient,
          gender: formData.gender,
          country: patientInfo.country,
          city: patientInfo.city,
          timezone: patientInfo.timezone,
          state: patientInfo.state,
          zip: patientInfo.zip
        },
        respParty: {
          pgId: patientInfo.pgid,
          email: patientInfo.user.userId,
          phone: patientInfo.user.phone,
          firstName: patientInfo.firstName,
          lastName: patientInfo.lastName,
          relationToPatient: formData.relation
        }
      };
    }
  }

  function addDependent(depdendentformData) {
    if (depdendentformData) {
      dispatch(showLoadingSpinner());
      savePatientProfileSettings_Ajax(
        depdendentformData,
        function (response) {
          dispatch(hideLoadingSpinner());

          if (response?.success && response?.data) {
            setIsAddFamilyMemberFormVisible(false);
            setPatientId(response.data.patid);
            showAlertDialouge("Message", AddDependentSuccessMessage);
            setCurrentPatient(response.data);
            dispatch(setIsFamilyMembersListChanged(true));
          } else if (!response.success && response.message) {
            showAlertDialouge("Error", response.message);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  function validateAddDependentFormData(formData) {
    let isValid = true;
    let errorMessages = [];

    if (formData) {
      if (formData.dateOfBirth) {
        let dobValidityCheck = isPatientDateOfBirthValid(formData.dateOfBirth);

        if (!dobValidityCheck.valid) {
          isValid = false;
          errorMessages.push(dobValidityCheck.errorMessage);
        }
      } else if (!formData.dateOfBirth) {
        isValid = false;
        errorMessages.push("Please enter a valid date of birth.");
      }
    } else {
      isValid = false;
    }

    if (isValid) {
      return { isValid };
    }

    return { isValid, errorMessages };
  }

  function isPatientSelected() {
    return patientId > 0;
  }

  useEffect(() => {
    if (patientId <= 0) {
      setPatientId(getDefaultSelectedPatientId(patientAndFamilyMemberList));
    }
    patientAndFamilyMemberList.forEach((member) => {
      clearSecondOpinionRecordRequestProgress(member.patid);
    });
  }, [patientAndFamilyMemberList]);

  function shouldShowIntakeForm() {
    return displayFormData && isStateSelected() && isPatientSelected();
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="bg-white border-radius-xlg px-4 px-md-5 py-4">
              <QuestionnaireTabs />

              <fieldset className="d-flex flex-wrap text-start align-content-center my-3 d-none111">
                <div className="form-group mt-5 col-md-8 col-xl-6 mx-auto">
                  <label
                    for="patient"
                    className="form-group-title fs-3 text-violet mb-2 mb-md-3 lh-1"
                  >
                    Who is this visit for?{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <div className="form-check mb-2 ps-0">
                    <select
                      className="form-select form-control"
                      name="dependentsList"
                      id="patient"
                      value={patientId}
                      disabled={isAddFamilyMemberFormVisible}
                      onChange={handlePatientChange}
                    >
                      <option value="">Select Patient</option>
                      {!isEmptyArray(patientAndFamilyMemberList) &&
                        patientAndFamilyMemberList.map((patient, index) => (
                          <option
                            key={patient.fullName + patient.patid}
                            value={patient.patid}
                          >
                            {patient.fullName}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div
                    className="mt-2"
                    onClick={toogleAddFamilyMemberFormVisibility}
                  >
                    <a
                      style={{
                        minWidth: "24px",
                        minHeight: "24px",
                        display: "block"
                      }}
                      href="javascript:;"
                      className="text-muted"
                    >
                      <em className="bi bi-plus"> </em>
                      Add Dependent
                      <em
                        className="bi bi-info-circle-fill fs-3 text-primary ms-2"
                        data-place="top"
                        data-for="dashboardToolTip"
                        data-type="light"
                        data-tip="A dependent is defined as children under the age of 18, elderly parents, or disabled adult children."
                      ></em>
                      <ReactTooltip id="dashboardToolTip" className="tooltip" />
                    </a>
                  </div>

                  <div
                    id="show-hide-div"
                    style={{
                      display: isAddFamilyMemberFormVisible ? "flex" : "none"
                    }}
                  >
                    <AddDependentForm onSubmit={addDependentBtnClicked} />
                  </div>
                  <div className="form-group mb-3 mt-4 col-12">
                    <label for="state" className="form-label text-violet">
                      Where will you be located during your visit?{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select form-control"
                      name="state"
                      id="state"
                      value={patientState}
                      onChange={(event) => setPatientState(event.target.value)}
                    >
                      <option key={0} value="">
                        Select States
                      </option>
                      {states.map((state, index) => {
                        return (
                          <option key={index + 1} value={state?.value}>
                            {state.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </fieldset>

              {shouldShowIntakeForm() ? (
                <div id="campaign-form">
                  <div className="campaign-form-container text-align-left">
                    <h3 className="fs-1 mb-0">{secondOpinionForm.title}</h3>
                    <Form
                      onRender={
                        initializeAdditionalChangesInFormIoFormDuringRender
                      }
                      key={"displayed_" + currentFormIndex}
                      form={displayFormData.formObj}
                      submission={{ data: submissionData }}
                      onSubmit={onFormSubmit}
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div className="tab-focusable-background form-group text-center mt-3 mt-md-5 col-sm-12 button-wrapper">
                <button
                  onClick={cancelBtnClicked}
                  className="btn btn-outline btn-rounded btn-lg px-5 mx-md-2 mt-3 mt-md-0 w-100 w-md-auto"
                >
                  Back
                </button>
                {!displayFormData && (
                  <button
                    onClick={saveBtnClicked}
                    disabled={!isStateSelected() || !isPatientSelected()}
                    className="btn btn-secondary btn-rounded btn-lg px-3 px-md-5 mt-0 mx-md-2 w-100 w-md-auto"
                  >
                    Next
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuestionnaireIntakeFormPage;
