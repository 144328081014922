import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getSmartScan_Ajax } from "../../helpers/requests";
import {
  getSmartScanImageCategoryName,
  handleApiErrors,
  isEmptyArray,
  onImageError,
  showAlertDialouge
} from "../../helpers/utils";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import { smartScanCategory } from "../../constants";

const SmartScanSubmittedRecordsPage = (props) => {
  const { smartScanId, setCategoryImageInfo, showBackButton } = props;
  const [smartScan, setSmartScan] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (smartScanId > 0) {
      getSmartScan();
    }
  }, [smartScanId]);

  function getSmartScan() {
    if (smartScanId > 0) {
      dispatch(showLoadingSpinner());

      getSmartScan_Ajax(
        smartScanId,
        function (response) {
          dispatch(hideLoadingSpinner());
          if (
            response &&
            response.success &&
            response.data &&
            response.data.smartScanVM
          ) {
            setSmartScan(response.data.smartScanVM);
          } else if (response && !response.success && response.message) {
            showAlertDialouge("Error", response.message);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  function goToDashboardBtnClicked() {
    navigate("/dashboard");
  }

  function goToBackBtnClicked() {
    navigate(-1);
  }

  function getSmartScanSubmittedImage(category) {
    if (!isEmptyArray(smartScan?.smartScanUploads)) {
      let image = smartScan?.smartScanUploads?.find(
        (u) => u.category == category
      );

      return (
        <div className="col-sm-6 mb-4">
          <div
            className="position-relative"
            data-bs-toggle="modal"
            data-bs-target="#fileViewerImage"
            onClick={() =>
              setCategoryImageInfo(
                image.photoId,
                getSmartScanImageCategoryName(image.category).toUpperCase()
              )
            }
          >
            <figure className="mb-0">
              <img
                className="w-100 h-100 object-cover border-radius-xlg"
                style={{ maxHeight: "310px" }}
                alt=""
                src={image.photoId}
                onError={onImageError}
              />
            </figure>
            <span className="badge bg-primary btn-xs fs-5 fw-normal m-3 position-absolute top-0 start-0">
              {getSmartScanImageCategoryName(image.category).toUpperCase()}
            </span>
          </div>
        </div>
      );
    }
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="gth-main-header mb-3">
              <div className="heading-wrapper back-to-wrapper position-relative">
                <h1 className="title">Smart Scan</h1>
              </div>
            </div>
            <div className="bg-white border-radius-xlg px-4 px-md-5 py-4">
              <div className="fixed-button-bottom text-center">
                {smartScan &&
                smartScan.smartScanUploads &&
                Array.isArray(smartScan.smartScanUploads) &&
                smartScan.smartScanUploads.length > 0 ? (
                  <>
                    <div className="row">
                      {getSmartScanSubmittedImage(smartScanCategory.UpperArch)}
                      {getSmartScanSubmittedImage(smartScanCategory.LowerArch)}
                      {getSmartScanSubmittedImage(smartScanCategory.UpperLip)}
                      {getSmartScanSubmittedImage(smartScanCategory.LowerLip)}
                      {getSmartScanSubmittedImage(smartScanCategory.Bite)}
                    </div>
                    {!showBackButton && (
                      <button
                        onClick={() => {
                          goToDashboardBtnClicked();
                        }}
                        className="tab-focusable-background btn btn-secondary btn-rounded btn-lg px-4 px-md-5 mt-3 mt-md-0 w-100 w-md-auto"
                      >
                        Go To Your dashboard
                      </button>
                    )}
                    {showBackButton && (
                      <button
                        onClick={() => {
                          goToBackBtnClicked();
                        }}
                        className="btn btn-outline btn-rounded btn-lg px-5 mx-md-2 mt-3 mt-md-0 w-100 w-md-auto"
                      >
                        Back
                      </button>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SmartScanSubmittedRecordsPage;
