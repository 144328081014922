import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  addSecondOpinionRequest_Ajax,
  getSecondOpinionSetting_Ajax
} from "../../helpers/requests";
import {
  handleApiErrors,
  isEmptyArray,
  redirectToCheckout,
  showAlertDialouge,
  showConfirmDialouge
} from "../../helpers/utils";
import {
  showLoadingSpinner,
  hideLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import QuestionnaireTabs from "../QuestionnaireTabs/QuestionnaireTabs";
import SeeDentistPromo from "../SeeDentist/SeeDentistPromo";
import {
  sethasSecondOpinionPromoCode,
  setSecondOpinionCharge
} from "../../redux/actions/userData";
import { EligibilityStatus, PaymentCancelUrl } from "../../constants";
import { getBaseUrl } from "../../ApplicationSettings";

const QuestionnaireReviewSubmitPage = (props) => {
  const {
    uploadedFiles,
    patientState,
    secondOpinionForm,
    consentForm,
    recordRequestProviderData,
    patientId,
    insuranceEligibilityStatus
  } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const clientName = useSelector(
    (state) => state?.appSettings?.settingsData?.clientName
  );
  const pgId = useSelector((state) => state?.appSettings?.settingsData?.pgId);
  const secondOpinionCharge = useSelector(
    (state) => state?.userData?.secondOpinionCharge
  );
  const hasPromoCode = useSelector(
    (state) => state?.userData?.hasSecondOpinionPromoCode
  );
  const [validPromoCode, setValidPromoCode] = useState(null);
  const [secondOpinionSettingId, setSecondOpinionSettingId] = useState(0);
  const [isInsuranceEligible, setIsInsuranceEligible] = useState(false);

  useEffect(() => {
    getSecondOpinion();
  }, [clientName]);

  function getSecondOpinion() {
    if (clientName) {
      dispatch(showLoadingSpinner());
      getSecondOpinionSetting_Ajax(
        clientName,
        function (response) {
          dispatch(hideLoadingSpinner());

          if (
            response &&
            response.success &&
            response.data &&
            response.data.secondOpinionSetting &&
            response.data.secondOpinionSetting.charge
          ) {
            dispatch(
              setSecondOpinionCharge(response.data.secondOpinionSetting.charge)
            );
          }

          if (
            response &&
            response.success &&
            response.data &&
            response.data.secondOpinionSetting &&
            response.data.secondOpinionSetting.secondOpinionSettingId
          ) {
            setSecondOpinionSettingId(
              response.data.secondOpinionSetting.secondOpinionSettingId
            );
            dispatch(sethasSecondOpinionPromoCode(response.data.hasPromoCode));
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  function saveSecondOpinionRequest() {
    if (patientState) {
      let requestSuccessURL =
        "/QuestionnaireAfterPaymentSuccessful?secondOpinionRequestSuccess=true";

      let paymentSuccessUrl = getBaseUrl() + requestSuccessURL;
      let paymentCancelUrl = getBaseUrl() + PaymentCancelUrl;

      var submittedIntakeForm;
      var submittedConsentForm;

      if (secondOpinionForm) {
        submittedIntakeForm = {
          medicalFormId: secondOpinionForm.medicalFormId,
          formData: JSON.stringify(secondOpinionForm.formData)
        };
      }

      if (consentForm) {
        submittedConsentForm = { ...consentForm.formData };
      }

      let requestVM = {
        state: patientState,
        paymentSuccessUrl: paymentSuccessUrl,
        paymentCancelUrl: paymentCancelUrl,
        submittedIntakeForm: submittedIntakeForm,
        submittedConsentData: submittedConsentForm,
        recordRequestProviderData: recordRequestProviderData,
        clientName: clientName,
        pgId: pgId,
        isInsuranceEligible: isInsuranceEligible
      };

      var formData = new FormData();
      formData.append("requestVM", JSON.stringify(requestVM));

      if (!isEmptyArray(uploadedFiles)) {
        uploadedFiles.map((upload) => {
          if (upload && upload.file) {
            formData.append("photos", upload.file);
          }
        });
      }

      var params = {
        promoCode: validPromoCode,
        patientId: patientId
      };

      dispatch(showLoadingSpinner());
      addSecondOpinionRequest_Ajax(
        formData,
        params,
        function (response) {
          dispatch(hideLoadingSpinner());

          if (response && response.success) {
            var isRedirectToStripe = false;
            var paymentSessionId = undefined;
            var stripeAccount = undefined;

            if (response.data && response.data.isRedirectToStripe) {
              isRedirectToStripe = response.data.isRedirectToStripe;
            }
            if (response.data && response.data.paymentSessionId) {
              paymentSessionId = response.data.paymentSessionId;
            }
            if (response.data && response.data.stripeAccount) {
              stripeAccount = response.data.stripeAccount;
            }

            if (isRedirectToStripe) {
              redirectToCheckout(paymentSessionId, stripeAccount);
            } else {
              navigate(requestSuccessURL);
            }
          } else if (response && !response.success && response.message) {
            showAlertDialouge("Error", response.message);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          dispatch(hideLoadingSpinner());
        }
      );
    }
  }

  function cancelBtnClicked() {
    showConfirmDialouge(
      "Are you sure?",
      "You have unsaved changes, are you sure that you want to cancel? All of your changes will be lost.",
      function () {
        navigate("/questionnaire-intake-form");
      }
    );
  }

  function shouldShowPromoCodeSection() {
    return hasPromoCode;
  }

  function getSubmitButtonVerbiageBasedOnCharge() {
    if (secondOpinionCharge > 0 && !isInsuranceEligible) {
      return "Submit & Enter Payment Method";
    }
    return "Submit";
  }

  useEffect(() => {
    setIsInsuranceEligible(insuranceEligibilityStatus == EligibilityStatus.Yes);
  }, [insuranceEligibilityStatus]);

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="bg-white border-radius-xlg px-4 px-md-5 py-4">
              <QuestionnaireTabs />
              <fieldset className="d-flex flex-wrap justify-content-between flex-column d-none111 min-vh">
                <legend className="fs-1 mb-3 mb-md-4 text-left">
                  Review & Submit
                </legend>
                <div className="row mb-5">
                  <div className="col-md-12 col-lg-6">
                    <div className="fs-3 mx-auto mt-0 mb-4 my-lg-5 col-lg-10 col-xl-8 inner-wrapper">
                      <p>
                        We will request your records from provider and notify
                        you when your second opinion report is ready.
                      </p>
                      <p>
                        If you uploaded records yourself, we will notify you
                        when your second opinion is ready.
                      </p>
                    </div>
                    <div className="text-primary text-center my-5 mt-auto">
                      Your second opinion report will give supporting
                      documentation regarding your specific case.
                    </div>
                  </div>
                  {secondOpinionCharge > 0 && !isInsuranceEligible && (
                    <SeeDentistPromo
                      initialCharge={secondOpinionCharge}
                      type={"Get a Second Opinion"}
                      validPromoCode={validPromoCode}
                      setValidPromoCode={setValidPromoCode}
                      secondOpinionSettingsId={secondOpinionSettingId}
                      hasPromoCode={hasPromoCode}
                      shouldShowPromoCodeSection={shouldShowPromoCodeSection}
                    ></SeeDentistPromo>
                  )}
                </div>
                {secondOpinionCharge > 0 && !isInsuranceEligible && (
                  <div className="d-table mx-auto">
                    <div className="form-text icon fs-3">
                      <i className="bi bi-exclamation-circle-fill text-gold"></i>{" "}
                      Your payment method will not be charged until your second
                      opinion report is delivered{" "}
                    </div>
                  </div>
                )}
                <div className="tab-focusable-background form-group text-center mt-3 mt-md-5 col-sm-12 button-wrapper">
                  <button
                    onClick={cancelBtnClicked}
                    className="btn btn-outline btn-rounded btn-lg px-5 mx-md-2 mt-3 mt-md-0 w-100 w-md-auto"
                  >
                    Cancel
                  </button>
                  <button
                    to="/questionnaire-billing-info"
                    onClick={saveSecondOpinionRequest}
                    className="btn btn-secondary btn-rounded btn-lg px-3 px-md-5 mt-3 mt-md-0 w-100 w-md-auto"
                  >
                    {getSubmitButtonVerbiageBasedOnCharge()}
                  </button>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuestionnaireReviewSubmitPage;
