import React from "react";

const MyDependentsHeader = (props) => {
  const { toggleAddFamilyMemberFormVisibility } = props;

  return (
    <>
      {/* Section -- 1 */}
      <div className="gth-main-header d-flex justify-content-between align-items-center mb-3">
        <h1 className="pe-2">
          My Dependents{" "}
          <span className="fs-3 text-primary d-block">
            Family Member's Profile
          </span>
        </h1>
        {/* <!-- <a href="#" className="btn btn-primary"><i className="bi bi-plus"></i> Add Member</a> --> */}
        <div className="add-member">
          <button
            className="tab-focusable-background btn btn-primary"
            onClick={toggleAddFamilyMemberFormVisibility}
          >
            <i className="bi bi-plus"></i> Add dependents
          </button>
        </div>
      </div>
    </>
  );
};

export default MyDependentsHeader;
