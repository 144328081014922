import {
  DateTimeFormats,
  KeyCodes,
  MobileDevices,
  SmartScanOralHealthStatus,
  smartScanCategory,
  MaxAllowdFileSizes,
  FileCompressQuality,
  GenderOptions,
  GroupNumMaxLength,
  CampaignStateSettingTypes,
  ReferralsNotificationTypesRedirectURL,
  SmartScanPerfectScore,
  DiscountTypes,
  DentalDotComUrlParamLinkType,
  SmartScanQuadrantType,
  UtmParams
} from "../constants";
import moment from "moment";
import $ from "jquery";
import { confirmAlert } from "react-confirm-alert"; // Import
import No_Preview_available from "../assets/images/No_preview_available.jpg";
import audio_file_icon from "../assets/images/audio_file_icon.png";
import compressed_file_icon from "../assets/images/compressed_file_icon.png";
import presentation_file_icon from "../assets/images/presentation_file_icon.png";
import spreadsheet_file_icon from "../assets/images/spreadsheet_file_icon.png";
import video_file_icon from "../assets/images/video_file_icon.png";
import pdf_file_icon from "../assets/images/pdf_file_icon.png";
import executable_file_icon from "../assets/images/executable_file_icon.png";
import word_processor_file_icon from "../assets/images/word_processor_file_icon.png";
import { loadStripe } from "@stripe/stripe-js";
import {
  auditConference_Ajax,
  addDentistDirectReferral_Ajax
} from "./requests";
import Compressor from "compressorjs";
import { getPatientInfo } from "./authManager";
import platform from "platform";
import {
  getApiUrl,
  getBaseUrl,
  getStripePublishableKey,
  isReferralFeatureEnabledForOrigin
} from "../ApplicationSettings";

export const supportedImageExtentions = [
  "ai",
  "bmp",
  "gif",
  "ico",
  "jpeg",
  "jpg",
  "png",
  "ps",
  "psd",
  "svg",
  "tif",
  "tiff",
  "webp",
  "jfif"
];
export const supportedDocExtensions = ["pdf", "slx", "doc", "docx"];

export const isPasswordValid = (value) => {
  var lowercaseLetterRex = /[a-z]/;
  var uppercaseLetterRex = /[A-Z]/;
  var digitRex = /\d/;
  var specialCharacterRex = /[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/;
  var lengthRex = /[A-Za-z\d !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}/;
  if (value.length == 0) {
    return true;
  } else if (
    lowercaseLetterRex.test(value) &&
    uppercaseLetterRex.test(value) &&
    digitRex.test(value) &&
    specialCharacterRex.test(value) &&
    lengthRex.test(value)
  ) {
    return true;
  } else {
    return false;
  }
};

export const isEmailAddressValid = (email) => {
  if (email.length == 0) {
    return true;
  } else if (
    /^((\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*)\s*[,]{0,1}\s*)+$/.test(
      email
    )
  ) {
    return true;
  } else {
    return false;
  }
};

export const isPhoneNumberValid = (value) => {
  if (value.length == 0) {
    return true;
  } else if (/^(\(\d{3}\))-(\d{3})-(\d{4})(\d*)/.test(value)) {
    return true;
  } else {
    return false;
  }
};

export const toLocalDate = (date) => {
  var dateUtc = moment.utc(date);
  return dateUtc.local().format(DateTimeFormats.MMM_DD_YYYY);
};

export const toLocalDateTime = (date) => {
  if (date) {
    var dateUtc = moment.utc(date);
    return dateUtc
      .local()
      .format(DateTimeFormats.MMM_DD_YYYY + " " + DateTimeFormats.HH_MM_A);
  }
};

export const getSupportedImageExtensions = () => {
  return supportedImageExtentions.join(", ");
};

export const isImageFile = (source) => {
  if (source) {
    var extension = source.split(".").pop().toLowerCase();

    if (extension) {
      return supportedImageExtentions.includes(extension);
    }
  }
  return false;
};

export const getSupportedDocExtensions = () => {
  return supportedDocExtensions.join(", ");
};

export const isValidDocFileForPatientUpload = (source) => {
  if (source) {
    var extension = source.split(".").pop().toLowerCase();

    if (extension) {
      return supportedDocExtensions.includes(extension);
    }
  }
  return false;
};

export const handleApiErrors = (err) => {};

export const getCurrentDate = (format, patientTimeZone) => {
  if (patientTimeZone) {
    var currentDate = moment.utc();
    var offsetToAdd = toTimezoneOffset(patientTimeZone);
    currentDate.hour(currentDate.hour() + offsetToAdd);
  } else {
    var currentDate = moment();
  }

  if (format) {
    currentDate = currentDate.format(format);
  }

  return currentDate;
};

export const isEqualToCurrentDate = (date) => {
  try {
    var date = moment(date);
    var currentDate = getCurrentDate();

    if (
      currentDate &&
      date &&
      currentDate.year() == date.year() &&
      currentDate.month() == date.month() &&
      currentDate.date() == date.date()
    ) {
      return true;
    }

    return false;
  } catch (ex) {}
};

export const formatDateTime = (date, format) => {
  try {
    var date = moment(date);
    return date.format(format);
  } catch (ex) {}
};

export const getIsoString = (date) => {
  try {
    var date = moment(date);
    return date.toISOString();
  } catch (ex) {}
};

export const formatDateTimeWithZone = (localDate, format) => {
  return (
    formatDateTime(localDate, format) +
    " (" +
    getTimezoneAbbreviation(String(localDate._d)) +
    ")"
  );
};

export const getDSTimezoneName = (offset) => {
  switch (offset) {
    case -4:
      return "EDT";
    case -5:
      return "CDT";
    case -6:
      return "MDT";
    case -7:
      return "PDT";
    case -8:
      return "ADT";
    case -9:
      return "HADT";
    case 0:
      return "GMT";
    default:
      return "UTC " + (offset >= 0 ? "+" : "") + offset;
  }

  return "";
};

export const toTimezoneName = (offset, isDST) => {
  if (isDST) {
    return getDSTimezoneName(offset);
  }

  switch (offset) {
    case -5:
      return "EST";
    case -6:
      return "CST";
    case -7:
      return "MST";
    case -8:
      return "PST";
    case -9:
      return "AST";
    case -10:
      return "HAST";
    case 0:
      return "GMT";
    default:
      return "UTC " + (offset >= 0 ? "+" : "") + offset;
  }

  return "";
};

export const toTimezoneOffset = (timezone) => {
  switch (timezone) {
    case "UTC -4":
      return -4;
    case "UTC -5":
      return -5;
    case "UTC -6":
      return -6;
    case "UTC -7":
      return -7;
    case "UTC -8":
      return -8;
    case "UTC -9":
      return -9;
    case "UTC -10":
      return -10;
    case "UTC +0":
      return 0;
  }
};

export const getTimezoneAbbreviation = (time) => {
  var index = time.indexOf("(");
  var lastIndex = time.indexOf(")");
  var prefixString = "";
  prefixString += time[index + 1];
  while (index < lastIndex) {
    index = time.indexOf(" ", ++index);
    if (index < 0) break;
    prefixString += time[++index];
  }
  return prefixString;
};

export const toLocal = (date) => {
  var date = moment.utc(date);
  return date.local();
};

export const getTimeSince = (date) => {
  date = moment.utc(date);
  var currentDate = getCurrentDate();

  var seconds = Math.floor((currentDate - date) / 1000);

  var interval = Math.floor(seconds / 31536000);

  if (interval > 1) {
    return interval + " years";
  } else if (interval == 1) {
    return interval + " year";
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return interval + " months";
  } else if (interval == 1) {
    return interval + " month";
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return interval + " days";
  } else if (interval == 1) {
    return interval + " day";
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return interval + " hours";
  } else if (interval == 1) {
    return interval + " hour";
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return interval + " minutes";
  } else if (interval == 1) {
    return interval + " minute";
  }
  interval = Math.floor(seconds);
  if (interval > 1) {
    return interval + " seconds";
  } else {
    return "1 second";
  }
};

export const getLocalTimezoneOffset = () => {
  try {
    return moment().utcOffset() / 60;
  } catch (ex) {}
};

export const removeBootstarpMediaPrintSelector = () => {
  return new Promise(function (resolve) {
    if (document && document.styleSheets) {
      for (var i = 0; i < document.styleSheets.length; i++) {
        var sheet = document.styleSheets[i];
        if (sheet && sheet.title && sheet.title === "bootstrap") {
          if (typeof sheet.cssRules !== "undefined") {
            for (var j = 0; j < sheet.cssRules.length; j++) {
              var rule = sheet.cssRules[j];
              if (
                rule &&
                rule.conditionText &&
                rule.conditionText == "print" &&
                rule.cssText.includes("*, ::before, ::after")
              ) {
                sheet.deleteRule(j);
                j--;
              }
            }
          }
        }
      }
    }
    resolve();
  });
};

export const getDiscountString = (promoCode) => {
  if (promoCode?.discountType == DiscountTypes.PercentageDiscount) {
    return promoCode.discount + "%";
  }
  return promoCode.discount + "$";
};

export const printContent = (content, header, title, cssTitles = []) => {
  removeBootstarpMediaPrintSelector().then(function () {
    title = title ? title : "Print preview";
    var w = window.open("", "Print Preview", 'fullscreen="yes"');
    if (w) {
      w.document.write(
        '<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">'
      );
      w.document.write("<html>");
      w.document.write("<head>");
      w.document.write("<title>" + title + "</title>");
      w.document.write("<meta charset='utf-8'>");

      var headChlidElements = $("head").find("link[title!=bootstrap]");
      var headElement = document.createElement("head");

      for (var i = 0; i < headChlidElements.length; i++) {
        $(headChlidElements[i]).clone().appendTo($(headElement));
      }

      w.document.write($(headElement).html());

      w.document.write("<style>");
      for (var i = 0; i < document.styleSheets.length; i++) {
        var sheet = document.styleSheets[i];
        if (
          sheet &&
          sheet.title &&
          (sheet.title === "bootstrap" || cssTitles.includes(sheet.title))
        ) {
          if (typeof sheet.cssRules !== "undefined") {
            for (var j = 0; j < sheet.cssRules.length; j++) {
              var rule = sheet.cssRules[j];
              if (rule && rule.cssText) {
                w.document.write(rule.cssText);
              }
            }
          }
        }
      }
      w.document.write("</style>");

      w.document.write("</head>");
      w.document.write("<body>");
      w.document.write('<div style="background: white;">');
      w.document.write(header);
      w.document.write(content);
      w.document.write("</div>");
      w.document.close();

      var is_chrome = Boolean(w.chrome);
      if (is_chrome) {
        w.onload = function () {
          // wait until all resources loaded
          w.focus(); // necessary for IE >= 10
          w.print();
        };
      } else {
        setTimeout(function () {
          w.focus(); // necessary for IE >= 10
          w.print(); // change window to mywindow
        }, 500);
      }
    }
  });
};

export const onImageError = (event) => {
  if (event && event.currentTarget) {
    event.currentTarget.src = No_Preview_available;
  }
};

export const groupBy = function (field) {
  let groupedArr = [];
  this.forEach(function (e) {
    //look for an existent group
    let group = groupedArr.find((g) => g["field"] === e[field]);
    if (group == undefined) {
      //add new group if it doesn't exist
      group = { field: e[field], groupList: [] };
      groupedArr.push(group);
    }

    //add the element to the group
    group.groupList.push(e);
  });

  return groupedArr;
};

export const openUrlInNewTab = (url) => {
  if (url) {
    window.open(url, "_blank");
  }
};

export const createVideoCallUrl = (alias, params) => {
  if (alias) {
    var url = getApiUrl() + "/Conference?alias=" + alias;
    if (params) {
      url = url + "&" + $.param(params);
    }

    return url;
  }
};

export const redirectToCheckout = (sessionId, stripeAccount) => {
  try {
    loadStripe(getStripePublishableKey(), { stripeAccount }).then(
      (stripePromise) => {
        stripePromise.redirectToCheckout({
          sessionId: sessionId
        });
      }
    );
  } catch (ex) {}
};

export const queryStringToJSON = () => {
  try {
    var pairs = window.location.search.slice(1).split("&");

    var result = {};
    pairs.forEach(function (pair) {
      if (pair != "") {
        pair = pair.split(/=(.*)/);
        result[pair[0].toLowerCase()] = decodeURIComponent(
          (pair[1] + "").replace(/\+/g, "%20")
        );
      }
    });

    return JSON.parse(JSON.stringify(result));
  } catch (ex) {}
};

export const queryStringToJSONWithoutDecode = () => {
  try {
    var pairs = window.location.search.slice(1).split("&");
    var result = {};
    pairs.forEach(function (pair) {
      pair = pair.split(/=(.*)/);
      result[pair[0].toLowerCase()] = pair[1] + "";
    });
    return JSON.parse(JSON.stringify(result));
  } catch (ex) {}
};

export const getDefaultPicPath = (element) => {
  if (element && element.src) {
    var source = element.src;
    var extension = source.split(".").pop();

    switch (extension) {
      case "ai":
      case "bmp":
      case "gif":
      case "ico":
      case "jpeg":
      case "jpg":
      case "png":
      case "ps":
      case "psd":
      case "svg":
      case "tif":
      case "tiff":
        return No_Preview_available;
      case "aif":
      case "cda":
      case "mid":
      case "midi":
      case "mp3":
      case "mpa":
      case "ogg":
      case "wav":
      case "wma":
      case "wpl":
        return audio_file_icon;
      case ".3g2":
      case "3gp":
      case "avi":
      case "flv":
      case "h264":
      case "m4v":
      case "mkv":
      case "mov":
      case "mp4":
      case "mpg":
      case "mpeg":
      case "rm":
      case "swf":
      case "vob":
      case "wmv":
        return video_file_icon;
      case "doc":
      case "docx":
      case "odt":
      case "rtf":
      case "tex":
      case "txt":
      case "wks":
      case "wps":
      case "wpd":
        return word_processor_file_icon;
      case "pdf":
        return pdf_file_icon;
      case "ods":
      case "xlr":
      case "xls":
      case "xlsx":
        return spreadsheet_file_icon;
      case "key":
      case "odp":
      case "pps":
      case "ppt":
      case "pptx":
      case ".csv":
        return presentation_file_icon;
      case "7z":
      case "arj":
      case "deb":
      case "pkg":
      case "rar":
      case "rpm":
      case "gz":
      case "z":
      case "zip":
        return compressed_file_icon;
      case "apk":
      case "bat":
      case "bin":
      case "cgi":
      case "pl":
      case "com":
      case "exe":
      case "gadget":
      case "jar":
      case "py":
      case "wsf":
        return executable_file_icon;
    }
  }

  return No_Preview_available;
};

function confirmDialogKeyPressHandler(event, action) {
  if (event && event.keyCode == KeyCodes.ENTER && action) {
    action();
  }
}

export const showAlertDialouge = (
  title,
  message,
  onConfirm,
  buttonText = "OK"
) => {
  const options = {
    title: title ? title : "Message",
    message: message,
    childrenElement: () => <div />,
    customUI: ({ onClose }) => {
      var okBtnAction = function () {
        try {
          if (onConfirm) {
            onConfirm();
          }

          if (onClose) {
            onClose();
          }
        } catch (ex) {}
      };

      return (
        <div className="custom-ui text-break">
          <h1>{title}</h1>
          {message}
          <div className="react-confirm-alert-button-group">
            <button
              autoFocus
              className="tab-focusable-background alert-dialog-ok-button"
              onKeyDown={(event) => {
                confirmDialogKeyPressHandler(event, okBtnAction);
              }}
              onClick={() => {
                okBtnAction();
              }}
            >
              {buttonText}
            </button>
          </div>
        </div>
      );
    },
    closeOnEscape: false,
    closeOnClickOutside: false,
    keyCodeForClose: [],
    willUnmount: () => {},
    afterClose: () => {},
    onClickOutside: () => {},
    onKeypressEscape: () => {},
    overlayClassName: "alert-dialog-overlay"
  };

  confirmAlert(options);
};

export const showConfirmDialouge = (title, message, onConfirm, onCancel) => {
  const options = {
    title: title ? title : "Are you sure?",
    message: message,
    childrenElement: () => <div />,
    customUI: ({ onClose }) => {
      var yesBtnAction = function () {
        try {
          if (onConfirm) {
            onConfirm();
          }

          if (onClose) {
            onClose();
          }
        } catch (ex) {}
      };

      var noBtnAction = function () {
        try {
          if (onCancel) {
            onCancel();
          }

          if (onClose) {
            onClose();
          }
        } catch (ex) {}
      };

      return (
        <div className="custom-ui text-break ">
          <h1>{title}</h1>
          {message}
          <div className="tab-focusable-background react-confirm-alert-button-group">
            <button
              tabIndex={0}
              autoFocus
              id="#yesbtn"
              className="alert-dialog-yes-button"
              onKeyDown={(event) => {
                confirmDialogKeyPressHandler(event, yesBtnAction);
              }}
              onClick={() => {
                yesBtnAction();
              }}
            >
              Yes
            </button>
            <button
              tabIndex={0}
              className="alert-dialog-no-button"
              onClick={() => {
                noBtnAction();
              }}
            >
              No
            </button>
          </div>
        </div>
      );
    },
    closeOnEscape: false,
    closeOnClickOutside: false,
    keyCodeForClose: [],
    willUnmount: () => {},
    afterClose: () => {},
    onClickOutside: () => {},
    onKeypressEscape: () => {},
    overlayClassName: "alert-dialog-overlay"
  };

  confirmAlert(options);
};

export const displayCopyToClipboardAlert = ({
  message,
  textToCopy,
  successMessage,
  errorMessage,
  successCallback,
  errorCallback
}) => {
  showAlertDialouge(
    "Message",
    message,
    function () {
      copyToClipboard(
        textToCopy,
        function () {
          showAlertDialouge("Message", successMessage, successCallback);
        },
        function () {
          showAlertDialouge("Error", errorMessage, errorCallback);
        }
      );
    },
    "Copy"
  );
};

export const handleBeforeUnload = (event) => {
  //Additional development required
  event.preventDefault();
  window.location.reload(false);
};

export const hasExceededMaxAllowedSize = (file, maxAllowedSizeMB) => {
  var hasExceededMaxSize = false;
  var maxAllowedFileSizeInMB = "";

  if (file) {
    var imageMaxSizeInBytes =
      (maxAllowedSizeMB
        ? maxAllowedSizeMB
        : MaxAllowdFileSizes.IMAGE_MAX_SIZE_IN_MB) *
      (1024 * 1024);
    var fileMaxSizeInBytes =
      (maxAllowedSizeMB
        ? maxAllowedSizeMB
        : MaxAllowdFileSizes.FILE_MAX_SIZE_IN_MB) *
      (1024 * 1024);
    var isImage = isImageFile(file.name);

    if (
      (isImage && file.size > imageMaxSizeInBytes) ||
      file.size > fileMaxSizeInBytes
    ) {
      hasExceededMaxSize = true;

      if (isImage) {
        maxAllowedFileSizeInMB = maxAllowedSizeMB
          ? maxAllowedSizeMB
          : MaxAllowdFileSizes.IMAGE_MAX_SIZE_IN_MB;
      } else {
        maxAllowedFileSizeInMB = maxAllowedSizeMB
          ? maxAllowedSizeMB
          : MaxAllowdFileSizes.FILE_MAX_SIZE_IN_MB;
      }
    }
  }

  return {
    hasExceededMaxSize: hasExceededMaxSize,
    maxAllowedFileSizeInMB: maxAllowedFileSizeInMB
  };
};

export const validateFileMaxSize = (files, maxAllowedFileSizeInMB) => {
  var errorMsg = "";
  if (files && Array.isArray(files) && files.length) {
    for (var i = 0; i < files.length; i++) {
      var returnObj = hasExceededMaxAllowedSize(
        files[i],
        maxAllowedFileSizeInMB
      );

      if (returnObj.hasExceededMaxSize) {
        errorMsg +=
          files[i].name +
          " has exceeded the maximum allowed size. Please upload less than " +
          returnObj.maxAllowedFileSizeInMB +
          " MB";
      }
    }
  }

  return errorMsg;
};

export const isFutureDate = (date) => {
  if (moment(date).diff(moment()) < 0) {
    return false;
  }
  return true;
};

export const isPastDate = (date) => {
  if (moment(date).diff(moment()) > 0) {
    return false;
  }
  return true;
};

export const isValidScheduledDate = (date) => {
  if (moment(date, "YYYY-MM-DD hh:mm A").diff(moment(), "minutes") >= 59) {
    return true;
  }
  return false;
};

export const isValidDate = (date) => {
  var dateTime = new Date(date);
  return !(!date || !dateTime.getTime());
};

export const isPatientDateOfBirthValid = (date) => {
  if (date) {
    var errorMessage = "";

    try {
      if (isValidDate(date)) {
        var isPatientFutureDate = isFutureDate(date);
        var currentYear = getCurrentDate("YYYY");
        var yearOfBirth = moment(date).format("YYYY");
        var patientMaxAge = 200;

        if (isPatientFutureDate) {
          errorMessage =
            "Patient date of birth is invalid. You cannot enter a future date.";
        } else if (currentYear - yearOfBirth > patientMaxAge)
          errorMessage =
            "Patient date of birth is invalid. Please enter a year within the past 200 years.";
      } else {
        errorMessage = "Please enter a valid date";
      }
    } catch (ex) {
      return {
        valid: false
      };
    }

    if (errorMessage) {
      return {
        valid: false,
        errorMessage
      };
    } else {
      return {
        valid: true
      };
    }
  } else {
    return {
      valid: false
    };
  }
};

export const getMinuteOptions = () => {
  var arr = [];

  for (var i = 0; i <= 59; i++) {
    arr.push(
      <option key={i} value={i}>
        {(i <= 9 ? "0" : "") + i}
      </option>
    );
  }

  return arr;
};

export const getHourOptions = () => {
  var arr = [];

  for (var i = 1; i <= 12; i++) {
    arr.push(
      <option key={i} value={i}>
        {(i <= 9 ? "0" : "") + i}
      </option>
    );
  }

  return arr;
};

export const getLocationName = (pathName) => {
  return "/" + pathName.split("/")[1];
};

export const shouldExcludeNavbar = (location) => {
  let pagesToExclude = [
    "/prescription-print",
    "/notes-print",
    "/form-print",
    "/visit-history-print",
    "/patient-record-request",
    "/wellness-score-print"
  ];
  let pathName = getLocationName(location.pathname);

  return pagesToExclude.includes(pathName);
};

export const shouldShowBrandLogoInPublicRoute = (pathname) => {
  let routes = [
    "/smart-scan-works-no-auth",
    "/smart-scan-disclaimer-no-auth",
    "/smart-scan-photo-options-no-auth",
    "/smart-scan-instruction-no-auth",
    "/smart-scan-lower-arch-no-auth",
    "/smart-scan-lower-arch-confirm-no-auth",
    "/smart-scan-lower-lip-no-auth",
    "/smart-scan-lower-lip-confirm-no-auth",
    "/smart-scan-upper-arch-no-auth",
    "/smart-scan-upper-arch-confirm-no-auth",
    "/smart-scan-upper-lip-no-auth",
    "/smart-scan-upper-lip-confirm-no-auth",
    "/smart-scan-bite-no-auth",
    "/smart-scan-bite-confirm-no-auth",
    "/smart-scan-pose-no-auth"
  ];

  return routes.includes(pathname);
};

export const isEmptyArray = (data) => {
  return !(data && Array.isArray(data) && data.length > 0);
};

export const GetDSTimezoneFullName = (offset) => {
  switch (offset) {
    case -4:
      return "Eastern Daylight Time";
    case -5:
      return "Central Daylight Time";
    case -6:
      return "Mountain Daylight Time";
    case -7:
      return "Pacific Daylight Time";
    case -8:
      return "Alaska Daylight Time";
    case -9:
      return "Hawaii-Aleutian Daylight Time";
    case 0:
      return "Greenwich Standard Time";
  }

  return "";
};

export const GetTimezoneFullName = (offset, isDST) => {
  if (isDST) {
    return GetDSTimezoneFullName(offset);
  }

  switch (offset) {
    case -5:
      return "Eastern Standard Time";
    case -6:
      return "Central Standard Time";
    case -7:
      return "Mountain Standard Time";
    case -8:
      return "Pacific Standard Time";
    case -9:
      return "Alaska Standard Time";
    case -10:
      return "Hawaiian Standard Time";
    case 0:
      return "Greenwich Standard Time";
  }
  return "";
};

export const convertKBFileSizeToMB = (fileSize) => {
  if (fileSize) {
    return fileSize / (1024 * 1024);
  }

  return 0;
};

export const isPDFfile = (source) => {
  if (source) {
    var extension = source.split(".").pop().toLowerCase();

    if (extension) {
      return extension === "pdf";
    }
  }
  return false;
};

export const getSubDomain = () => {
  if (window && window.location && window.location.hostname) {
    var currentPath = window.location.hostname;
    return currentPath.split(".")[0];
  }
};

export const getNameInitials = (patientName) => {
  if (patientName) {
    var patientNameInitialLetter = patientName
      .trim()
      .split(" ")
      .map((item) => {
        return item[0];
      });

    return (
      patientNameInitialLetter[0] + patientNameInitialLetter.pop()
    ).toUpperCase();
  }
};

export function validateDateOfBirth(
  dateOfBirth,
  excludeDependentPatientAgeLimitCheck = true
) {
  if (dateOfBirth) {
    const date = moment(dateOfBirth, "MM/DD/YYYY", true);

    if (date.isValid()) {
      const age = moment().diff(date, "years");

      if (age < 0) {
        return "Please enter a valid date. You cannot enter a future date.";
      }

      if (age < 18 && !excludeDependentPatientAgeLimitCheck) {
        return "A parent or legal guardian must create an account and add you as a dependent.";
      }

      if (age > 200) {
        return "Please enter a year within the past 200 years.";
      }

      return null;
    } else {
      return "Please enter a valid date.";
    }
  }
}

export function validateDate(date) {
  if (!isValidDate(date)) {
    return "Please enter a valid date";
  }
  return null;
}

export function validateScheduleAppointmentDate(date) {
  if (!isValidDate(date)) {
    return "Please enter a valid date";
  } else if (moment(date).diff(moment().format("MM-DD-YYYY")) < 0) {
    return "Please select a date from today or a future date.";
  }
  return null;
}

export function validatePhoneNumber(phoneNo) {
  if (phoneNo) {
    if (isPhoneNumberValid(phoneNo)) {
      return true;
    } else {
      return "Please enter a valid phone number.";
    }
  }
}

export function validatePassword(password) {
  if (isPasswordValid(password)) {
    return true;
  } else {
    return false;
  }
}

export function validateGroupNo(value) {
  if (value.length <= GroupNumMaxLength) {
    return true;
  } else {
    return (
      "The group number should not be more than " +
      GroupNumMaxLength +
      " characters"
    );
  }
}

export function getRegExpFromStrPattern(pattern) {
  return pattern ? new RegExp(pattern) : null;
}

export function validateSubscriberID(value, validator = {}) {
  let validatorRegex = getRegExpFromStrPattern(validator.validatorRegex);
  let validationMessage = validator.validationMessage
    ? validator.validationMessage
    : null;

  if (value && validatorRegex && validationMessage) {
    return validatorRegex.test(value) ? null : validationMessage;
  }

  return null;
}

export function getBrowserName() {
  let userAgent = navigator.userAgent;
  let browserName;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = "Chrome";
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = "Firefox";
  } else if (userAgent.match(/safari/i)) {
    browserName = "Safari";
  } else if (userAgent.match(/opr\//i)) {
    browserName = "Opera";
  } else if (userAgent.match(/edg/i)) {
    browserName = "Edge";
  } else {
    browserName = "No browser detection";
  }

  return browserName;
}

export function getOS() {
  var userAgent = window.navigator.userAgent,
    platform =
      window.navigator?.userAgentData?.platform || window.navigator.platform,
    macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
    windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
    iosPlatforms = ["iPhone", "iPad", "iPod"],
    os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = "Mac OS";
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = "iOS";
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = "Windows";
  } else if (/Android/.test(userAgent)) {
    os = "Android";
  } else if (/Linux/.test(platform)) {
    os = "Linux";
  }

  return os;
}

export function checkBrowserVideoCallEligibility() {
  var os = getOS();
  var browser = getBrowserName();
  if (os == "iOS" && browser == "Chrome") {
    return {
      status: false,
      message:
        "Due to video and photo compatibility, iPhone users are required to use Safari for Dental.com. Please re-open Dental.com in Safari or copy the URL on this page and paste it into Safari."
    };
  }
  return {
    status: true
  };
}

export function auditConference(
  consultationId,
  eventName,
  pageName,
  patId = 0
) {
  let platformDetails = platform.description;

  let dataJSON = {
    pageName: pageName,
    EventName: eventName,
    browserString: platformDetails,
    consultationId: consultationId,
    siteName: getBaseUrl(),
    referer: document.referrer ? document.referrer : window.location?.href?.split('?')[0],
    patId: patId
  };

  auditConference_Ajax(JSON.stringify(dataJSON));
}

export function executeDentistDirectReferralProcess(
  linkType,
  encryptedProviderId,
  patId
) {
  return new Promise(function (resolve) {
    if (
      linkType == DentalDotComUrlParamLinkType.DentistDirectReferralUrl &&
      patId &&
      encryptedProviderId
    ) {
      const dataJSON = {
        encryptedProviderId: encryptedProviderId,
        patId: patId
      };
      addDentistDirectReferral_Ajax(
        JSON.stringify(dataJSON),
        function () {
          resolve();
        },
        function () {
          resolve();
        }
      );
    } else {
      resolve();
    }
  });
}

export function getMobileOperatingSystem() {
  let userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (/android/i.test(userAgent)) {
    return MobileDevices.Android;
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return MobileDevices.iOS;
  }
  return MobileDevices.None;
}

export function isIosDevice(mobileDevice) {
  try {
    if (mobileDevice) {
      return mobileDevice == MobileDevices.iOS;
    } else {
      return getMobileOperatingSystem() == MobileDevices.iOS;
    }
  } catch (exception) {}
}

export function isAndroidDevice(mobileDevice) {
  try {
    if (mobileDevice) {
      return mobileDevice == MobileDevices.Android;
    } else {
      return getMobileOperatingSystem() == MobileDevices.Android;
    }
  } catch (exception) {}
}

export function getSmartScanImageCategoryName(category) {
  switch (category) {
    case smartScanCategory.Bite:
      return "Upper";
    case smartScanCategory.UpperArch:
      return "Right Side";
    case smartScanCategory.LowerArch:
      return "Left Side";
    case smartScanCategory.UpperLip:
      return "Front";
    case smartScanCategory.LowerLip:
      return "Lower";
    default:
      return "";
  }
}

export function isSmartScanScorePerfect(score) {
  return score == SmartScanPerfectScore;
}

export function getSmartScanImageObjectNameForState(category) {
  switch (category) {
    case smartScanCategory.Bite:
      return "upper";
    case smartScanCategory.UpperArch:
      return "rightSide";
    case smartScanCategory.LowerArch:
      return "leftSide";
    case smartScanCategory.UpperLip:
      return "front";
    case smartScanCategory.LowerLip:
      return "lower";
    default:
      return "";
  }
}

export function getSmartScanStepNo(category) {
  switch (category) {
    case smartScanCategory.UpperArch:
      return 1;
    case smartScanCategory.LowerArch:
      return 2;
    case smartScanCategory.UpperLip:
      return 3;
    case smartScanCategory.LowerLip:
      return 4;
    case smartScanCategory.Bite:
      return 5;
    default:
      return 0;
  }
}

export function getCSSClassToStopScrollingInSmartScanPosePageInMobileDevice() {
  if (isAndroidDevice()) {
    return "android-device";
  } else if (isIosDevice()) {
    return "iphone-device";
  }
}

export function getSmartScanReportAccrodionColorClass(status) {
  if (status == SmartScanOralHealthStatus.Poor) {
    return "accordion-danger";
  } else if (status == SmartScanOralHealthStatus.Good) {
    return "accordion-success";
  } else if (status == SmartScanOralHealthStatus.NeedsImprovement) {
    return "accordion-gold";
  } else if (status == SmartScanOralHealthStatus.NonObservable) {
    return "accordion-non-observable";
  }

  return "";
}

export function validateImage(file, maxAllowedSizeInMB = 0) {
  let errMsg = "";

  if (maxAllowedSizeInMB == 0) {
    maxAllowedSizeInMB = MaxAllowdFileSizes.IMAGE_MAX_SIZE_IN_MB_5;
  }

  if (file && maxAllowedSizeInMB > 0) {
    let fileName = file.name.toLowerCase();
    let isImageTypeFile = isImageFile(fileName);
    let fileSizeErrMsg = validateFileMaxSize([file], maxAllowedSizeInMB);

    if (!isImageTypeFile) {
      errMsg = "File type not supported";
    } else if (fileSizeErrMsg) {
      errMsg = fileSizeErrMsg;
    }
  }

  return errMsg;
}

export async function getCompressedFile(file) {
  try {
    if (file) {
      let fileName = file.name;
      let compressedFile = await compressFile(
        file,
        FileCompressQuality.Image_Quality
      );
      if (compressedFile) {
        return new File([compressedFile], fileName, {
          type: compressedFile.type
        });
      }
    }
  } catch (ex) {}

  return undefined;
}

function compressFile(image, quality) {
  return new Promise(function (resolve, reject) {
    return new Compressor(image, {
      quality: quality,
      success: (res) => {
        resolve(res);
      },
      error: () => {
        reject(undefined);
      }
    });
  });
}

export function getCampaignStatesOfType(type, states) {
  let filteredStates = [];
  states.forEach((state) => {
    if (state.type === type) {
      filteredStates.push(state);
    }
  });
  return filteredStates;
}

export function getSeeADentistNowButtonText() {
  return "See a Dentist Now";
}

export function shouldSelectCurrentPatientByDefault(
  patientAndFamilyMemberList
) {
  return (
    getPatientInfo() &&
    patientAndFamilyMemberList &&
    Array.isArray(patientAndFamilyMemberList) &&
    patientAndFamilyMemberList.length == 1
  );
}

export function getDefaultSelectedPatientId(patientAndFamilyMemberList) {
  if (shouldSelectCurrentPatientByDefault(patientAndFamilyMemberList)) {
    return getPatientInfo().patid;
  }
  return 0;
}

export function getGenderText(gender) {
  if (!gender) return "";

  switch (gender) {
    case GenderOptions.Male:
      return "Male";
    case GenderOptions.Female:
      return "Female";
    case GenderOptions.Unknown:
      return "Other";
    case GenderOptions.Other:
      return "Other";
    default:
      return "";
  }
}

export const ConvertFileToBase64String = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export function arrayBufferToBlob(buffer, fileName, mimeType) {
  let blob = new Blob([buffer], { type: mimeType });
  return new File([blob], fileName);
}

export function fileToArrayBuffer(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
  });
}

export const formatCurrency = (currency) => {
  if (currency) {
    return "$" + currency;
  }

  return "";
};

export const formatVersion = (version) => {
  if (version) {
    return "v" + version;
  }

  return "v1";
};

export const isCampaignShareStateAvailable = (data) => {
  if (data) {
    let sharedStates = getCampaignStatesOfType(
      CampaignStateSettingTypes.Shared,
      data.campaignStates
    );
    if (!isEmptyArray(sharedStates) && data.isRecordShareConsentEnabled) {
      return sharedStates;
    }
  }
};

export const initializeAdditionalChangesInFormIoFormDuringRender = () => {
  changeSubmitButtonTextAndStyle();
  addEventListenerInFormIo();
};

export const changeSubmitButtonTextAndStyle = () => {
  let submitButtonDiv = document.querySelectorAll(
    ".formio-component-submit"
  )[0];

  if (submitButtonDiv) {
    submitButtonDiv?.classList?.add("text-center");
  }

  let submitButton = document.querySelectorAll(
    ".formio-component-submit button"
  )[0];

  if (submitButton) {
    submitButton.innerHTML = "Submit and Next";
    submitButton?.classList?.add("tab-focusable-background btn-secondary");
  }
};

export const initializeAdditionalChangesInFormIoFormDuringCreate = (
  elementId,
  formData
) => {
  setCustomValueInFormIoFields(elementId, formData);
};

export const setCustomValueInFormIoFields = (elementId, formData) => {
  if (!elementId || !formData) {
    return;
  }

  if (
    formData?.signature &&
    document.querySelectorAll("#" + elementId + " #signaturePreview")[0]
  ) {
    let element = "#" + elementId + " #signaturePreview";
    $(element).val(formData?.signature);
  }
};

export const addEventListenerInFormIo = () => {
  let signaturePreviewDiv = document.querySelectorAll("#signaturePreview")[0];
  let signatureDiv = document.querySelectorAll(".eTypeSignatureFormIo")[0];

  if (signatureDiv && signaturePreviewDiv) {
    document
      .getElementsByClassName("eTypeSignatureFormIo")[0]
      .addEventListener("keyup", setValueOfPreviewSignature, true);
  }
};

export const setValueOfPreviewSignature = () => {
  if (
    document.getElementById("signaturePreview") &&
    document.getElementsByName("data[signature]")
  ) {
    document.getElementById("signaturePreview").value =
      document.getElementsByName("data[signature]")[0].value;
  }
};

export const getCarrierIdFromInsurance = (insuranceInfo, insuranceCarrier) => {
  if (!insuranceCarrier.length) {
    return 0;
  }
  const carrier = insuranceCarrier?.find(
    (item) =>
      item.name === insuranceInfo?.carrierName &&
      item.code === insuranceInfo?.carrierCode
  );
  return carrier?.id;
};

export const checkForPaymentCompletionStatus = (paymentsuccess) => {
  let title = getPaymentCompletionDialougeTitle(paymentsuccess);
  let message = getPaymentCompletionMessage(paymentsuccess);

  if (title && message) {
    showAlertDialouge(title, message);
  }
};

export const getPaymentCompletionMessage = (status) => {
  if (status == "true") return "Payment has been completed successfully.";
  else if (status == "false") return "Failed to complete the payment.";
  else return "";
};

export const getPaymentCompletionDialougeTitle = (status) => {
  if (status == "true") return "Message";
  else if (status == "false") return "Error";
  else return "";
};

export const copyToClipboard = (textToCopy, successCallback, errorCallback) => {
  if (navigator?.clipboard?.writeText && textToCopy) {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        if (successCallback) {
          successCallback();
        }
      })
      .catch(() => {
        if (errorCallback) {
          errorCallback();
        }
      });
  }
};

export const isReferralNotificationType = (type) => {
  return ReferralsNotificationTypesRedirectURL?.some(
    ([notificationType]) => notificationType === type
  );
};

export const isFeatureNotificationDisabledForOrigin = (type) => {
  return (
    !isReferralFeatureEnabledForOrigin() && isReferralNotificationType(type)
  );
};

export const extractCustomLinkQueryParams = () => {
  let paramsWithoutDecode = queryStringToJSONWithoutDecode();

  if (paramsWithoutDecode.linktype && paramsWithoutDecode.data) {
    return {
      linkType: paramsWithoutDecode.linktype,
      version: 0,
      dataString: paramsWithoutDecode.data
    };
  } else if (paramsWithoutDecode.v && paramsWithoutDecode.data) {
    return {
      version: parseInt(paramsWithoutDecode.v),
      dataString: paramsWithoutDecode.data
    };
  }

  return {
    linkType: 0,
    version: 0,
    dataString: null
  };
};

export const getSmartScanQuadrantTypeName = (quadrantNumber) => {
  switch (quadrantNumber) {
    case SmartScanQuadrantType.UpperLeft:
      return "UL";
    case SmartScanQuadrantType.UpperRight:
      return "UR";
    case SmartScanQuadrantType.LowerLeft:
      return "LL";
    case SmartScanQuadrantType.LowerRight:
      return "LR";
  }

  return "";
};

export const getAddressFromGooglePlaceObject = (place) => {

  if (!place.address_components) {
    return null;
  }

  let address = {
      country: "",
      state: "",
      city: "",
      zip: ""
  };

  for (const component of place.address_components) {
    const componentType = component.types[0];

    switch (componentType) {
      case "postal_code": {
        address.zip = component.long_name;
        break;
      }
      case "administrative_area_level_1": {
        address.state = component.short_name;
        break;
      }
        
      case "sublocality_level_1": 
      case "locality": {
        address.city = component.long_name;
        break;
      }
        
      case "country":
        address.country = component.short_name;
        break;
    }
  }
  
  return address;
}

export function handleRemoveProfilePhoto(confirmCallback) {
  return function (event) {
    event.preventDefault();
    showConfirmDialouge(
      "Are you sure?",
      "You are about to remove your profile photo.",
      confirmCallback
    );
  };
}

export const getUtmParams = () => {
  const referrerUrl = document.referrer || "";

  if (referrerUrl) {
    const url = new URL(referrerUrl);
    const queryParams = new URLSearchParams(url.search);
    const utmParams = {
      source: queryParams.get(UtmParams.Source) || "",
      medium: queryParams.get(UtmParams.Medium) || "",
      campaign: queryParams.get(UtmParams.Campaign) || "",
      term: queryParams.get(UtmParams.Term) || "",
      content: queryParams.get(UtmParams.Content) || ""
    };

    if (
      utmParams.source ||
      utmParams.medium ||
      utmParams.campaign ||
      utmParams.term ||
      utmParams.content
    ) {
      return utmParams;
    } else {
      return null;
    }
  }
};

export const fetchBlob = (url, callback) => {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      callback(blob);
    });
};

export const convertURLToLink = (message) => {
  if (message) {
    let linkRegex = /(https?:\/\/[^\s]+|www\.[^\s]+)/gi;

    return message.replace(linkRegex, (match) => {
      let url = match.toLowerCase().startsWith("www.") ? `//${match}` : match;

      if (url.endsWith(".")) {
        url = url.slice(0, url.length - 1);
      }

      return `<a class="message_url" href="${url}" target="_blank" rel="noopener noreferrer">${match}</a>`;
    });
  }
};
