import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  getSecondOpinion_Ajax,
  sendRecordRequestNotification_Ajax,
  updateSecondOpinionRecordRequest_Ajax
} from "../../helpers/requests";
import {
  handleApiErrors,
  isEmailAddressValid,
  isPhoneNumberValid,
  showAlertDialouge
} from "../../helpers/utils";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import AlertModal from "./AlertModal";

import InputMask from "react-input-mask";

const QuestionnaireUpdateRecordPage = (props) => {
  const { secondOpinionId } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [recordRequestData, setRecordRequestData] = useState(null);
  const [
    isSecondOpinionFetchApiInProgress,
    setIsSecondOpinionFetchApiInProgress
  ] = useState(true);
  const [inputs, setInputs] = useState({
    dentistName: "",
    email: "",
    phone: ""
  });

  const { dentistName, email, phone } = inputs;

  useEffect(() => {
    if (recordRequestData) {
      setInputs({
        dentistName: recordRequestData.dentistName,
        email: recordRequestData.email,
        phone: recordRequestData.phone
      });
    }
  }, [recordRequestData]);

  useEffect(() => {
    if (secondOpinionId) {
      getSecondOpinion();
    }
  }, [secondOpinionId]);

  function getSecondOpinion() {
    if (secondOpinionId) {
      var params = {
        includeRecordRequestData: true
      };

      dispatch(showLoadingSpinner());
      getSecondOpinion_Ajax(
        secondOpinionId,
        params,
        function (response) {
          dispatch(hideLoadingSpinner());
          setIsSecondOpinionFetchApiInProgress(false);

          if (
            response &&
            response.success &&
            response.data &&
            response.data.secondOpinion &&
            response.data.secondOpinion.secondOpinionRecordRequestData
          ) {
            setRecordRequestData(
              response.data.secondOpinion.secondOpinionRecordRequestData
            );
          } else if (response && !response.success && response.message) {
            setRecordRequestData(null);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          setIsSecondOpinionFetchApiInProgress(false);
          handleApiErrors(err);
        }
      );
    }
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  }

  function isInputValid() {
    var errorMessage = "";

    if (!dentistName) {
      errorMessage = "Please enter dentist name.";
    } else if (!email || !isEmailAddressValid(email)) {
      errorMessage = "Please enter a valid email address.";
    } else if (phone && !isPhoneNumberValid(phone)) {
      errorMessage = "Please enter a valid phone number.";
    }

    if (errorMessage) {
      showAlertDialouge("Error", errorMessage);
    } else {
      return true;
    }
  }

  function isRequiredFieldsEmpty() {
    return !(dentistName && email && isEmailAddressValid(email));
  }

  function isUpdateButtonEnable() {
    if (inputs) {
      return (
        (inputs.dentistName != recordRequestData.dentistName ||
          inputs.email != recordRequestData.email ||
          inputs.phone != recordRequestData.phone) &&
        !isRequiredFieldsEmpty()
      );
    }
  }

  function updateBtnClicked() {
    if (isInputValid()) {
      var recordRequestVM = {
        dentistName,
        email,
        phone
      };

      var formData = new FormData();
      formData.append(
        "recordRequestViewModel",
        JSON.stringify(recordRequestVM)
      );

      dispatch(showLoadingSpinner());
      updateSecondOpinionRecordRequest_Ajax(
        secondOpinionId,
        formData,
        function (response) {
          dispatch(hideLoadingSpinner());

          if (response && response.success) {
            showAlertDialouge("Success", response.message, function () {
              navigate("/dashboard");
            });
          } else if (response && !response.success && response.message) {
            showAlertDialouge("Error", response.message);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  function resendBtnClicked() {
    if (recordRequestData && secondOpinionId) {
      dispatch(showLoadingSpinner());

      sendRecordRequestNotification_Ajax(
        secondOpinionId,
        function (response) {
          dispatch(hideLoadingSpinner());
          if (response && response.success) {
            showAlertDialouge("Success", response.message, function () {
              navigate("/dashboard");
            });
          } else if (response && !response.success && response.message) {
            showAlertDialouge("Error", response.message);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  function uploadRecordsBtnClicked() {
    if (secondOpinionId) {
      navigate("/upload-record", {
        state: {
          secondOpinionId: secondOpinionId
        }
      });
    }
  }

  function renderMessage(message) {
    if (message) {
      return (
        <div className="text-center mt-5 mb-5">
          <h2>{message}</h2>
        </div>
      );
    } else {
      return <></>;
    }
  }

  return (
    <>
      <div className="body-content">
        <section className="py-4 py-md-5">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="gth-main-header mb-3">
                  <div className="heading-wrapper back-to-wrapper position-relative">
                    <h1 className="title">Records Request</h1>
                  </div>
                </div>
                <div className="bg-white border-radius-xlg px-4 px-md-5 py-4">
                  {isSecondOpinionFetchApiInProgress ? (
                    <>{renderMessage("Getting second opinion data")}</>
                  ) : recordRequestData ? (
                    <div>
                      <div className="row">
                        <div className="fs-3 mb-3 mb-md-4">
                          Enter your dentist's information and we'll request
                          your records on your behalf
                        </div>
                        <div className="form-group mb-3 col-md-12">
                          <label className="form-label">
                            Dentist's Name or Practice Name{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <div className="position-relative">
                            <input
                              type="text"
                              name="dentistName"
                              value={dentistName}
                              className="form-control pe-5"
                              placeholder="Dentist's Name"
                              onChange={handleChange}
                            />
                            <i className="bi bi-pencil mx-2 position-absolute end-0 top-50 translate-middle"></i>
                          </div>
                        </div>
                        <div className="form-group mb-3 col-md-6">
                          <label className="form-label">
                            Dentist's Email Address{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <div className="position-relative">
                            <input
                              type="email"
                              name="email"
                              value={email}
                              className="form-control pe-5"
                              placeholder="Dentist's Email Address"
                              onChange={handleChange}
                            />
                            <i className="bi bi-pencil mx-2 position-absolute end-0 top-50 translate-middle"></i>
                          </div>
                        </div>
                        <div className="form-group mb-3 col-md-6">
                          <label className="form-label">
                            Dentist's Phone Number
                          </label>
                          <div className="position-relative">
                            <InputMask
                              mask="(999)-999-9999"
                              name="phone"
                              value={phone}
                              className="form-control"
                              placeholder="(XXX)-XXX-XXXX"
                              onChange={handleChange}
                            />
                            <i className="bi bi-pencil mx-2 position-absolute end-0 top-50 translate-middle"></i>
                          </div>
                        </div>

                        <div className="d-table mt-md-5"></div>
                        <div className="tab-focusable-background form-group text-center mt-3 mt-md-5 col-sm-12 button-wrapper">
                          <button
                            className="btn btn-body btn-secondary btn-rounded btn-lg px-3 px-md-5 mt-3 mx-md-2 w-100 w-md-auto"
                            disabled={!isUpdateButtonEnable()}
                            onClick={updateBtnClicked}
                          >
                            Update & Submit
                          </button>
                          <button
                            className="btn btn-outline-secondary btn-rounded btn-lg px-3 px-md-5 mt-3 mx-md-2 w-100 w-md-auto"
                            onClick={resendBtnClicked}
                            id="resend-request"
                          >
                            Resend Request
                          </button>
                        </div>
                        <div className="tab-focusable-background form-group text-center mt-5 col-sm-12">
                          <p className="fs-3 text-muted">
                            Already have your records? Upload them now!
                          </p>
                          <button
                            onClick={uploadRecordsBtnClicked}
                            className="btn btn-outline-secondary btn-rounded btn-lg px-3 px-md-5 w-100 w-md-auto"
                          >
                            <i className="bi bi-cloud-upload"></i> &nbsp; Upload
                            Your Records
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>{renderMessage("Second opinion data not found")}</>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <AlertModal />
    </>
  );
};

export default QuestionnaireUpdateRecordPage;
