import React, { useEffect, useState } from "react";
import WellnessScorePrintPage from "../../components/WellnessScorePrint/WellnessScorePrintPage";
import { getSmartScanReportForExternalClient_Ajax } from "../../helpers/requests";
import { useDispatch } from "react-redux";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import { handleApiErrors, showAlertDialouge } from "../../helpers/utils";

const WellnessReportForExternalClient = ({ scrambledData }) => {
  const [smartScanCaseReport, setSmartScanCaseReport] = useState();
  const [wellnessScoreSummary, setWellnessScoreSummary] = useState();
  const [smartscanVM, setSmartscanVM] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    getSmartscanReportDetails();
  }, []);

  function getSmartscanReportDetails() {
    if (!scrambledData) {
      return;
    }

    let params = {
      scrambledData: scrambledData
    };

    dispatch(showLoadingSpinner());

    getSmartScanReportForExternalClient_Ajax(
      params,
      function (response) {
        dispatch(hideLoadingSpinner());
        if (response?.success && response?.data) {
          setSmartScanCaseReport(response.data.smartScanCaseReportVM);
          setSmartscanVM(response.data.smartScanVM);
          setWellnessScoreSummary(response.data.smartScanWellnessScoreSummery);
        } else {
          showAlertDialouge("Message", response?.message);
        }
      },
      function (err) {
        dispatch(hideLoadingSpinner());
        handleApiErrors(err);
      }
    );
  }

  return (
    <div id={"external-client-smartscan-report"}>
      <section className="step-section py-1">
        <div className="container">
          <WellnessScorePrintPage
            smartScanCaseReport={smartScanCaseReport}
            wellnessScoreSummary={wellnessScoreSummary}
            smartScanId={smartscanVM?.smartScanId}
            patientId={smartscanVM?.patId}
            isForExternalClient={true}
            smartScanVM={smartscanVM}
          />
        </div>
      </section>
    </div>
  );
};

export default WellnessReportForExternalClient;
