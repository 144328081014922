import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GuestPatientKey, smartScanCategory } from "../../constants";
import useGetSmartScanSelfieCaptureProgress from "../CustomHooks/useGetSmartScanSelfieCaptureProgress";
import { saveSmartScanSelfieCaptureProgress } from "../../indexedDbHelper/smartScanProgressIndexedDbHelper";
import SmartScanSelfie from "./SmartScanSelfie";
import { getIsSmartScanSelfieCaptureProgressCachingEnabled } from "../../ApplicationSettings";
import useSubmitSmartScan from "../CustomHooks/useSubmitSmartScan";

const SmartScanPosePage = ({ shouldSubmitSmartScan = false }) => {
  const navigate = useNavigate();
  const smartScanState = useGetSmartScanSelfieCaptureProgress();
  const { pathname } = useLocation();

  const {
    submitSmartScan,
    isSavingInProgress,
    getSmartScanCategoryPhotoFileObj
  } = useSubmitSmartScan();

  useEffect(() => {
    if (shouldSubmitSmartScan && pathname == "/finish-account-setup") {
      nextBtnClicked();
    }
  }, [shouldSubmitSmartScan]);

  function retakeBtnClicked(retakeType) {
    let newState = { ...smartScanState, isRetake: true };
    saveSmartScanSelfieCaptureProgress(newState, newState.patientId);
    let retakePageState;

    if (getIsSmartScanSelfieCaptureProgressCachingEnabled()) {
      retakePageState = {
        patientId: newState.patientId,
        isRetake: newState.isRetake
      };
    } else {
      retakePageState = { ...newState };
    }

    let isAuthenticated = smartScanState.isAuthenticated;

    switch (retakeType) {
      case smartScanCategory.Bite:
        navigate(
          isAuthenticated
            ? "/smart-scan-bite-confirm"
            : "/smart-scan-bite-confirm-no-auth",
          {
            state: retakePageState
          }
        );
        break;
      case smartScanCategory.LowerArch:
        navigate(
          isAuthenticated
            ? "/smart-scan-lower-arch-confirm"
            : "/smart-scan-lower-arch-confirm-no-auth",
          {
            state: retakePageState
          }
        );
        break;
      case smartScanCategory.UpperArch:
        navigate(
          isAuthenticated
            ? "/smart-scan-upper-arch-confirm"
            : "/smart-scan-upper-arch-confirm-no-auth",
          {
            state: retakePageState
          }
        );
        break;
      case smartScanCategory.UpperLip:
        navigate(
          isAuthenticated
            ? "/smart-scan-upper-lip-confirm"
            : "/smart-scan-upper-lip-confirm-no-auth",
          {
            state: retakePageState
          }
        );
        break;
      case smartScanCategory.LowerLip:
        navigate(
          isAuthenticated
            ? "/smart-scan-lower-lip-confirm"
            : "/smart-scan-lower-lip-confirm-no-auth",
          {
            state: retakePageState
          }
        );
        break;
      default:
    }
  }

  function nextBtnClicked() {
    if (
      !smartScanState.isAuthenticated &&
      pathname == "/smart-scan-pose-no-auth"
    ) {
      navigate("/smart-scan-send-result", {
        state: {
          patientId: GuestPatientKey,
          hasSmartScanProgress: true
        }
      });
      return;
    }

    submitSmartScan();
  }

  async function goToPreviousPage() {
    let previousPageState;
    if (getIsSmartScanSelfieCaptureProgressCachingEnabled()) {
      previousPageState = {
        patientId: smartScanState?.patientId
      };
      let newState = { ...smartScanState, isRetake: false };
      await saveSmartScanSelfieCaptureProgress(
        newState,
        smartScanState.patientId
      );
    } else {
      previousPageState = { ...smartScanState, isRetake: false };
    }

    navigate("/smart-scan-bite-confirm", {
      state: previousPageState
    });
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="gth-main-header mb-3">
              <div className="heading-wrapper back-to-wrapper position-relative">
                <h1 className="title">Smart Scan</h1>
              </div>
            </div>
            <div className="bg-white border-radius-xlg px-4 px-md-5 py-4">
              <div className="smart-scan-images-div">
                <div className="border border-transparent smart-scan-images pt-2 pt-md-4">
                  {
                    <div className="row w-100">
                      <SmartScanSelfie
                        retakeBtnClicked={retakeBtnClicked}
                        category={smartScanCategory.UpperArch}
                        imageFile={getSmartScanCategoryPhotoFileObj(
                          smartScanCategory.UpperArch
                        )}
                      />
                      <SmartScanSelfie
                        retakeBtnClicked={retakeBtnClicked}
                        category={smartScanCategory.LowerArch}
                        imageFile={getSmartScanCategoryPhotoFileObj(
                          smartScanCategory.LowerArch
                        )}
                      />
                      <SmartScanSelfie
                        retakeBtnClicked={retakeBtnClicked}
                        category={smartScanCategory.UpperLip}
                        imageFile={getSmartScanCategoryPhotoFileObj(
                          smartScanCategory.UpperLip
                        )}
                      />
                      <SmartScanSelfie
                        retakeBtnClicked={retakeBtnClicked}
                        category={smartScanCategory.LowerLip}
                        imageFile={getSmartScanCategoryPhotoFileObj(
                          smartScanCategory.LowerLip
                        )}
                      />
                      <SmartScanSelfie
                        retakeBtnClicked={retakeBtnClicked}
                        category={smartScanCategory.Bite}
                        imageFile={getSmartScanCategoryPhotoFileObj(
                          smartScanCategory.Bite
                        )}
                      />
                    </div>
                  }
                </div>
              </div>
              <div className="tab-focusable-background btn-div text-center smart-scan-submit-btn-div">
                <button
                  disabled={isSavingInProgress}
                  onClick={nextBtnClicked}
                  tabIndex={0}
                  className="btn btn-secondary btn-rounded btn-lg px-4 px-md-5 mt-3 mt-md-0 w-100 w-md-auto"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SmartScanPosePage;
