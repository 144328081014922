import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompressedFile,
  validateImage,
  showAlertDialouge,
  getSmartScanImageCategoryName,
  getSmartScanImageObjectNameForState,
  getSmartScanStepNo,
  getCSSClassToStopScrollingInSmartScanPosePageInMobileDevice,
  fileToArrayBuffer
} from "../../helpers/utils";
import {
  SmartScanTotalPhotos,
  CameraAttribute,
  CameraAttributeValues,
  SmartScanCaptureOptions
} from "../../constants";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import { saveSmartScanSelfieCaptureProgress } from "../../indexedDbHelper/smartScanProgressIndexedDbHelper";
import { getIsSmartScanSelfieCaptureProgressCachingEnabled } from "../../ApplicationSettings";

const SmartScanSelfieCapture = (props) => {
  const {
    previousPagePath,
    nextPagePath,
    category,
    poseImage,
    instruction,
    state
  } = props;
  const navigate = useNavigate();
  const smartScanMaxPhotoSizeInMB = useSelector(
    (state) => state?.appSettings?.settingsData?.SmartScanMaxFileSizeInMB
  );
  const dispatch = useDispatch();
  const [isProcessingImage, setIsProcessingImage] = useState(false);
  const inputElement = useRef(null);

  async function handleSelfieClicked(event) {
    if (event && event.target && event.target.files && category) {
      setIsProcessingImage(true);
      dispatch(showLoadingSpinner());
      let file = event.target.files[0];
      let fileName = file.name.toLowerCase();
      let newState = { ...state };
      let fileValidationErrMsg = validateImage(file, smartScanMaxPhotoSizeInMB);

      if (!fileValidationErrMsg) {
        let compressedFile = await getCompressedFile(file);
        let processedFile = compressedFile ? compressedFile : file;

        let picObj;
        let nextPageState;
        if (getIsSmartScanSelfieCaptureProgressCachingEnabled()) {
          let fileArrayBuffer = await fileToArrayBuffer(processedFile);
          picObj = {
            file: fileArrayBuffer,
            name: fileName,
            mimeType: file.type
          };

          nextPageState = {
            patientId: newState.patientId
          };

          if (newState?.isRetake) {
            nextPageState.isRetake = newState.isRetake;
          }

          newState[getSmartScanImageObjectNameForState(category)] = picObj;
          await saveSmartScanSelfieCaptureProgress(
            newState,
            newState.patientId
          );
        } else {
          picObj = {
            file: processedFile
          };

          newState[getSmartScanImageObjectNameForState(category)] = picObj;
          nextPageState = { ...newState };
        }

        if (nextPagePath) {
          navigate(nextPagePath, {
            state: nextPageState
          });
        }
        setIsProcessingImage(false);
        dispatch(hideLoadingSpinner());
      } else {
        setIsProcessingImage(false);
        dispatch(hideLoadingSpinner());
        showAlertDialouge("Error", fileValidationErrMsg);
      }
    }
  }

  async function handleBackBtnClick() {
    if (previousPagePath) {
      let previousPageState;
      if (getIsSmartScanSelfieCaptureProgressCachingEnabled()) {
        previousPageState = { patientId: state.patientId };
      } else {
        previousPageState = { ...state };
      }

      navigate(previousPagePath, {
        state: previousPageState
      });
    }
  }

  function getProgress() {
    let stepNo = getSmartScanStepNo(category);
    let totalSteps = SmartScanTotalPhotos;

    let width = (stepNo / totalSteps) * 100;

    return (
      <div className="progress mb-3">
        <div
          className="progress-bar fs-6 pb-1"
          role="progressbar"
          style={{ width: width + "%" }}
          aria-valuenow="20"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          {stepNo + " out of " + totalSteps}
        </div>
      </div>
    );
  }

  function setCameraCaptureAttribute() {
    if (inputElement.current) {
      let cameraAttributeValue;

      if (state?.captureOption == SmartScanCaptureOptions.Myself) {
        cameraAttributeValue = CameraAttributeValues.User;
      } else if (state?.captureOption == SmartScanCaptureOptions.Assisted) {
        cameraAttributeValue = CameraAttributeValues.Environment;
      }

      inputElement.current.setAttribute(CameraAttribute, cameraAttributeValue);
    }
  }

  function onKeyPressed(event) {
    if (event?.key == "Enter") {
      inputElement.current.click();
    }
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            {getProgress()}
            <div
              className={
                "smart-scan-selfie-wrapper smart-scan-wrapper bg-white border-radius-xlg " +
                getCSSClassToStopScrollingInSmartScanPosePageInMobileDevice()
              }
            >
              <figure className="mb-0">
                <img
                  className="w-100 border-radius-xlg rounded-b-l-0 rounded-b-r-0"
                  src={poseImage}
                  alt="smart scan pose image"
                />
              </figure>
              <div className="bg-primary px-4 px-md-5 py-4 border-radius-xlg rounded-t-l-0 rounded-t-r-0">
                <div className="d-flex flex-wrap justify-content-between align-items-center mb-3">
                  <h3 className="flex-1 pe-3">
                    <b>{getSmartScanImageCategoryName(category)}</b>
                  </h3>
                  <label
                    htmlFor="imageInput"
                    className="bg-gold text-white p-3 position-relative rounded-circle"
                    style={{ width: "25px" }}
                    tabIndex={0}
                    onKeyPress={onKeyPressed}
                  >
                    <i
                      className="bi bi-chevron-right fs-3 fw-bold position-absolute top-50 start-50 translate-middle"
                      onClick={setCameraCaptureAttribute}
                    ></i>
                  </label>
                  <input
                    type="file"
                    className="d-none form-control"
                    id="imageInput"
                    capture="environment"
                    disabled={isProcessingImage}
                    onChange={handleSelfieClicked}
                    ref={inputElement}
                  />
                </div>
                <p style={{ lineHeight: "1.5" }}>{instruction}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SmartScanSelfieCapture;
