import moment from "moment";
import React from "react";
import { useRef } from "react";
import SignatureCanvas from "react-signature-canvas";
import { RecordForOptions, RequestorPatientRelations } from "../../constants";
import { onImageError } from "../../helpers/utils";

const ConsentDataPage = (props) => {
  const { consentData } = props;

  return (
    <>
      <div
        className="modal fade"
        id="medical-records-release-modal"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header primary-border-color">
              <h2 className="modal-title text-primary text-center w-100">
                Request and Authorization For Release of Dental Records
              </h2>
              <button
                type="button"
                className="btn-close text-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body position-static">
              <div className="consent-form">
                <div className="container">
                  <div className="row">
                    <div className="col-sm-12">
                      <div
                        style={{
                          background: "#eee",
                          fontSize: "14px",
                          padding: "12px 15px",
                          fontWeight: "600",
                          marginTop: "10px",
                          marginBottom: "20px",
                          borderRadius: "5px"
                        }}
                      >
                        <p style={{ marginBottom: "2px" }}>
                          Form title: Consent Form
                        </p>
                      </div>
                      <div className="bg-white border-radius-xlg">
                        <form className="consent-form">
                          <div className="row">
                            <div className="form-group mb-3 col-sm-12">
                              <label className="form-label fw-bold">
                                Section 1 - Request and Authorization
                              </label>
                              <p>
                                I,{" "}
                                <span>
                                  <u>{consentData.requestor}</u>
                                </span>{" "}
                                (“Requestor”), hereby requests a copy of:
                              </p>
                              <ul className="check-list list-unstyled my-2">
                                <li className="mb-2 me-3 me-md-3 me-lg-5 d-inline-block">
                                  <div className="form-check d-flex flex-wrap w-100">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="dental_records"
                                      id="dental_records1"
                                      checked={
                                        consentData.recordFor ==
                                        RecordForOptions.Myself
                                      }
                                      disabled
                                    />
                                    <label
                                      className="form-check-label fs-body opacity-1"
                                      htmlFor="dental_records1"
                                    >
                                      My dental records,
                                    </label>
                                  </div>
                                </li>
                                <li className="mb-2 me-3 me-md-3 me-lg-5 d-inline-block">
                                  Or
                                </li>
                                <li className="mb-2 d-inline-block">
                                  <div className="form-check d-flex flex-wrap w-100">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="dental_records"
                                      id="dental_records2"
                                      checked={
                                        consentData.recordFor ==
                                        RecordForOptions.Other
                                      }
                                      disabled
                                    />
                                    <label
                                      className="form-check-label fs-body w-auto opacity-1"
                                      htmlFor="dental_records2"
                                    >
                                      the dental records of &nbsp;
                                    </label>
                                    <span>
                                      <u>
                                        {consentData.patientName
                                          ? consentData.patientName
                                          : ""}
                                      </u>
                                    </span>
                                  </div>
                                </li>
                              </ul>
                              <p>
                                (Patient’s Date of Birth:) &nbsp;{" "}
                                <span>
                                  {moment(consentData.dateOfBirth).format(
                                    "MM/DD/YYYY"
                                  )}
                                </span>
                              </p>
                            </div>
                            <div className="form-group mb-3 col-sm-12">
                              <label className="form-label fw-bold">
                                Section 2 - Scope of Request
                              </label>
                              <p>
                                This request includes but is not limited to all
                                diagnosis, treatment plans, x-rays, lab test
                                results, treatment, treatment plans, cost
                                estimates and billing records, for all findings
                                and conditions included in or related to my most
                                recent treatment plan(s).
                              </p>
                            </div>
                            <div className="form-group mb-3 col-sm-12">
                              <label className="form-label fw-bold">
                                Section 3 - Form and Format of Records and
                                Delivery{" "}
                              </label>
                              <p>
                                In accordance with HIPAA regulations (45 CFR
                                Sec. 164.524 (c)(2)(i) & (c)(3)(ii)), I
                                authorize and direct{" "}
                                <span style={{ minWidth: "220px" }}>
                                  <u>{consentData.dentistOrPracticeName}</u>
                                </span>
                                ,
                              </p>
                              <p>
                                to provide the records in Electronic format and
                                that they be delivered Electronically to my
                                HIPAA secure dental.com patient portal utilizing
                                the dental.com link referenced in the e-mail
                                accompanying this Request.
                              </p>
                            </div>
                            <div className="form-group mb-3 col-sm-12">
                              <label className="form-label fw-bold">
                                Section 4 - Purpose
                              </label>
                              <p>
                                The purpose of this request is to obtain a
                                second opinion.
                              </p>
                            </div>
                            <div className="form-group mb-3 col-sm-12">
                              <label className="form-label fw-bold">
                                Section 5 – Acknowledgments
                              </label>
                              <p>
                                Requestor hereby understands and acknowledges
                                that: (i) he or she has the right to revoke this
                                Request and Authorization at any time by
                                contacting customer service at dental.com.
                              </p>
                            </div>
                            <div className="form-group mb-3 col-sm-12">
                              <label className="form-label fw-bold">
                                Section 6 – Duration of Authorization
                              </label>
                              <p>
                                This authorization is effective as of the date
                                signed and for a period of 60 days thereafter.
                              </p>
                            </div>
                            <div className="form-group mb-3 col-sm-12">
                              <label className="form-label fw-bold">
                                Section 7 – Signature of Requestor and Date.
                              </label>
                              <div className="d-flex flex-wrap align-items-end mt-3">
                                <div className="d-flex111 mb-3 me-md-3">
                                  <span className="flex-shrink-1 me-2">
                                    Signature
                                  </span>

                                  <div className="mt-2">
                                    <div className="canvas-wrapper d-table position-relative">
                                      {consentData.signatureDataURL ? (
                                        <img
                                          src={consentData.signatureDataURL}
                                          onError={onImageError}
                                          alt="signature"
                                        ></img>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                    <div className="form-group-title fs-5 text-muted mb-2 mb-md-3 lh-1">
                                      Patient / Responsible Party Signature
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex mb-3 me-md-3">
                                  <span className="flex-shrink-1 me-2">
                                    Date
                                  </span>
                                  <span className="w-100">
                                    {moment(consentData.signatureDate).format(
                                      "MM/DD/YYYY"
                                    )}
                                  </span>
                                </div>
                              </div>

                              <p className="fw-bold mt-3">
                                If not signed by the patient, the Requestor’s
                                relationship to the Patient is:
                              </p>
                              <ul className="check-list list-unstyled my-2">
                                <li className="mb-2">
                                  <div className="form-check d-flex flex-wrap w-100">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="patient_relation"
                                      id="patient_relation1"
                                      checked={
                                        consentData.relation ==
                                        RequestorPatientRelations.Parent
                                      }
                                      disabled
                                    />
                                    <label
                                      className="form-check-label fs-body opacity-1"
                                      htmlFor="patient_relation1"
                                    >
                                      Parent or guardian of minor patient
                                    </label>
                                  </div>
                                </li>
                                <li className="mb-2">
                                  <div className="form-check d-flex flex-wrap w-100">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="patient_relation"
                                      id="patient_relation2"
                                      checked={
                                        consentData.relation ==
                                        RequestorPatientRelations.Conservator
                                      }
                                      disabled
                                    />
                                    <label
                                      className="form-check-label fs-body w-auto opacity-1"
                                      htmlFor="patient_relation2"
                                    >
                                      Guardian or conservator of an incompetent
                                      patient
                                    </label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConsentDataPage;
