import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";

import { getPatientInfo } from "../../helpers/authManager";
import {
  handleApiErrors,
  redirectToCheckout,
  queryStringToJSON,
  showAlertDialouge
} from "../../helpers/utils";
import {
  getPaymentInfo_Ajax,
  getPaymentInfo_ajax,
  redirectToManageBilling_Ajax
} from "../../helpers/requests";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import { Link } from "react-router-dom";
import { getBaseUrl } from "../../ApplicationSettings";

const BillingInformationForm = (props) => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const officeId = useSelector(
    (state) => state?.appSettings?.settingsData?.officeId
  );

  const [email, setEmail] = useState(null);
  const [patientId, setPatientId] = useState(0);
  const [paymentMethodInfo, setPaymentMethodInfo] = useState(null);

  useEffect(() => {
    var patientInfo = getPatientInfo();
    if (patientInfo) {
      if (patientInfo.user && patientInfo.user.email) {
        setEmail(patientInfo.user.email);
      }
      if (patientInfo.patid) {
        setPatientId(patientInfo.patid);
      }
    }

    checkForPaymentSetupStatus();
  }, []);

  useEffect(() => {
    if (patientId && officeId) {
      getPaymentInfo();
    }
  }, [patientId, officeId]);

  function getPaymentInfo() {
    if (patientId && officeId) {
      var params = {
        officeId: officeId
      };

      dispatch(showLoadingSpinner());
      getPaymentInfo_Ajax(
        patientId,
        params,
        function (response) {
          if (
            response &&
            response.success &&
            response.data &&
            response.data.paymentMethod
          ) {
            setPaymentMethodInfo(response.data.paymentMethod);
          } else if (response && !response.success && response.message) {
            showAPIFailureAlert(response.message);
          }

          dispatch(hideLoadingSpinner());
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  function showAPIFailureAlert(message) {
    var params = queryStringToJSON();

    if (params.showfailurealert == "true") {
      if (message) {
        showAlertDialouge("Error", message);
      }
    }
  }

  function checkForPaymentSetupStatus() {
    var params = queryStringToJSON();
    if (params.success) {
      if (params.success == "true") {
        showAlertDialouge("Message", "Payment information has been saved.");
      } else if (params.success == "false") {
        showAlertDialouge(
          "Error",
          "Payment information could not be saved. Please try again."
        );
      }
    }
  }

  function redirectToManageBilling() {
    if ((patientId, email, officeId)) {
      var stripeSuccessURL = getBaseUrl() + "/billing-information?success=true";
      var stripeCancelURL = getBaseUrl() + "/billing-information?success=false";

      var data = {
        PatientId: patientId,
        StripeSuccessURL: stripeSuccessURL,
        StripeCancelURL: stripeCancelURL,
        OfficeId: officeId
      };

      dispatch(showLoadingSpinner());
      redirectToManageBilling_Ajax(
        patientId,
        email,
        JSON.stringify(data),
        function (response) {
          dispatch(hideLoadingSpinner());
          if (
            response &&
            response.success &&
            response.data &&
            response.data.paymentSessionId
          ) {
            redirectToCheckout(
              response.data.paymentSessionId,
              response.data.stripeAccount
            );
          } else if (response && !response.success && response.message) {
            showAlertDialouge("Error", response.message);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  return (
    <div className="container">
      <div className="row mb-3">
        <div className="col-sm-10">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Billing Information
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xxl-4 offset-xxl-4">
          <div className="bg-white border-radius-xlg px-4 px-md-5 py-4">
            <div className="" id="">
              <div className="fade show active" id="billing-information">
                <form>
                  <fieldset>
                    <h2 className="text-center text-violet mb-4">
                      Billing Information
                    </h2>
                    <div className="row">
                      <div className="form-group mb-3">
                        <label className="form-label" htmlFor="email">
                          Email <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          name="Email"
                          id="email"
                          className="form-control"
                          value={email}
                          disabled
                        />
                      </div>
                      {paymentMethodInfo &&
                      paymentMethodInfo.card &&
                      paymentMethodInfo.card.last4 ? (
                        <div className="form-group mb-3">
                          <label className="form-label" htmlFor="last4digit">
                            Last 4 digits of saved card
                          </label>
                          <input
                            type="text"
                            name="Email"
                            id="last4digit"
                            className="form-control"
                            value={paymentMethodInfo.card.last4}
                            disabled
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </fieldset>
                </form>
                <div className="form-group text-center mt-2 mt-md-4 col-sm-12">
                  <button
                    className="tab-focusable-background btn btn-secondary btn-rounded btn-lg px-5"
                    onClick={redirectToManageBilling}
                    disabled={!email}
                  >
                    Manage Billing
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingInformationForm;
